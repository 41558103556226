<div class="container">
  <davinci-text-field
    [formControlName]="controlName"
    [label]="label"
    [placeholder]="placeholder"
    [error]="error"
    (blur)="blurEvent(controlName)"
  >
    <span class="unit">{{ unit }}</span>
  </davinci-text-field>
</div>
