import {
  Component,
  ElementRef,
  Input,
  OnChanges
} from '@angular/core';
import { DavinciCoreModel } from '../davinci-core.model';

@Component({
  selector: 'sick-davinci-toolbar',
  templateUrl: './davinci-toolbar.component.html',
  styleUrls: ['./davinci-toolbar.component.scss']
})
export class DavinciToolbarComponent implements OnChanges {
  @Input()
  config: DavinciCoreModel.Toolbar;

  containerElement: HTMLElement;

  constructor(private elRef: ElementRef) {
    this.containerElement = elRef.nativeElement;
  }

  ngOnChanges() {
    if (!this.config) {
      this.config = {
        buttons: null,
        menu: null,
        type: 'workspace',
      };
    }

    this.containerElement.classList.remove('workspace');
    this.containerElement.classList.remove('header');
    this.containerElement.classList.remove('hidden');

    this.containerElement.classList.add(this.config.type);

    if (!(this.config.buttons && this.config.buttons.length) && !(this.config.menu && this.config.menu.length)) {
      this.containerElement.classList.add('hidden');
    }
  }
}
