import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UncertaintyInputData } from '../../model/model';
import { convertViewModel2DataModel, convertDataModel2ViewModel } from '../../utils/convert';
import { urlEncodeQuery } from '../../utils/urlEncoder';
import {
  alpha,
  checkgas,
  gasComposition,
  limitsVector,
  measureMatrix,
  pressures,
  temperatures,
  customSoS,
  kappa,
  customMolWeight,
} from './query-builder';
import { map, tap } from 'rxjs/operators';
import { log } from './log';

interface VminSearchResponse {
  result: number[];
}

// GET /octave/VminSearch
//
export const searchVminLimitHandler = (http: HttpClient) => (input: UncertaintyInputData) => {
  const data = convertViewModel2DataModel(input);

  const queryObj = {
    pressures: pressures(data),
    temperatures: temperatures(data),
    customSos: customSoS(data),
    customMolWeight: customMolWeight(data),
    kappa: kappa(data),
    checkgas: checkgas(data),
    gasComposition: gasComposition(data),
    installType: data.installType,
    deviceType: data.deviceType,
    diameter: data.diameter,
    paths: data.pathConfiguration,
    alpha: alpha(data),
    measureMatrix: measureMatrix(data),
    limitsVector: limitsVector(data),
    // parametrMap: parameterMap(data),
    type: data.installType,
  };

  const query = urlEncodeQuery(queryObj);
  const url = environment.apiUrl + '/octave/VminSearch?' + query;

  return http.get<VminSearchResponse>(url).pipe(
    tap((res) => log(url, queryObj, res)),
    map((res) => res.result),
    map((vMinLimits) => {
      if (vMinLimits.some((x) => x === 9999)) {
        data.alarmMarker.genericMessages.push('Invalid vMin values returned!');
      }
      data.solutions.vMinLimit1 = vMinLimits[0];
      data.solutions.vMinLimit2 = vMinLimits[1];
      data.solutions.vMinLimit3 = vMinLimits[2];
      data.solutions.vMinLimit4 = vMinLimits[3];

      return convertDataModel2ViewModel(data, input.unitSystem);
    })
  );
};
