import { Component } from '@angular/core';
import { AuthService } from './auth.service';

@Component({
  selector: 'sick-davinci-silent-refresh',
  template: ''
})
export class SilentRefreshComponent {
  constructor(authService: AuthService) {
    authService.silentRefresh();
  }
}



