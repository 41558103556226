import { NominalSize } from './consts';

export interface AbstractProcessData<NumValue> {
  active: boolean;
  pressure: NumValue;
  temperature: NumValue;
  speed: NumValue;
  agc: NumValue;
}

export interface UncertaintyResult {
  velocity: number;
  values: {
    min?: number;
    norm?: number;
    max?: number;
  };
}

export class PreParams {
  molWeight: number;
  maxVelocity: number;
  maxFlowRate: number;
  maxVelocityAsc?: number;
  maxFlowRateAsc?: number;
}

export interface CalculatedParams {
  min?: PreParams;
  norm?: PreParams;
  max?: PreParams;
}

export interface ResultRow {
  vog: number | string;
  flowRate: number | string;
  uncertainty: {
    min?: number;
    norm?: number;
    max?: number;
  };
  m0deviation: {
    min?: number;
    norm?: number;
    max?: number;
  };
}

export interface ParameterEntry<NumValue> {
  entry: NumValue;
}

export type ParameterVector<NumValue> = [
  ParameterEntry<NumValue>,
  ParameterEntry<NumValue>,
  ParameterEntry<NumValue>,
  ParameterEntry<NumValue>,
  ParameterEntry<NumValue>,
  ParameterEntry<NumValue>,
  ParameterEntry<NumValue>,
  ParameterEntry<NumValue>,
  ParameterEntry<NumValue>
];

export type ParameterMatrix<NumValue> = [
  ParameterVector<NumValue>,
  ParameterVector<NumValue>,
  ParameterVector<NumValue>,
  ParameterVector<NumValue>,
  ParameterVector<NumValue>,
  ParameterVector<NumValue>,
  ParameterVector<NumValue>,
  ParameterVector<NumValue>,
  ParameterVector<NumValue>,
  ParameterVector<NumValue>
];

/** The raw user input  */
interface AbstractUncertaintyData<NumValue, StrValue> {
  // General information
  id: string; // project id, client side generated random id
  version: string; // Persistence service version
  backendVersion: string; // backend version used to perform calculations
  frontendVersion: string; // frontend version used
  projectName: string; // Aux information. Not needed for any computation
  reference: string; // Aux information. Not needed for any computation
  tag: string; // Aux information. Not needed for any computation
  poNumber: string; // Aux information. Not needed for any computation
  partNumber: string; // Aux information. Not needed for any computation
  serialNumber: string; // Aux information. Not needed for any computation
  deviceType: string;
  pathConfiguration: string;
  diameter?: NominalSize;
  innerDiameter?: string;
  nozzleLength?: string;
  installType: string;
  explanation: string; // Aux information. Not needed for any computation

  user: string;
  userList: string[];

  // Options
  unitSystem: UnitSystemKey;
  projectType: 'projectTypeInstallBased' | 'projectTypeQuoting';

  // Process data
  min: AbstractProcessData<NumValue>;
  norm: AbstractProcessData<NumValue>;
  max: AbstractProcessData<NumValue>;
  speedOfSoundMethod: 'sos-gas-composition' | 'sos-own' | 'sos-mol-and-kappa';

  speedUncertaintyPercent: NumValue;
  speedUncertaintyMax: NumValue;
  speedUncertaintyAbove: NumValue;

  exZone: StrValue;
  activeSoundCorrelation: boolean;
  envelope: boolean;
  m0deviation: boolean;

  // extended data
  extendedData: {
    useCustomAngle?: boolean;
    customAngleValue?: NumValue;
    additionalPathAngle?: NumValue;
    useUnpairedProbesTime?: boolean;
    unpairedProbesTimeDiff?: NumValue;
    useCustomZeroPointUncertainty?: boolean;
    customZeroPointUncertainty?: NumValue;
  };

  // Solutions
  solutions: {
    m1: true; // immer true. Martin: Das suggerriert, dass das Firmwareupdate durchgeführt wurde. Ohne M1 können wir nichts rechnen.
    m2a: boolean;
    m3a: boolean;
    m3b: boolean;
    m3c: boolean;
    m4a: boolean;
    m4b: boolean;
    deviceTypeM3a: NumValue;
    deviceTypeM3b: NumValue;
    angleTypeM3b: NumValue;
    deviceTypeM3c: NumValue;
    vMinLimit1: NumValue; // M1
    vMinLimit2: NumValue; // M1+m2
    vMinLimit3: NumValue; // M1+M2+M3a/c
    vMinLimit4: NumValue; // M1+M2+M3b
  };

  // for SoS based on a gas composition
  gasComposition: {
    argon: NumValue;
    carbondioxid: NumValue;
    carbonMonoxid: NumValue;
    ethan: NumValue;
    helium: NumValue;
    hydrogen: NumValue;
    hydrogenSulfid: NumValue;
    iButhan: NumValue;
    iPenthan: NumValue;
    methan: NumValue;
    nButhan: NumValue;
    nDecane: NumValue;
    nHepthan: NumValue;
    nHexan: NumValue;
    nitrogen: NumValue;
    nNonane: NumValue;
    nOcthan: NumValue;
    nPenthan: NumValue;
    oxygen: NumValue;
    propan: NumValue;
    water: NumValue;
  };

  // for SoS based on mol weight, isentropic exp. kapp
  molWeightAndKappa: {
    minMolWeight: NumValue;
    normMolWeight: NumValue;
    maxMolWeight: NumValue;
    minIsentropic: NumValue;
    normIsentropic: NumValue;
    maxIsentropic: NumValue;
  };

  vogVector: {
    velo1: NumValue;
    velo2: NumValue;
    velo3: NumValue;
    velo4: NumValue;
    velo5: NumValue;
    velo6: NumValue;
  };

  parameters: ParameterMatrix<NumValue>;

  alarmMarker: {
    agc: boolean;
    spec: boolean;
    solution: boolean;
    customSolution: boolean;
    genericMessages: string[];
  };

  results: {
    md5?: string;
    calculatedParams: CalculatedParams;
    resultRows: ResultRow[];
    envelopeResultRows: ResultRow[];
  };
}

export type InputNumValue = string | number | null;
export type InputStrValue = string | null;

export type PhysicalQuantity =
  | 'pressure'
  | 'speed'
  | 'sos'
  | 'temperature'
  | 'flowRate'
  | 'mol'
  | 'smallLength'
  | 'molFraction'
  | 'angle'
  | 'diameter';

/**
 * The user provied data may contain string, null values etc.
 * Futhermore, the user input can be in imperial values
 *
 * We want to transform UncertaintyInputData -> UncertaintyNormalizedData using basic SI units, kelvin, etc... as base units
 */
export type UncertaintyInputData = AbstractUncertaintyData<InputNumValue, InputStrValue>;
export type UncertaintyData = AbstractUncertaintyData<number, string>;

export type UnitSystemKey = 'metric' | 'imperial';
export type UnitSystemValues = { [x in PhysicalQuantity]: string };
export type ProcessData = AbstractProcessData<InputNumValue>;

export const UNITS: {
  [k in UnitSystemKey]: UnitSystemValues;
} = {
  metric: {
    pressure: 'bar',
    speed: 'm/s',
    sos: 'm/s',
    temperature: 'temp-C',
    flowRate: 'm^3/h',
    mol: 'g/mol',
    smallLength: 'cm',
    molFraction: '%',
    angle: '°',
    diameter: 'inches',
  },
  imperial: {
    pressure: 'psi',
    speed: 'foot/s',
    sos: 'foot/s',
    temperature: 'temp-F',
    flowRate: 'foot^3/h',
    mol: 'lb/mol',
    smallLength: 'inch',
    molFraction: '%',
    angle: '°',
    diameter: 'inches',
  },
};

// Type alias to id
export type Kelvin = number;
export type Celsius = number;
export type MeterPerSec = number;
export type Inch = number;
export type CubicsMeterPerHour = number;

// To display values
export type UnitSystemLabels = UnitSystemValues;
export type UnitLabels = { [k in UnitSystemKey]: UnitSystemLabels };
export const unitLabels: UnitLabels = {
  metric: {
    pressure: 'bar',
    speed: 'm/s',
    sos: 'm/s',
    temperature: '°C',
    flowRate: 'm³/h',
    mol: 'g/mol',
    smallLength: 'cm',
    molFraction: 'mole in %',
    angle: '°',
    diameter: 'inches',
  },
  imperial: {
    pressure: 'psi',
    speed: 'ft/s',
    sos: 'ft/s',
    temperature: '°F',
    flowRate: 'ft³/h',
    mol: 'lb/mol',
    smallLength: 'inch',
    molFraction: 'mole in %',
    angle: '°',
    diameter: 'inches',
  },
};
