/**
 * Returns true if device is a classic meter (non-XT meter).
 * @param devicetype Denotes the numeric representation of the selected device type.
 * Device types are indexed by dropdown in device type selection:
 *  [1] EX-S 135 kHz
 *  [2] EX-S 80 kHz
 *  [3] EX 42 kHz
 *  [4] EX-PR
 *  [5] F1F-S
 *  [6] F1F-M
 *  [7] F1F-H
 *  [8] F1F-P
 * @return True, if device is classic device.
 */
export function isClassicDevice(devicetype: number): boolean {
  return devicetype <= 4;
}
