import {
  Component,
  OnInit
} from '@angular/core';
import {
  NavigationEnd,
  Router
} from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DavinciCoreUtilityService } from '../../davinci-core-utility.service';
import { DavinciCoreModel } from '../../davinci-core.model';
import { DavinciCoreService } from '../../davinci-core.service';

@Component({
  selector: 'sick-davinci-navigation',
  templateUrl: './davinci-navigation.component.html',
  styleUrls: ['./davinci-navigation.component.scss']
})
export class DavinciNavigationComponent implements OnInit {
  itemList: DavinciCoreModel.NavigationItem[] = [];
  type: DavinciCoreModel.NavigationType;
  path: string[] = [];
  activeIndex = -1;

  private routerEvents: Subscription;

  constructor(
    private core: DavinciCoreService,
    private router: Router,
    private utility: DavinciCoreUtilityService,
  ) {
  }

  ngOnInit() {
    this.init(this.router);

    this.core.navigation$.subscribe((navigation: DavinciCoreModel.Navigation) => {
      this.type = navigation.type;
      this.init(this.router);

      this.path = navigation.currentPath;

      this.activeIndex = -1;
      this.itemList.some((navigationItem, index) => {
        if (navigationItem.route === this.path[0]) {
          this.activeIndex = index;
          return true;
        }
        return false;
      });
    });
  }

  onClick(item: DavinciCoreModel.NavigationItem|null) {
    if (!item) {
      this.router.navigateByUrl('');
    } else if (this.type !== 'workflow' || !item.disabled) {
      this.router.navigateByUrl(item.route);
    }
  }

  private init(router: Router) {
    if (this.routerEvents) {
      this.routerEvents.unsubscribe();
    }

    switch (this.type) {
      case 'basic':
      case 'workflow':
        this.itemList = this.core.navigation$.getValue().tree || [];
        break;
      case 'tree':
        this.initTreeNavigation(router);
        break;
    }
  }

  private initTreeNavigation(router: Router) {
    this.routerEvents = router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.updateTreeNavigation());

    this.updateTreeNavigation();
  }

  private updateTreeNavigation() {
    this.itemList = this.resolveTreeItems(
      this.utility.urlToSegmentPaths(this.router.url),
      this.core.navigation$.getValue().tree
    );
  }

  private resolveTreeItems(
    path: string[],
    tree: DavinciCoreModel.NavigationItem[]
  ): DavinciCoreModel.NavigationItem[] {
    const currentPath = path.shift();
    const pathResolved = path.length === 0;
    let item: DavinciCoreModel.NavigationItem|null = null;

    for (const testItem of tree) {
      if (testItem.route === currentPath) {
        item = testItem;
        break;
      }
    }

    let subItems;
    if (item && item.sub) {
      const itemHasSubroutes = !!(item.sub.length);
      if (!pathResolved && itemHasSubroutes) {
        path[0] = currentPath + path[0];
        subItems = this.resolveTreeItems(
          path,
          item.sub
        );
      }
    }

    let result = item ? [item] : [];
    if (subItems && subItems.length) {
      result = result.concat(subItems);
    }

    return result;
  }
}
