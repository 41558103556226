import { Celsius, MeterPerSec } from '../model/model';

/**
 * @param temperature Temperature input (in Celsius).
 * @param sos Speed of sound input.
 *
 * @returns Molecular weight based on
 */
export function calculateMolecularWeight(temperature: Celsius, sos: MeterPerSec): number {
  const kappa = 1.4;
  return (kappa * 8314 * temperature) / (sos * sos);
}
