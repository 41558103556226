import { Injectable } from '@angular/core';
import {
  User,
  UserManager,
} from 'oidc-client';
import { OidcClientConfig } from './open-id.model';

@Injectable()
export class AuthService {
  oidcClientConfig: OidcClientConfig | null;
  redirectUrl = '/';

  private oidcUserManager: UserManager;
  private user: User | null = null;

  initializeUser() {
    if (this.oidcClientConfig != null) {
      this.oidcUserManager = new UserManager(this.oidcClientConfig.userManagerSettings);

      this.oidcUserManager.getUser().then(user => {
        this.user = user;
      });
      this.oidcUserManager.events.addAccessTokenExpiring(() => {
        this.oidcUserManager.startSilentRenew();
      });
    } else {
      throw new Error('No OidcClientConfig is defined. Set oidcClientConfig in AuthService first.');
    }
  }

  isLoggedIn() {
    return this.user != null && !this.user.expired;
  }

  getUserProfile() {
    return this.user ? this.user.profile : undefined;
  }

  getUser(): Promise<User|null> {
    return this.oidcUserManager.getUser();
  }

  getAccessToken(): Promise<string> {
    return this.oidcUserManager.getUser().then(user => user ? user.access_token : '');
  }

  renewToken(): Promise<User> {
    return this.oidcUserManager.signinSilent();
  }

  logout(): Promise<void> {
    return this.oidcUserManager.signoutRedirect();
  }

  startAuthentication(): Promise<void> {
    return this.oidcUserManager.signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    return this.oidcUserManager.signinRedirectCallback()
      .then(user => {
        this.oidcUserManager.storeUser(user);
        this.user = user;
      }).catch();
  }

  silentRefresh() {
    this.oidcUserManager.signinSilentCallback()
      .catch((err) => {
        console.error(err);
      });
  }

  silenetRenew() {
    this.oidcUserManager.startSilentRenew();
  }
}
