import { Injectable } from '@angular/core';

@Injectable()
export class DavinciCorePersistenceService {
  private readonly davinciKey = 'DaVinCI';
  private appKey: string;
  private prefix: string;

  public init(appKey: string, version: [number, number, number]) {
    if (this.prefix) {
      throw new Error('DavinciCorePersistenceService is initialized already!');
    }

    if (appKey.length < 5) {
      throw new Error('DavinciCorePersistenceService :: AppKey must be at least 5 characters long!');
    }

    this.appKey = appKey;
    this.prefix = this.davinciKey + '_' + this.appKey + '-' + version.join('.') + '_';
  }

  public setData(key: string, data: any) {
    if (!this.prefix) {
      throw new Error('DavinciCorePersistenceService must be initialized before using it! ' +
       'Call DavinciCoreService.init() in your root-component');
    }
    window.localStorage.setItem(this.prefix + key, JSON.stringify(data));
  }

  public getData(key: string): any {
    if (!this.prefix) {
      throw new Error('DavinciCorePersistenceService must be initialized before using it! ' +
        'Call DavinciCoreService.init() in your root-component');
    }

    const storeContent = window.localStorage.getItem(this.prefix + key);
    if (storeContent) {
      return JSON.parse(storeContent);
    } else {
      return undefined;
    }
  }
}
