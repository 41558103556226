import {
  Injectable,
  NgZone
} from '@angular/core';
import { BehaviorSubject, } from 'rxjs';
import { DavinciCoreModel } from './davinci-core.model';
import { DavinciCoreService } from './davinci-core.service';

@Injectable()
export class DavinciCoreTasksService {
  public tasks$: BehaviorSubject<DavinciCoreModel.Task[]> = new BehaviorSubject([]);
  public active$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // ToDo: tasks-attribute can be removed in favour of tasks$.getValue()
  private tasks: DavinciCoreModel.Task[] = [];
  private pollingIntervalId;

  constructor(private core: DavinciCoreService, private ngZone: NgZone) {}

  public cancel(task: DavinciCoreModel.Task) {
    if (task && !task.canceled) {
      task.canceled = true;
      if (task.reject) {
        task.reject(new Error('task ' + task.name + ' was canceled'));
      }
      this.removeTask(task);
    }
  }

  public start(task: DavinciCoreModel.Task): Promise<any> {
    if (task && task.canceled || task.startTime || this.tasks.indexOf(task) > -1) {
      throw new Error('This task was already started or canceled: ' + JSON.stringify(task));
    }

    task.startTime = new Date().getTime();
    task.canceled = false;
    this.core.uiDisabled$.next(this.core.uiDisabled$.getValue() || task.blocking);

    return new Promise((resolve, reject) => {
      task.reject = reject;
      task.resolve = resolve;
      this.tasks.push(task);
      this.tasks$.next([...this.tasks]);

      task.callback.action()
        .then(
          result => this.onActionResolved(task, result),
          error => this.onActionRejected(task, error)
        )
        .catch(reject);

      this.updateProgress(task);
    });
  }

  public startAllStatusPolling(interval?: number) {
    this.stopAllStatusPolling();
    interval = interval || 2500;
    this.ngZone.runOutsideAngular(() => {
      this.pollingIntervalId = setInterval(() => {
        this.ngZone.run(() => this.updateAllTasksProgress());
      }, interval);
    });
  }

  public stopAllStatusPolling() {
    if (!this.pollingIntervalId) {
      return;
    }

    clearInterval(this.pollingIntervalId);
    this.pollingIntervalId = undefined;
  }

  private updateAllTasksProgress() {
    this.tasks.forEach((task) => {
      this.updateProgress(task);
    });
  }

  private async updateProgress(task: DavinciCoreModel.Task) {
    if (task && !task.startTime || task.canceled || !(this.tasks.indexOf(task) > -1)) {
      throw new Error('This was not started or it is canceled: ' + JSON.stringify(task));
    }

    let progress = -1;
    if ('progress' in task.callback) {
      try {
        if (task && task.callback && task.callback.progress) {
          progress = await task.callback.progress();
          if (typeof progress === 'string' || typeof progress === 'undefined') {
            progress = -1;
          }
        }
      } catch (error) {
        console.error('An error occurred while fetching the current progress of the task "' + task.name + '"', error);
      }
      progress = Math.max(-1, progress);
      progress = Math.min(100, progress);
    }

    task.progress = progress;
  }

  private onActionResolved(task: DavinciCoreModel.Task, result: any) {
    this.removeTask(task);
    if (!task.canceled && task.resolve) {
      task.resolve(result);
    }
  }

  private onActionRejected(task: DavinciCoreModel.Task, error: any) {
    this.removeTask(task);
    if (!task.canceled && task.reject) {
      task.reject(error);
    }
  }

  private updateUiBlocking() {
    const blocked = this.tasks
      .map(task => task.blocking)
      .reduce((prev, current) => prev || current, false);
    this.core.uiDisabled$.next(blocked);
  }

  private removeTask(task: DavinciCoreModel.Task) {
    const taskIndex = this.tasks.indexOf(task);
    if (task && taskIndex > -1) {
      this.tasks.splice(taskIndex, 1);
      this.tasks$.next([...this.tasks]);
      this.updateUiBlocking();
      if (!this.tasks.length) {
        this.stopAllStatusPolling();
      }
    }
  }
}

