import {
  Component,
  Input
} from '@angular/core';
import { DavinciCoreTasksService } from '../../davinci-core-tasks.service';
import { DavinciCoreModel } from '../../davinci-core.model';

@Component({
  selector: 'sick-davinci-task-progress',
  templateUrl: './task-progress.component.html',
  styleUrls: ['./task-progress.component.scss']
})
export class DavinciTaskProgressComponent {
  @Input()
  task: DavinciCoreModel.Task;

  constructor(public tasks: DavinciCoreTasksService) {}
}
