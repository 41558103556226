export function patchEnvelopeValue(
  vog: any,
  uncertainty: any,
  speedUncertaintyMax: any,
  speedUncertaintyPercent: any,
  speedUncertaintyAbove: any
): any {
  // low vog threshold
  let threshold = 0.0;
  if (vog <= speedUncertaintyMax) {
    threshold = Number(speedUncertaintyPercent);
  } else {
    threshold = Number(speedUncertaintyAbove);
  }
  if (uncertainty <= threshold) {
    // below threshold - draw along threshold line
    return threshold;
  }
  return uncertainty;
}

/*
  function adds support points into the Charts x and y values, in case of envelope and the uncertainty is below the threshhold
  all vog and uncertainty input points are copied as they are, just support points are injected if needed
*/
export function patchEnvelopeSupportPoints(
  vog_in: any,
  uncertainty_in: any,
  speedUncertaintyMax: any,
  speedUncertaintyPercent: any,
  speedUncertaintyAbove: any
): any {
  const vog_out = new Array();
  const uncertainty_out = new Array();
  // low vog threshold
  let threshold = 0.0;
  let vog_last = 0;
  let uncertainty_last = 0;

  const debugOn = 0;
  const debug = (message: String, v1Name: String, v1, v2Name: String, v2) => {
    if (debugOn > 0) {
      console.log(message + ' ' + v1Name + ':' + v1 + '  ' + v2Name + ':' + v2);
    }
  };

  vog_in.forEach((vog: any, idx) => {
    let addSupportPoint = false;
    if (vog <= speedUncertaintyMax) {
      threshold = Number(speedUncertaintyPercent);
    } else {
      threshold = Number(speedUncertaintyAbove);
    }

    // need to add special points ? (is vog[x-1] with high uncertainty limit and vog[x] with low uncertainty limit )
    if (vog_last <= speedUncertaintyMax && vog > speedUncertaintyMax) {
      debug('Supportpoints at', 'vog_last', vog_last, 'vog', vog);
      addSupportPoint = true;
    }

    if (addSupportPoint && uncertainty_last <= speedUncertaintyPercent) {
      // upper support point
      debug(
        'upper point to add',
        'speedUncertaintyMax',
        speedUncertaintyMax,
        'speedUncertaintyPercent',
        Number(speedUncertaintyPercent)
      );
      vog_out.push(speedUncertaintyMax);
      uncertainty_out.push(Number(speedUncertaintyPercent));
    }

    if (uncertainty_in[idx] <= threshold) {
      if (addSupportPoint && uncertainty_in[idx] <= speedUncertaintyAbove) {
        // lower support point
        debug(
          'lower point to add',
          'speedUncertaintyMax',
          speedUncertaintyMax,
          'speedUncertaintyAbove',
          Number(speedUncertaintyAbove)
        );
        vog_out.push(speedUncertaintyMax);
        uncertainty_out.push(Number(speedUncertaintyAbove));
      }
    }

    vog_out.push(vog);
    uncertainty_out.push(uncertainty_in[idx]);

    vog_last = vog;
    uncertainty_last = uncertainty_in[idx];
  });
  debug('final points', 'vog_out', vog_out, 'uncertainty_out', uncertainty_out);

  return { x_out: vog_out, y_out: uncertainty_out };
}
