import { PersistenceService } from '../services';
import { convertParameterMatrixBackend2View } from '../utils/parameters';
import { uuid } from '../utils/uuid';
import { DEFAULT_VOGS } from './consts';
import { UncertaintyInputData } from './model';
import { environment } from 'src/environments/environment';

export const defaultParameterMatrix = convertParameterMatrixBackend2View(
  [
    [4e-9, 6.000000000000001e-9, 5.000000000000001e-7, 4e-9, 4e-9, 1.8e-8, 1.8e-8, 4e-9, 4e-9],
    [2.3e-9, 4.500000000000001e-9, 5.000000000000001e-7, 2.3e-9, 2.3e-9, 1.51e-8, 1.51e-8, 2.3e-9, 2.3e-9],
    [77.5, 77.5, 77.5, 75, 77.5, 77.5, 77.5, 75, 46],
    [0.01, 0.01, 0.01, 0.01, 0.005, 0.005, 0.005, 0.005, 0.005],
    [0.3, 0.03, 0, 0, 0, 0, 0, 0, 0],
    [55, 0, 0, 0, 0, 0, 0, 0, 0],
    [1.2, 0, 0, 0, 0, 0, 0, 0, 0],
    [0.001, 0.00005, 0, 0, 0, 0, 0, 0, 0],
    [0.0005, 0.00005, 0.03, 0.0001, 0.03, 0, 0, 0, 0],
  ],
  'metric'
);

export const defaultUncertaintyInputData: () => UncertaintyInputData = () => {
  const ret: UncertaintyInputData = {
    id: uuid(),
    version: PersistenceService.VERSION,
    frontendVersion: environment.frontendVersion,
    backendVersion: '',
    projectName: '',
    projectType: 'projectTypeQuoting',
    tag: '',
    poNumber: '',
    partNumber: '',
    serialNumber: '',
    reference: '',
    diameter: 12,
    nozzleLength: '',
    deviceType: '',
    installType: '',
    pathConfiguration: '',
    exZone: '1',
    explanation: '',

    user: '',
    userList: [],

    unitSystem: 'metric',
    min: {
      active: true,
      pressure: null,
      temperature: null,
      speed: null,
      agc: null,
    },
    norm: {
      active: true,
      pressure: null,
      temperature: null,
      speed: null,
      agc: null,
    },
    max: {
      active: true,
      pressure: null,
      temperature: null,
      speed: null,
      agc: null,
    },
    activeSoundCorrelation: false,
    envelope: true,
    m0deviation: false,

    speedOfSoundMethod: 'sos-own',
    speedUncertaintyPercent: '20',
    speedUncertaintyMax: '0.3',
    speedUncertaintyAbove: '5',

    extendedData: {
      useCustomAngle: false,
      customAngleValue: 0,
      additionalPathAngle: 60,
      useUnpairedProbesTime: false,
      unpairedProbesTimeDiff: 10,
      useCustomZeroPointUncertainty: false,
      customZeroPointUncertainty: 10,
    },

    solutions: {
      m1: true,
      m2a: false,
      m3a: false,
      m3b: false,
      m3c: false,
      m4a: false,
      m4b: false,
      deviceTypeM3a: '0', // must be string, otherwise the davinci-dropdown is not working properly
      deviceTypeM3b: '0', // must be string, otherwise the davinci-dropdown is not working properly
      angleTypeM3b: '0',
      deviceTypeM3c: '0', // must be string, otherwise the davinci-dropdown is not working properly
      vMinLimit1: 0, // M1
      vMinLimit2: 0, // M1+m2
      vMinLimit3: 0, // M1+M2+M3a/c
      vMinLimit4: 0, // M1+M2+M3b
    },

    gasComposition: {
      argon: '0.0',
      carbondioxid: '0.0',
      carbonMonoxid: '0.0',
      ethan: '10.0',
      helium: '0.0',
      hydrogen: '0.0',
      hydrogenSulfid: '0.0',
      iButhan: '0.0',
      iPenthan: '0.0',
      methan: '89.0',
      nButhan: '0.0',
      nDecane: '0.0',
      nHepthan: '0.0',
      nHexan: '0.0',
      nitrogen: '0.01',
      nNonane: '0.0',
      nOcthan: '0.0',
      nPenthan: '0.0',
      oxygen: '0.0',
      propan: '0.0',
      water: '0.99',
    },
    molWeightAndKappa: {
      minMolWeight: '0.0',
      normMolWeight: '0.0',
      maxMolWeight: '0.0',
      minIsentropic: '0.0',
      normIsentropic: '0.0',
      maxIsentropic: '0.0',
    },
    vogVector: {
      velo1: DEFAULT_VOGS[0].toFixed(2),
      velo2: DEFAULT_VOGS[1].toFixed(2),
      velo3: DEFAULT_VOGS[2].toFixed(2),
      velo4: DEFAULT_VOGS[3].toFixed(2),
      velo5: DEFAULT_VOGS[4].toFixed(2),
      velo6: DEFAULT_VOGS[5].toFixed(2),
    },
    parameters: defaultParameterMatrix,

    alarmMarker: {
      agc: false,
      spec: false,
      solution: false,
      customSolution: false,
      genericMessages: [],
    },

    results: {
      calculatedParams: {
        min: {
          molWeight: 0,
          maxVelocity: 0,
          maxFlowRate: 0,
          maxVelocityAsc: 0,
          maxFlowRateAsc: 0,
        },
        norm: {
          molWeight: 0,
          maxVelocity: 0,
          maxFlowRate: 0,
          maxVelocityAsc: 0,
          maxFlowRateAsc: 0,
        },
        max: {
          molWeight: 0,
          maxVelocity: 0,
          maxFlowRate: 0,
          maxVelocityAsc: 0,
          maxFlowRateAsc: 0,
        },
      },
      resultRows: [],
      envelopeResultRows: [],
    },
  };

  return ret;
};
