import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OAuthServiceMock {
  configure() {
    //
  }

  hasValidIdToken() {
    return true;
  }

  async loadDiscoveryDocument() {
    return Promise.resolve({ id: '' });
  }

  getIdentityClaims() {
    return {
      name: 'local development',
      company: 'SICK AG',
      sub: '12345678-abcd-1234-abcd-1234567abcde',
    };
  }

  logout() {
    return true;
  }
}
