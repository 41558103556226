import { environment } from '../../../environments/environment';

export function log(url: string, query: object, result: any) {
  const color = 'white';
  const backgroundColor = 'DarkOrange';
  const queryMarkerPosition = url.indexOf('?');
  const shortUrl = url.slice(environment.apiUrl.length, queryMarkerPosition === -1 ? url.length : queryMarkerPosition);
  console.groupCollapsed(
    `%c [OctaveService]  ${shortUrl}`,
    `color: ${color}; background:${backgroundColor}; padding:2px`
  );
  console.log('Url:', url);
  console.log('Query: ', query);
  console.log('Result: ', result.result ? result.result : '<no result>');
  console.groupEnd();
}
