import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DEFAULT_VOGS } from 'src/app/model/consts';
import {
  convertViewModel2DataModel,
  convertDataModel2ViewModel,
  convertViewModelToViewModel,
} from 'src/app/utils/convert';
import { UncertaintyInputData, UnitSystemLabels } from 'src/app/model/model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-vog-vector-dialog',
  templateUrl: './vog-vector-dialog.component.html',
  styleUrls: ['./vog-vector-dialog.component.scss'],
})
export class VogVectorDialogComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() units: UnitSystemLabels;

  constructor(private dataService: DataService) {}

  hasError(path: string): boolean {
    const formControl = this.form.get(['vogVector', path]);
    return !formControl.valid && formControl.touched;
  }

  setDefaults() {
    const data = this.dataService.data;
    const model = convertViewModel2DataModel(data);

    model.vogVector.velo1 = DEFAULT_VOGS[0];
    model.vogVector.velo2 = DEFAULT_VOGS[1];
    model.vogVector.velo3 = DEFAULT_VOGS[2];
    model.vogVector.velo4 = DEFAULT_VOGS[3];
    model.vogVector.velo5 = DEFAULT_VOGS[4];
    model.vogVector.velo6 = DEFAULT_VOGS[5];

    const nextFormData = convertDataModel2ViewModel(model, data.unitSystem);

    this.form.patchValue(nextFormData);
  }

  ngOnInit() {}
}
