import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { DavinciCoreService } from '../../../davinci-core.service';
import { DavinciCoreModel } from '../../../davinci-core.model';

@Component({
  selector: 'sick-davinci-toolbar-submenu',
  templateUrl: './davinci-toolbar-submenu.component.html',
  styleUrls: ['./davinci-toolbar-submenu.component.scss']
})
export class DavinciToolbarSubmenuComponent implements OnInit {
  item: DavinciCoreModel.ToolbarMenuItem | null = null;
  tree: DavinciCoreModel.ToolbarMenuItem[] | null = null;

  @Input()
  level = 1;

  @Input()
  menu: DavinciCoreModel.ToolbarMenuItem|DavinciCoreModel.ToolbarMenuItem[];

  constructor(private davinciCore: DavinciCoreService) {}

  ngOnInit() {
    if (Array.isArray(this.menu)) {
      this.tree = this.menu;
    } else {
      this.item = this.menu;
    }
  }

  onItemClicked(item: DavinciCoreModel.ToolbarMenuItem, event: Event) {
    event.stopPropagation();
    if (event.type === 'click') {
      if (!item.disabled && item.callback) {
        item.callback();
      }
      this.davinciCore.menuCloser$.next();
    }
  }
}
