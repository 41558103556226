import { md5 } from './md5';
import { UncertaintyData } from '../model/model';

export function tagResults(input: UncertaintyData) {
  // Those data are relevant for the results to be shown or not
  const {
    activeSoundCorrelation,
    envelope,
    m0deviation,
    deviceType,
    diameter,
    nozzleLength,
    extendedData,
    exZone,
    gasComposition,
    installType,
    max,
    min,
    molWeightAndKappa,
    norm,
    parameters,
    pathConfiguration,
    speedOfSoundMethod,
    speedUncertaintyAbove,
    speedUncertaintyMax,
    speedUncertaintyPercent,
    vogVector,
  } = input;

  const tagObj = {
    activeSoundCorrelation,
    envelope,
    m0deviation,
    deviceType,
    diameter,
    nozzleLength,
    extendedData,
    exZone,
    gasComposition,
    installType,
    max,
    min,
    molWeightAndKappa,
    norm,
    parameters,
    pathConfiguration,
    speedOfSoundMethod,
    speedUncertaintyAbove,
    speedUncertaintyMax,
    speedUncertaintyPercent,
    vogVector,
  };

  return md5(JSON.stringify(tagObj));
}
