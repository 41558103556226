import { Component, OnInit, ViewChild } from '@angular/core';
import { DEVICE_LIST } from 'src/app/model/consts';
import { FlowsicLogEntry, PersistenceService } from 'src/app/services/persistence.service';
import { defaultUncertaintyInputData } from 'src/app/model/data';
import { UncertaintyInputData } from 'src/app/model/model';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { convertDataModel2ViewModel } from 'src/app/utils/convert';
import { OAuthService } from 'angular-oauth2-oidc';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss'],
})
export class ProjectsPageComponent implements OnInit {
  constructor(
    private persistenceService: PersistenceService,
    private dataService: DataService,
    private authService: OAuthService,
    private router: Router
  ) {}

  state: 'loading' | 'ready' | 'error' = 'loading';

  deviceList = DEVICE_LIST;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  data: FlowsicLogEntry[] = [];

  page: PageEvent = {
    length: 0,
    pageSize: 5,
    pageIndex: 0,
  };
  showDeleteUserRecordDialog = false;
  private projectToDelete: FlowsicLogEntry = null;

  //
  // Share Dialog
  //
  shareDialog = {
    open: false,
    project: defaultUncertaintyInputData(),
    usersList: [],

    openDialog: (project: UncertaintyInputData) => {
      this.shareDialog.project = project;
      this.shareDialog.usersList = [...project.userList];
      this.shareDialog.open = true;
    },

    cancel: () => {
      this.shareDialog.open = false;
      this.shareDialog.usersList = [];
    },

    ok: () => {
      this.shareDialog.open = false;
      const project = this.shareDialog.project;
      project.userList = this.shareDialog.usersList;
      this.persistenceService.saveUserData(project as any, project.unitSystem);
    },

    onNextUserList: (list: string[]) => {
      this.shareDialog.usersList = list;
    },
  };

  get filteredData(): FlowsicLogEntry[] {
    const searchRegEx = new RegExp(this._searchTerm.trim(), 'im');
    const res = this.data
      .filter(
        (entry) =>
          searchRegEx.test(entry.projectName) ||
          searchRegEx.test(entry.reference) ||
          searchRegEx.test(entry.tag) ||
          searchRegEx.test(entry.serialNumber) ||
          searchRegEx.test(entry.poNumber) ||
          searchRegEx.test(entry.partNumber) ||
          searchRegEx.test(DEVICE_LIST[entry.deviceType]) ||
          searchRegEx.test(entry.displayUnitSystem) ||
          searchRegEx.test(entry.userList.join(' '))
      )
      .sort((a, b) => b.created.localeCompare(a.created));

    return res;
  }

  get pagedData(): FlowsicLogEntry[] {
    const { pageSize, pageIndex } = this.page;
    const start = pageSize * pageIndex;
    const end = start + pageSize;
    return this.filteredData.slice(start, end);
  }

  // Search
  _searchTerm = '';
  get searchTerm() {
    return this._searchTerm;
  }

  set searchTerm(newValue: string) {
    this._searchTerm = newValue;
    this.page.pageIndex = 0;
    this.paginator.pageIndex = 0;
  }

  formatUsers(item: FlowsicLogEntry) {
    const users = [...item.userList];
    return users.join(', ');
  }

  fetchProjects() {
    this.state = 'loading';
    this.persistenceService.getUserRecord().subscribe(
      (res) => {
        this.data = res;
        this.cleanupProjectProperties();
        this.state = 'ready';
      },
      () => {
        this.state = 'error';
      }
    );
  }

  cleanupProjectProperties() {
    this.data.forEach((element) => {
      for (const prop in element) {
        if (!element.hasOwnProperty(prop)) {
          continue;
        }
        if (typeof element[prop] === 'string') {
          element[prop] = element[prop].replace(/\0.*$/g, '');
        }
      }
    });
  }

  newProject() {
    this.dataService.newProject();
    this.router.navigate(['/home']);
  }

  editProject(project: FlowsicLogEntry) {
    console.log('loading project: ', project);
    const viewModel = convertDataModel2ViewModel(project, project.displayUnitSystem);
    this.dataService.data = viewModel;
    this.router.navigate(['/home']);
  }

  projectEditDialogDelete(project: FlowsicLogEntry) {
    this.showDeleteUserRecordDialog = true;
    this.projectToDelete = project;
  }

  //
  // Delete UserRecord Dialog
  //
  async deleteUserRecordDialogYes() {
    this.showDeleteUserRecordDialog = false;
    await this.persistenceService.deleteUserRecord(this.projectToDelete);
    this.data = [];
    this.fetchProjects();
  }

  deleteUserRecordDialogCancel() {
    this.showDeleteUserRecordDialog = false;
    this.projectToDelete = null;
  }

  ngOnInit() {
    this.fetchProjects();
  }

  get actualUserId() {
    return this.authService.getIdentityClaims()['sub'];
  }

  onPage(pageEvent: PageEvent) {
    this.page = pageEvent;
  }
}
