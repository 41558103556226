export const DEVICE_LIST = [
  '', // 0
  'EX-S 135 kHz', // 1
  'EX-S 80 kHz', // 2
  'EX 42 kHz', // 3
  'EX-PR', // 4
  'F1F-S', // 5
  'F1F-M', // 6
  'F1F-H', // 7
  'F1F-P', // 8
  'F1F-R/EX-S90', // 9
];
export const DEFAULT_VOGS = [0.03, 0.1, 0.3, 1.0, 3.0, 10.0];

export const PARAMETER_MATRIX_ROWS = 9;
export const PARAMETER_MATRIX_COLS = 9;

/**
 * path angle in degree
 */

export const ALPHA_PR = 75;
export const ALPHA_CD = 77.5;

export const RANGES = {
  temperature: {
    from: -196,
    to: 280,
  },
  pressure: {
    from: 0.5,
    to: 21,
  },
  sos: {
    from: 220,
    to: 650,
  },
};

export type NominalSize =
  | 3
  | 4
  | 6
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 22
  | 24
  | 26
  | 28
  | 30
  | 32
  | 34
  | 36
  | 38
  | 40
  | 42
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 68
  | 72;

export const NOMINAL_SIZES: NominalSize[] = [
  3,
  4,
  6,
  8,
  10,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  34,
  36,
  38,
  40,
  42,
  44,
  48,
  52,
  56,
  60,
  64,
  68,
  72,
];
