import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserRecord, UserService } from 'src/app/services/user.service';
import { uuid } from 'src/app/utils/uuid';

export interface UserTableElement {
  id: string;
  userfull: string;
  usersickid: string;
  gbc04expert: 0 | 1;
  admin: 0 | 1;
  gbc04: 0 | 1;
  ssu: 0 | 1;
  m0deviation: 0 | 1;
}

type UserTableElementKeys = keyof UserTableElement;

type UserEditDialogType = 'new-user' | 'edit-user';

interface UserEditFormData {
  id: string;
  userfull: string;
  usersickid: string;
  gbc04expert: boolean;
  admin: boolean;
  gbc04: boolean;
  ssu: boolean;
  m0deviation: boolean;
}

const initUserEditForm = () =>
  new FormBuilder().group({
    id: [''],
    userfull: ['', [Validators.required]],
    usersickid: ['', [Validators.required, Validators.email]],
    gbc04expert: [false, []],
    admin: [false, []],
    gbc04: [false, []],
    ssu: [false, []],
    m0deviation: [false, []],
  });

const convertFormToTable: (input: UserEditFormData) => UserTableElement = (input) => ({
  id: input.id,
  userfull: input.userfull.trim(),
  usersickid: input.usersickid.trim().toLowerCase(),
  gbc04expert: input.gbc04expert ? 1 : 0,
  admin: input.admin ? 1 : 0,
  gbc04: input.gbc04 ? 1 : 0,
  ssu: input.ssu ? 1 : 0,
  m0deviation: input.m0deviation ? 1 : 0,
});

const convertTableToForm: (input: UserTableElement) => UserEditFormData = (input) => ({
  id: input.id,
  userfull: input.userfull,
  usersickid: input.usersickid,
  gbc04expert: input.gbc04expert === 1,
  admin: input.admin === 1,
  gbc04: input.gbc04 === 1,
  ssu: input.ssu === 1,
  m0deviation: input.m0deviation === 1,
});

const convertTableToBackend: (input: UserTableElement) => UserRecord = (input) => {
  const { id, ...userRecord } = input;
  return userRecord;
};
@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnInit {
  constructor(private userService: UserService) {}

  displayedColumns: UserTableElementKeys[] = [
    'userfull',
    'usersickid',
    'gbc04expert',
    'admin',
    'gbc04',
    'ssu',
    'm0deviation',
  ];
  dataSource = new MatTableDataSource<UserTableElement>([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  userEditForm = initUserEditForm();
  userEditErrorMessage = '';
  userEditDialogType: UserEditDialogType = 'edit-user';
  showUserEditDialog = false;
  showDeleteUserDialog = false;

  _searchTerm = '';

  get searchTerm() {
    return this._searchTerm;
  }

  set searchTerm(newValue) {
    this._searchTerm = newValue;
    this.dataSource.filter = newValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.fetchUserRecords();
  }

  fetchUserRecords() {
    this.userService.fetchAllUserRecords().subscribe((userRecords) => {
      const data: UserTableElement[] = userRecords.map((record, idx) => ({ id: '' + idx, ...record }));
      this.dataSource = new MatTableDataSource<UserTableElement>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = (entry, filter) =>
        entry.userfull.toLowerCase().indexOf(filter) > -1 || entry.usersickid.toLowerCase().indexOf(filter) > -1;
    });
  }

  //
  // User Edit Dialog
  //

  addUserRecord() {
    const id = uuid();
    const newUserRecord: UserTableElement = {
      id,
      userfull: '',
      usersickid: '',
      gbc04expert: 0,
      admin: 0,
      gbc04: 0,
      ssu: 0,
      m0deviation: 0,
    };
    this.openUserEditDialog(newUserRecord, 'new-user');
  }

  editUserRecord(input: UserTableElement) {
    this.openUserEditDialog(input, 'edit-user');
  }

  openUserEditDialog(input: UserTableElement, type: UserEditDialogType) {
    this.userEditDialogType = type;
    this.patchUserEditForm(input);
    this.showUserEditDialog = true;
  }

  patchUserEditForm(data: UserTableElement) {
    const nextFormData = convertTableToForm(data);
    this.userEditForm.patchValue(nextFormData);
    this.userEditForm.markAsUntouched();
  }

  userEditDialogCancel() {
    this.showUserEditDialog = false;
  }

  userEditDialogDelete() {
    this.showUserEditDialog = false;
    this.showDeleteUserDialog = true;
  }

  userEditDialogSave() {
    this.showUserEditDialog = false;
    const nextUserRecord = convertFormToTable(this.userEditForm.getRawValue());
    this.updatOrCreateUser(nextUserRecord);
  }

  hasError(path: string): boolean {
    const formControl: AbstractControl = this.userEditForm.get(path);
    return formControl.invalid && formControl.touched;
  }

  get userEditDialogIsValid() {
    const formIsValid = !this.userEditForm.invalid;
    const sickIdIsNotInUse = !this.userEditSickIdInUse;

    if (this.userEditDialogType === 'new-user') {
      return formIsValid && sickIdIsNotInUse;
    } else {
      return formIsValid;
    }
  }

  get userEditSickIdInUse() {
    const usersickid = this.userEditForm.get('usersickid').value;
    return this.dataSource.data.some((entry) => entry.usersickid === usersickid);
  }

  //
  // Delete User Dialog
  //
  async deleteUserDialogYes() {
    const userToDelete = convertFormToTable(this.userEditForm.getRawValue());
    this.showDeleteUserDialog = false;
    const records = this.dataSource.data;
    const idx = records.findIndex((entry) => entry.id === userToDelete.id);
    if (idx >= 0) {
      this.dataSource.data = [...records.slice(0, idx), ...records.slice(idx + 1)];
    }
    await this.userService.deleteUserRecord(userToDelete).toPromise();
  }

  delteUserDialogCancel() {
    this.showDeleteUserDialog = false;
  }

  //
  // Utils
  //
  async updatOrCreateUser(userRecord: UserTableElement) {
    const records = this.dataSource.data;
    const idx = records.findIndex((entry) => entry.id === userRecord.id);
    if (idx === -1) {
      // Not found, insert new user
      this.dataSource.data = [...records, userRecord];
      const newBackendUserRecord = convertTableToBackend(userRecord);
      await this.userService.postUserRecord(newBackendUserRecord).toPromise();
    } else {
      // Update user
      const oldRecord = records[idx];
      this.dataSource.data = [...records.slice(0, idx), userRecord, ...records.slice(idx + 1)];
      await this.userService.deleteUserRecord(oldRecord).toPromise();
      await this.userService.postUserRecord(userRecord).toPromise();
    }
  }
}
