import { DEFAULT_VOGS } from 'src/app/model/consts';
import { defaultParameterMatrix } from 'src/app/model/data';
import { UncertaintyInputData } from 'src/app/model/model';
import { PersistenceService } from 'src/app/services';
import { environment } from 'src/environments/environment';

export const formInputFixture: UncertaintyInputData = {
  id: 'test-data',
  version: PersistenceService.VERSION,
  frontendVersion: environment.frontendVersion,
  backendVersion: 'unknown',

  user: '',
  userList: [],

  min: {
    active: true,
    pressure: 1,
    temperature: 20,
    speed: 400,
    agc: 30,
  },
  norm: {
    active: true,
    pressure: 2,
    temperature: 45,
    speed: 500,
    agc: 40,
  },
  max: {
    active: true,
    pressure: 3,
    temperature: 60,
    speed: 600,
    agc: 50,
  },
  extendedData: {
    useCustomAngle: false,
    customAngleValue: 0,
    additionalPathAngle: 60,
    customZeroPointUncertainty: 10,
    useUnpairedProbesTime: false,
    unpairedProbesTimeDiff: 10,
    useCustomZeroPointUncertainty: false,
  },
  solutions: {
    m1: true,
    m2a: false,
    m3a: false,
    m3b: false,
    m3c: false,
    m4a: false,
    m4b: false,
    deviceTypeM3a: '0',
    deviceTypeM3b: '0',
    angleTypeM3b: '0',
    deviceTypeM3c: '0',
    vMinLimit1: 0, // M1
    vMinLimit2: 0, // M1+m2
    vMinLimit3: 0, // M1+M2+M3a/c
    vMinLimit4: 0, // M1+M2+M3b
  },
  projectName: 'Test project',
  projectType: 'projectTypeInstallBased',
  reference: 'xyz 123',
  poNumber: 'po 123',
  partNumber: 'part 123',
  serialNumber: 's-123-456-789',
  tag: 'test',
  diameter: 12,
  nozzleLength: '1',
  deviceType: '1',
  pathConfiguration: '1',
  installType: '1',
  speedUncertaintyPercent: 20,
  speedUncertaintyMax: 1,
  speedUncertaintyAbove: 5,
  unitSystem: 'metric',
  activeSoundCorrelation: false,
  envelope: true,
  m0deviation: false,
  exZone: '1',
  explanation: '',

  speedOfSoundMethod: 'sos-gas-composition',

  gasComposition: {
    argon: '0.0',
    carbondioxid: '0.0',
    carbonMonoxid: '0.0',
    ethan: '0.0',
    helium: '0.0',
    hydrogen: '0.0',
    hydrogenSulfid: '0.0',
    iButhan: '0.0',
    iPenthan: '0.0',
    methan: '99.0',
    nButhan: '0.0',
    nDecane: '0.0',
    nHepthan: '0.0',
    nHexan: '0.0',
    nitrogen: '0.0',
    nNonane: '0.0',
    nOcthan: '0.0',
    nPenthan: '0.0',
    oxygen: '0.0',
    propan: '0.0',
    water: '1.0',
  },

  molWeightAndKappa: {
    minMolWeight: 0,
    normMolWeight: 0,
    maxMolWeight: 0,
    minIsentropic: 0,
    normIsentropic: 0,
    maxIsentropic: 0,
  },

  vogVector: {
    velo1: DEFAULT_VOGS[0].toFixed(2),
    velo2: DEFAULT_VOGS[1].toFixed(2),
    velo3: DEFAULT_VOGS[2].toFixed(2),
    velo4: DEFAULT_VOGS[3].toFixed(2),
    velo5: DEFAULT_VOGS[4].toFixed(2),
    velo6: DEFAULT_VOGS[5].toFixed(2),
  },
  parameters: defaultParameterMatrix,

  alarmMarker: {
    agc: false,
    spec: false,
    solution: false,
    customSolution: false,
    genericMessages: [],
  },

  results: {
    calculatedParams: {
      min: {
        molWeight: 123,
        maxVelocity: 123,
        maxFlowRate: 123,
        maxVelocityAsc: 123,
        maxFlowRateAsc: 123,
      },
      norm: {
        molWeight: 123,
        maxVelocity: 123,
        maxFlowRate: 123,
        maxVelocityAsc: 123,
        maxFlowRateAsc: 123,
      },
      max: {
        molWeight: 123,
        maxVelocity: 123,
        maxFlowRate: 123,
        maxVelocityAsc: 123,
        maxFlowRateAsc: 123,
      },
    },
    resultRows: [],
    envelopeResultRows: [],
  },
};

export const UncertaintyResultFixture = [
  {
    vog: 0.03,
    flowRate: 7.88,
    uncertainty: {
      norm: 34.53,
      max: 49.46,
      min: 22.42,
    },
  },
  {
    vog: 0.1,
    flowRate: 26.268,
    uncertainty: {
      norm: 11.38,
      max: 15.57,
      min: 8.2,
    },
  },
  {
    vog: 0.3,
    flowRate: 78.803,
    uncertainty: {
      norm: 6,
      max: 6.97,
      min: 5.39,
    },
  },
  {
    vog: 1,
    flowRate: 262.677,
    uncertainty: {
      norm: 5.03,
      max: 5.15,
      min: 4.96,
    },
  },
  {
    vog: 3,
    flowRate: 788.031,
    uncertainty: {
      norm: 4.94,
      max: 4.96,
      min: 4.93,
    },
  },
  {
    vog: 10,
    flowRate: 2626.772,
    uncertainty: {
      norm: 4.93,
      max: 4.94,
      min: 4.92,
    },
  },
  {
    vog: 'v_max',
    flowRate: 'Q_max',
    uncertainty: {
      norm: 4.92,
      max: 4.93,
      min: 4.91,
    },
  },
];

export const PreParamsFixture = {
  norm: {
    molWeight: 14.812554959999996,
    maxVelocity: 88.49,
    maxFlowRate: 23244.302,
  },
  max: {
    molWeight: 10.77147983333333,
    maxVelocity: 92.512,
    maxFlowRate: 24300.789,
  },
  min: {
    molWeight: 21.325929624999997,
    maxVelocity: 82.371,
    maxFlowRate: 21636.98,
  },
};

export const UncertaintyInputArgsFixture = [
  {
    min: { active: true, pressure: 1, temperature: 20, speed: 400 },
    norm: { active: true, pressure: 2, temperature: 45, speed: 500 },
    max: { active: true, pressure: 3, temperature: 60, speed: 600 },
  },
  {
    norm: {
      molWeight: 14.812554959999996,
      maxVelocity: 88.49,
      maxFlowRate: 23244.302,
    },
    max: {
      molWeight: 10.77147983333333,
      maxVelocity: 92.512,
      maxFlowRate: 24300.789,
    },
    min: {
      molWeight: 21.325929624999997,
      maxVelocity: 82.371,
      maxFlowRate: 21636.98,
    },
  },
  1,
  12,
  1,
  false,
  1,
];

export const uncertaintyRawResultFixture = {
  result: [
    {
      velocity: '0.030000',
      values: { min: '22.419808', max: '49.461293', norm: '34.529998' },
    },
    {
      velocity: '0.100000',
      values: { min: ' 8.202232', max: '15.567689', norm: '11.375536' },
    },
    {
      velocity: '0.300000',
      values: { min: ' 5.385371', max: ' 6.970570', norm: ' 5.996624' },
    },
    {
      velocity: '1.000000',
      values: { min: ' 4.964725', max: ' 5.152493', norm: ' 5.032923' },
    },
    {
      velocity: '3.000000',
      values: { min: ' 4.926018', max: ' 4.960897', norm: ' 4.939212' },
    },
    {
      velocity: '10.000000',
      values: { min: ' 4.921481', max: ' 4.938581', norm: ' 4.928366' },
    },
    {
      velocity: '82.371000',
      values: { min: ' 4.912663', max: ' 4.932666', norm: ' 4.921930' },
    },
    {
      velocity: '92.512000',
      values: { min: ' 4.910477', max: ' 4.931675', norm: ' 4.920515' },
    },
    {
      velocity: '88.490000',
      values: { min: ' 4.911372', max: ' 4.932081', norm: ' 4.921095' },
    },
  ],
};
