import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { OAuthStorage, OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
  constructor(private oauthStorage: OAuthStorage, private oauthErrorHandler: OAuthResourceServerErrorHandler) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.startsWith(environment.apiUrl)) {
      const token = this.oauthStorage.getItem('access_token');

      if (token) {
        return next
          .handle(
            request.clone({
              headers: request.headers.set('Authorization', `Bearer ${token}`),
              withCredentials: false,
            })
          )
          .pipe(catchError((err) => this.oauthErrorHandler.handleError(err)));
      }
    }

    return next.handle(request);
  }
}
