/* tslint:disable */
import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DEVICE_LIST } from '../model/consts';
import { CalculatedParams, InputNumValue, UncertaintyInputData, UnitSystemLabels, PreParams } from '../model/model';
import { warningSvg } from '../warning.svg';

// SVG icons from https://material-ui.com/components/material-icons/
const svg = {
  check: `<svg width="12" height="12" viewBox="0 0 24 24">
    <path  d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
  </svg>`,
  unchecked: `<svg   width="12" height="12" viewBox="0 0 24 24">
    <path  d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
  </svg>`,
  warn: warningSvg,
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const defaultStyle = {
  fontSize: 8,
};

const styles = {
  header: {
    fontSize: 14,
  },
  footnote: {
    fontSize: 6,
    color: '#333333',
  },
  subHeader: {
    fontSize: 12,
    margin: [0, 10, 0, 5],
  },
  subSubHeader: {
    fontSize: 10,
    margin: [0, 10, 0, 5],
  },
  disclaimer: {
    fontSize: 8,
    margin: [0, 5, 0, 5],
  },
  blue: {
    color: '#007CC1',
  },
  red: {
    color: '#FF0000',
  },
  italic: {
    italics: true,
  },
  warn: {
    fontSize: 10,
    bold: true,
  },
  tableStyle: {
    fontSize: 9,
    bold: true,
    margin: [0, 10, 0, 5],
  },
};

export interface TableRow {
  text?: string;
  colSpan?: number;
  rowSpan?: number;
  alignment?: 'center' | 'left' | 'right';
  style?: string;
  image?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  downloadPdf(
    inputData: UncertaintyInputData,
    _parameter: CalculatedParams,
    data: Array<Array<number | string | TableRow>>,
    units: UnitSystemLabels,
    currentUser: string,
    images: Array<string> = undefined
  ): void {
    const documentDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      footer: (currentPage, pageCount) => [
        { text: `${currentPage.toString()} /  ${pageCount}`, alignment: 'center' },
        {
          text: `Generated: ${new Date().toLocaleString()} by ${currentUser}`,
          alignment: 'right',
          margin: [0, 10, 40, 10],
        },
      ],
      header: (currentPage, pageCount, pageSize) =>
        currentPage != 1
          ? {
              text: `Project: ${inputData.projectName} | TAG Name or Number: ${inputData.tag}`,
              alignment: 'right',
              fontSize: 10,
              margin: [0, 10, 40, 10],
            }
          : '',
      content: [
        ...this.renderHeader(inputData.m0deviation),
        this.renderGeneralInfo(inputData),
        this.renderAlarm(inputData.alarmMarker.spec, 'Device out of specification.'),
        this.renderProcessDataTable(inputData, units),
        this.pageBreak(),
        this.renderSubHeader('Computed Results'),
        this.renderSubSubHeader('Calculated Flow Ranges'),
        this.renderParameterTable(inputData, units),
        this.renderSubSubHeader('Measurement Uncertainties'),
        this.renderHint(inputData.m0deviation, units),
        this.renderResultTable(data),
        this.renderFootnotes(inputData),
        this.renderAlarm(inputData.alarmMarker.agc, 'Signal strength is weak'),
        this.renderSolutionsTable(inputData),
        this.renderAlarm(inputData.alarmMarker.solution, 'No solution found. Please contact GBC04.'),
        this.renderAlarm(inputData.alarmMarker.customSolution, 'Review by Custom Solutions'),
        this.renderVminLimits(inputData, units),
        this.renderChart(images[0]),
        this.renderVersionNumbers(inputData),
        inputData.m0deviation ? this.pageBreak() : null,
        inputData.m0deviation ? this.renderSubHeader('Measurement uncertainties - Installed Base evaluation') : null,
        inputData.m0deviation ? this.renderRedRemark('Confidential', inputData.m0deviation) : null,
        inputData.m0deviation ? this.renderSubSubHeader('Process condition 1: Min', images[1] !== undefined) : null,
        inputData.m0deviation ? this.renderChart(images[1], images[1] !== undefined) : null,
        inputData.m0deviation ? this.renderSubSubHeader('Process condition 2: Norm', images[2] !== undefined) : null,
        inputData.m0deviation ? this.renderChart(images[2], images[2] !== undefined) : null,
        inputData.m0deviation ? this.renderSubSubHeader('Process condition 3: Max', images[3] !== undefined) : null,
        inputData.m0deviation ? this.renderChart(images[3], images[3] !== undefined) : null,
        this.renderDisclaimer(
          inputData.projectType === 'projectTypeInstallBased',
          inputData.activeSoundCorrelation,
          inputData.installType === '2' || inputData.installType === '3'
        ),
      ],
      defaultStyle,
      styles,
    };
    console.log(documentDefinition);
    const fileName =
      'Flare-Evaluation-Datasheet-' +
      inputData.projectName.replace(/[<>:"/\|.?*]/g, '') +
      '_' +
      new Date().toISOString().substring(0, 10);
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  renderRedRemark(arg0: string, cond: boolean) {
    if (cond) {
      return { text: arg0, style: ['header', 'red', 'italic'] };
    }
  }

  private renderHeader(cond: boolean) {
    const header = [
      {
        text: 'FLARE Gas Application Evaluation Datasheet',
        style: ['header', 'blue'],
      },
      {
        text: 'FLOWSIC100 Flare / FLOWSIC100 Flare-XT\n\n',
        style: ['header'],
      },
      {
        text: 'General Information',
        style: ['subHeader', 'blue'],
      },
    ];
    if (cond) {
      header.splice(2, 0, { text: 'Confidential', style: ['header', 'red', 'italic'] });
    }
    return header;
  }

  private renderSubHeader(headerText: string) {
    return {
      text: headerText,
      style: ['subHeader', 'blue'],
    };
  }

  private renderSubSubHeader(headerText: string, cond: boolean = true) {
    if (cond) {
      return {
        text: headerText,
        style: ['subSubHeader', 'blue'],
      };
    }
  }

  private renderHint(cond: boolean, units: UnitSystemLabels) {
    const table = {
      widths: ['20%', '20%', '60%'],
      body: [
        ['VoG [' + units.speed + ']', 'Flowrate [' + units.flowRate + ']', 'Measurement Uncertainty of Flow (2σ) [%]'],
      ],
    };
    return {
      style: 'tableStyle',
      layout: 'noBorders',
      table,
    };
  }

  private renderDeviceSelectionFootnotes(data: UncertaintyInputData) {
    const style = { style: ['footnote'] };
    return [
      {
        text:
          data.installType === '1'
            ? '¹ Flare gas measuring instrument, tapped installation or loose spool piece from SICK qualified supplier. Sensor integration on customer site.'
            : '',
        ...style,
      },
      {
        text:
          data.installType === '2'
            ? '² Flare gas meter, measured spool piece from SICK with sensor integration and testing at factory site.'
            : '',
        ...style,
      },
      {
        text:
          data.installType === '3'
            ? '³ Flare gas meter, measured spool piece from SICK with sensor integration and testing at factory site. Additional flow calibration.'
            : '',
        ...style,
      },
    ];
  }

  private renderGeneralInfo(inputData: UncertaintyInputData) {
    const installType =
      {
        1: 'Instrument ¹',
        2: 'Meter, SICK spool ²',
        3: 'Meter, SICK spool, flow-calib.³',
      }[inputData.installType] || '';

    const nozzleLength =
      {
        1: '9"',
        2: '10"',
        3: '6"',
      }[inputData.nozzleLength] || '';

    const exZone =
      {
        1: 'Zone IIc',
        2: 'Zone IIa',
        3: 'Zone 2',
      }[inputData.exZone] || '';

    var m0Label = '';
    var m0Value = { text: '', bold: false };
    if (inputData.m0deviation) {
      m0Label = 'Estim. deviation w/o correction';
      m0Value = { text: 'Enabled', bold: true };
    }
    var typeLabel = 'Type';
    var typeValue = { text: 'Quoting', bold: true };
    if (inputData.projectType === 'projectTypeInstallBased') {
      typeValue = { text: 'Install based', bold: true };
    }

    const customerDataTable = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 0,
        widths: ['50%', '50%'],
        styles: {
          fontSize: 12,
        },
        body: [
          ['Project Name', { text: inputData.projectName, bold: true }],
          ['Reference (CRM or SAP)', { text: inputData.reference, bold: true }],
          ['TAG Name or Number', { text: inputData.tag, bold: true }],
        ],
      },
    };

    // find valid inner diameter value
    const deviceSelectionDataTable = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 0,
        widths: ['50%', '50%'],
        styles: {
          fontSize: 12,
        },
        body: [
          ['Device Type', { text: DEVICE_LIST[inputData.deviceType], bold: true }],
          ['Nominal Pipe Width [inches]', { text: inputData.diameter, bold: true }],
          ['Inner Diameter [inches]', { text: inputData.innerDiameter, bold: true }],
          ['Number of Paths', { text: inputData.pathConfiguration, bold: true }],
          ['Installation Type', { text: installType, bold: true }],
          ['EX Zone', { text: exZone, bold: true }],
        ],
      },
    };

    const deviceSelectionDataTableExtGeReplacement = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 0,
        widths: ['50%', '50%'],
        styles: {
          fontSize: 12,
        },
        body: [
          ['Device Type', { text: DEVICE_LIST[inputData.deviceType], bold: true }],
          ['Nominal Pipe Width [inches]', { text: inputData.diameter, bold: true }],
          ['Nozzle distance [inches]', { text: nozzleLength, bold: true }],
          ['Number of Paths', { text: inputData.pathConfiguration, bold: true }],
          ['Installation Type', { text: installType, bold: true }],
          ['EX Zone', { text: exZone, bold: true }],
        ],
      },
    };

    const productReferenceDataTable = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 0,
        widths: ['50%', '50%'],
        styles: {
          fontSize: 12,
        },
        body: [
          ['PO Number', { text: inputData.poNumber, bold: true }],
          ['SICK Part Number', { text: inputData.partNumber, bold: true }],
          ['SICK Serial Number', { text: inputData.serialNumber, bold: true }],
        ],
      },
    };

    let projectExplanations = {};
    let projectExplanationsHeader = {};
    console.log(typeof inputData.explanation + '  value:' + inputData.explanation);
    console.log('check: ' + inputData.explanation !== null);
    if (inputData.explanation !== '' && inputData.explanation !== null) {
      projectExplanationsHeader = this.renderSubSubHeader('Project Explanations');
      projectExplanations = {
        text: inputData.explanation,
      };
    }

    return [
      this.renderSubSubHeader('Customer Data'),
      customerDataTable,
      this.renderSubSubHeader('Device Selection'),
      inputData.deviceType === '9' ? deviceSelectionDataTableExtGeReplacement : deviceSelectionDataTable,
      this.renderDeviceSelectionFootnotes(inputData),
      this.renderSubSubHeader('Order Reference'),
      productReferenceDataTable,
      projectExplanationsHeader,
      projectExplanations,
    ];
  }

  private renderProcessDataTable(data: UncertaintyInputData, units: UnitSystemLabels) {
    const { molWeightAndKappa } = data;
    const {
      minMolWeight,
      normMolWeight,
      maxMolWeight: maxMolWeight,
      minIsentropic,
      normIsentropic,
      maxIsentropic,
    } = molWeightAndKappa;

    const labelMap = {
      pressure: 'Pressure [' + units.pressure + ']',
      temperature: 'Temperature [' + units.temperature + ']',
      sos: 'Speed of Sound [' + units.speed + ']',
      computeSos: 'Computed speed of sound [' + units.speed + ']',

      mol: 'Mol weight [' + units.mol + ']',
      kappa: 'Isentropic Exponent \u03BA',
    };

    const empty = { pressure: '-', temperature: '-', speed: '-' };
    const min = data.min.active ? data.min : empty;
    const norm = data.norm.active ? data.norm : empty;
    const max = data.max.active ? data.max : empty;

    // Table Rows
    const headerRow = ['', 'min', 'norm', 'max'];
    const pressureRow = [labelMap.pressure, min.pressure, norm.pressure, max.pressure];
    const temperatureRow = [labelMap.temperature, min.temperature, norm.temperature, max.temperature];
    const sosRow = [labelMap.sos, min.speed, norm.speed, max.speed];
    const computedSosRow = [labelMap.computeSos, min.speed, norm.speed, max.speed];

    const processTable = (...body: any) => ({
      layout: 'lightHorizontalLines',
      table: {
        widths: ['40%', '20%', '20%', '20%'],
        body,
      },
    });

    const method = (methodText: string) => ({
      text: methodText,
    });

    //
    // Process data based on Gas Composition
    //
    const sosGasComposition = () => {
      const COLS = 6;

      const renderGasCompositionTable = () => {
        const p = (n: any) => n + '%';

        const values: any[][] = [
          ['Methane', data.gasComposition.methan],
          ['Nitrogen', data.gasComposition.nitrogen],
          ['Carbon dioxide', data.gasComposition.carbondioxid],
          ['Ethane', data.gasComposition.ethan],
          ['Propane', data.gasComposition.propan],
          ['n-Butane', data.gasComposition.nButhan],
          ['i-Butane', data.gasComposition.iButhan],
          ['n-Pentane', data.gasComposition.nPenthan],
          ['i-Pentane', data.gasComposition.iPenthan],
          ['n-Hexane', data.gasComposition.nHexan],
          ['n-Heptane', data.gasComposition.nHepthan],
          ['n-Octane', data.gasComposition.nOcthan],
          ['n-Nonane', data.gasComposition.nNonane],
          ['n-Decane', data.gasComposition.nDecane],
          ['Hydrogen', data.gasComposition.hydrogen],
          ['Oxygen', data.gasComposition.oxygen],
          ['Carbon monoxide', data.gasComposition.carbonMonoxid],
          ['Water', data.gasComposition.water],
          ['Hydrogen sulphur', data.gasComposition.hydrogenSulfid],
          ['Helium', data.gasComposition.helium],
          ['Argon', data.gasComposition.argon],
        ];

        let head = 0;
        const rows = [['', '', '', '', '', '']];

        for (let i = 0; i < values.length; i++) {
          const row = Math.floor(head / COLS);
          const col = head % COLS;

          const val = values[i];
          const gasName = val[0];
          const gasPercent = val[1];

          if (gasPercent > 0) {
            rows[row][col] = gasName;
            rows[row][col + 1] = p(gasPercent);
            head = head + 2;

            if (head % COLS === 0) {
              // New row
              rows.push(['', '', '', '', '', '']);
            }
          }
        }

        return {
          layout: 'lightHorizontalLines',
          table: {
            widths: ['25%', '8%', '25%', '8%', '25%', '9%'],
            body: rows,
          },
        };
      };

      return [
        this.renderSubHeader('Process Data'),
        method('Calculation basis: Gas Composition'),
        this.renderSubSubHeader('Gas Composition'),
        renderGasCompositionTable(),
        this.renderSubSubHeader('Process Variables'),
        processTable(headerRow, pressureRow, temperatureRow, computedSosRow),
      ];
    };

    //
    // Process data based on mol and kappa
    //
    const sosMolAndKappa = () => {
      const isMin = (val: any) => (data.min.active ? val : '-');
      const isNorm = (val: any) => (data.norm.active ? val : '-');
      const isMax = (val: any) => (data.max.active ? val : '-');

      const molRow = [labelMap.mol, isMin(minMolWeight), isNorm(normMolWeight), isMax(maxMolWeight)];
      const kappaRow = [labelMap.kappa, isMin(minIsentropic), isNorm(normIsentropic), isMax(maxIsentropic)];

      return [
        this.renderSubHeader('Process Data'),
        method('Calculation basis: Molecular Weight and Isentropic Exponent'),
        processTable(headerRow, pressureRow, temperatureRow, molRow, kappaRow, computedSosRow),
      ];
    };

    //
    // Process data based on user provided SOS
    //
    const soSOwn = () => {
      return [
        this.renderSubHeader('Process Data'),
        method('Calculation basis: User-provided Parameters'),
        processTable(headerRow, pressureRow, temperatureRow, sosRow),
      ];
    };

    //
    //
    if (data.speedOfSoundMethod === 'sos-gas-composition') {
      return sosGasComposition();
    }
    if (data.speedOfSoundMethod === 'sos-mol-and-kappa') {
      return sosMolAndKappa();
    }
    if (data.speedOfSoundMethod === 'sos-own') {
      return soSOwn();
    }
  }

  private renderChart(img: string, cond: boolean = true) {
    if (cond) {
      return {
        image: img,
        width: 450,
      };
    }
  }

  private renderFootnotes(data: UncertaintyInputData) {
    const style = { style: ['footnote'] };
    return [
      {
        text: '¹ For fully developed flow profiles; based on ultrasonic transit time measurement.',
        ...style,
      },
      {
        text: data.activeSoundCorrelation
          ? '² Increased uncertainty at max. VoG when switching to Active Sound Correlation technology (ASC).'
          : '',
        ...style,
      },
      {
        text: data.activeSoundCorrelation
          ? '³ Extended measuring range based on Active Sound Correlation technology (ASC), 130% of last velocity measured with ultrasonic time difference.'
          : '',
        ...style,
      },
    ];
  }

  private renderParameterTable(data: UncertaintyInputData, units: UnitSystemLabels) {
    const maxVelocityLabel = 'Max velocity Vmax [' + units.speed + ']';
    const maxVelocityLabelAsc = 'Max velocity (ASC) [' + units.speed + ']';
    const maxFlowRateLabel = 'Max flow rate Qmax [' + units.flowRate + ']';
    const maxFlowRateLabelAsc = 'Max flow rate (ASC) [' + units.flowRate + ']';

    const format = (active: boolean) => (input: number) =>
      input == null || !active
        ? ''
        : new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 1 }).format(input);

    const formatMin = format(data.min.active);
    const formatNorm = format(data.norm.active);
    const formatMax = format(data.max.active);

    const { min, norm, max } = data.results.calculatedParams;

    const rows = [
      ['', 'min', 'norm', 'max'],
      [maxVelocityLabel, formatMin(min.maxVelocity), formatNorm(norm.maxVelocity), formatMax(max.maxVelocity)],
      [maxFlowRateLabel, formatMin(min.maxFlowRate), formatNorm(norm.maxFlowRate), formatMax(max.maxFlowRate)],
    ];
    // attach ASC results if requested
    if (data.activeSoundCorrelation) {
      rows.push([
        maxVelocityLabelAsc,
        formatMin(min.maxVelocityAsc),
        formatNorm(norm.maxVelocityAsc),
        formatMax(max.maxVelocityAsc),
      ]);
      rows.push([
        maxFlowRateLabelAsc,
        formatMin(min.maxFlowRateAsc),
        formatNorm(norm.maxFlowRateAsc),
        formatMax(max.maxFlowRateAsc),
      ]);
    }

    return {
      layout: 'lightHorizontalLines', // optional
      table: {
        widths: ['40%', '20%', '20%', '20%'],
        body: rows,
      },
    };
  }

  private renderResultTable(data: Array<Array<number | string | TableRow>>) {
    const displayData = data.map((row) =>
      row.map((num: any, index) =>
        isNaN(num) || num === ''
          ? num
          : new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: index === 0 ? 2 : 1 }).format(
              Number.parseFloat('' + num)
            )
      )
    );
    const table = {
      widths: ['20%', '20%', '20%', '20%', '20%'],
      body: displayData,
    };
    return {
      layout: 'lightHorizontalLines', // optional
      table,
    };
  }

  private renderSolutionsTable(data: UncertaintyInputData) {
    if (data.projectType === 'projectTypeQuoting') {
      return [];
    }

    const { solutions } = data;

    const check = (checked: boolean) => (checked ? { svg: svg.check } : { svg: svg.unchecked });

    const dev = (idx: number | string) => (Number(idx) === 0 ? '-' : DEVICE_LIST[Number(idx)]);

    const rows = [];
    rows.push(['', { text: '', alignment: 'center' }, 'Description', 'Device']);
    // reduce rows not checked (GBC04ADSW-122)
    if (solutions.m1) {
      rows.push([check(solutions.m1), 'M1', 'Firmware update (MCU 1.24.01 / FLSE 1.05.00)', '-']);
    }
    if (solutions.m2a) {
      rows.push([check(solutions.m2a), 'M2a', 'Advanced zero flow adjustment!', '-']);
    }
    if (solutions.m3a) {
      rows.push([
        check(solutions.m3a),
        'M3a',
        'Complete sender/receiver unit replacement',
        dev(solutions.deviceTypeM3a),
      ]);
    }
    if (solutions.m3b) {
      rows.push([
        check(solutions.m3b),
        'M3b',
        'Installation of additional path <=75 degree',
        dev(solutions.deviceTypeM3b),
      ]);
    }
    if (solutions.m3c) {
      rows.push([
        check(solutions.m3c),
        'M3c',
        'Exchange of sender/receiver electronics to XT electronics',
        dev(solutions.deviceTypeM3c),
      ]);
    }
    if (solutions.m4a) {
      rows.push([check(solutions.m4a), 'M4a', 'Wall thickness measurement - for offset adjustment', '-']);
    }
    if (solutions.m4b) {
      rows.push([check(solutions.m4b), 'M4b', 'Inline geometry measurement - for offset adjustment', '-']);
    }

    return [
      this.renderSubSubHeader('Solutions'),
      {
        layout: 'lightHorizontalLines', // optional
        table: {
          widths: ['1%', '4%', '60%', '35%'], // max 75%
          body: rows,
        },
      },
    ];
  }

  private renderVminLimits(data: UncertaintyInputData, units: UnitSystemLabels) {
    const { solutions } = data;
    const { vMinLimit1, vMinLimit2, vMinLimit3, vMinLimit4 } = solutions;

    if (
      data.projectType === 'projectTypeQuoting' ||
      (vMinLimit1 === 0 && vMinLimit2 == 0 && vMinLimit3 == 0 && vMinLimit4 == 0)
    ) {
      return [];
    }

    const speed = (inp: InputNumValue) => `${inp || '?'} ${units.speed}`;

    const rows = [];
    rows.push(['', '']);
    // reduce rows not checked (GBC04ADSW-122)
    if (vMinLimit1) {
      rows.push(['M1', speed(vMinLimit1)]);
    }
    if (vMinLimit2) {
      rows.push(['M1+M2', speed(vMinLimit2)]);
    }
    if (vMinLimit3) {
      rows.push(['M1+M2+M3a/c', speed(vMinLimit3)]);
    }
    if (vMinLimit4) {
      rows.push(['M1+M2+M3b', speed(vMinLimit4)]);
    }

    return [
      {
        text: 'Vmin Limits',
        style: ['subHeader', 'blue'],
      },
      {
        layout: 'lightHorizontalLines', // optional
        table: {
          widths: ['30%', '20%', '50%'], // max 75%
          body: rows,
        },
      },
    ];
  }

  private renderAlarm(cond: boolean, text: string) {
    if (!cond) {
      return [];
    }

    const border = { fillColor: '#ffad33', text: '' };

    return [
      { text: '', margin: [0, 10, 0, 0] },
      {
        layout: 'noBorders',
        table: {
          body: [
            [border, '', ''],
            [border, { svg: svg.warn }, { text, style: ['warn'] }],
            [border, '', ''],
          ],
        },
      },
    ];
  }

  private renderDisclaimer(installedbase: boolean, asc: boolean = false, sickSpool: boolean = false) {
    return [
      this.renderSubHeader('Disclaimer'),
      {
        text: installedbase
          ? 'The application evaluation sheet is electronically valid without signature. \
It is valid for Flare gas applications in compliance with the requirements stated \
in the applicable version of the operating instructions. \n \
\nThe estimated uncertainty of the existing installation – referenced by project name \
– shall be treated confidential.'
          : 'The application evaluation sheet is electronically valid without signature. \
It is valid for Flare gas applications in compliance with the requirements stated \
in the latest version of the operating instructions.',
        style: ['disclaimer'],
      },
      {
        text: asc
          ? 'Uncertainty of ASC Technology is only valid for densities of 1.2 kg/m³ +/-10 % \
and if 50 D upstream of the meter no noise generating elements such as temperature wells, \
flow conditioners, diameter steps >3 % of inner diameter or sharp edges are present.'
          : '',
        style: ['disclaimer'],
      },
      {
        text: sickSpool ? 'Increased uncertainty may apply in case of transducer pair replacement.' : '',
        style: ['disclaimer'],
      },
    ];
  }

  private renderVersionNumbers(data: UncertaintyInputData) {
    return [
      this.renderSubHeader('Software-Version'),
      {
        text: 'Frontend: ' + data.frontendVersion + ', Backend: ' + data.backendVersion,
        style: ['disclaimer'],
      },
    ];
  }

  private pageBreak(condition = true) {
    if (!condition) {
      return [];
    }
    return [{ text: '', fontSize: 14, bold: true, pageBreak: 'before', margin: [0, 0, 0, 0] }];
  }
}
