import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defaultParameterMatrixHandler } from './octave/default-parameter-matrix.handler';
import { masterFunctionHandler } from './octave/master-function.handler';
import { searchVminLimitHandler } from './octave/search-vmin-limits.handler';
import { solutionFinderHandler } from './octave/solution-finder.handler';
import { sosCalculatorHandler } from './octave/sos-calculator.handler';
import { versionHandler } from './octave/version.handler';

@Injectable({
  providedIn: 'root',
})
export class OctaveService {
  constructor(private http: HttpClient) {}

  version = versionHandler(this.http);
  searchVminLimit = searchVminLimitHandler(this.http);
  solutionFinder = solutionFinderHandler(this.http);
  defaultParameterMatrix = defaultParameterMatrixHandler(this.http);
  sosGasComposition = sosCalculatorHandler(this.http);
  masterFunction = masterFunctionHandler(this.http);
}
