import { Pipe, PipeTransform } from '@angular/core';

const ERR = '--';

@Pipe({
  name: 'min',
})
export class MinPipe implements PipeTransform {
  transform(value: any, min = 0): any {
    if (isNaN(Number.parseFloat(value))) {
      return ERR;
    }

    if (Number.parseFloat('' + value) <= min) {
      return ERR;
    }

    return value;
  }
}
