import { HttpClient } from '@angular/common/http';
import { Observable, of, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { convertDataModel2ViewModel, convertViewModel2DataModel } from 'src/app/utils/convert';
import { urlEncodeQuery } from 'src/app/utils/urlEncoder';
import { environment } from '../../../environments/environment';
import { ProcessData, UncertaintyInputData } from '../../model/model';
import { log } from './log';
import { gasComposition } from './query-builder';

interface SosCalculatorResp {
  result: {
    sos: number;
  };
  calledfunction: string;
}

export const handleGergError = (res: number): string | number => {
  const errorCodes = {
    '−99991': 'Given value of temperature out of range',
    '−99992': 'Given value of pressure out of range',
    '−99997': 'Given value of component mole fraction out of range',
    '−99998': 'Sum of mole fractions less or greater than one',
    '−99999': 'Given value of desired phase type out of range',
    '−99990': 'Given value of desired type of unit out of range',
    '−99910': 'Given value of starting pressure out of range',
    '−99920': 'Given value of maximum pressure out of range',
    '−99930': 'Given value of minimum temperature out of range',
    '−99940': 'Given value of initial step size out of range',
    '−99950': 'Given value of molar vapour fraction out of range',
    '−91111': 'Calculation not possible for pure components',
    '−94444': 'Property not defined in the homogeneous region',
    '−95555': 'Property not defined in the two-phase region',
    '−96666': 'Function error (square root of a negative number)',
    '−97777': 'Flash calculation did not converge',
    '−98888': 'Phase envelope calculation failed at initial point',
  };

  if (errorCodes.hasOwnProperty(res.toString())) {
    return errorCodes[res];
  }
  return res;
};

// GET /octave/SosCalculator
//
// Return the speed of sound based on the gas composition
export const sosCalculatorHandler = (http: HttpClient) => (input: UncertaintyInputData) => {
  const data = convertViewModel2DataModel(input);

  const callSosCalculator = (processData: ProcessData): Observable<number | null> => {
    if (!processData.active) {
      return of(null);
    }
    const { temperature, pressure } = processData;
    const queryObj = {
      gasComposition: gasComposition(data),
      ipType: 0,
    };
    const query = urlEncodeQuery(queryObj);
    const url = environment.apiUrl + `/octave/SoSCalculator/${pressure},${temperature}?${query}`;

    return http.get<SosCalculatorResp>(url).pipe(
      tap((res) => log(url, queryObj, res)),
      // tap((res) => handleGergError(res.result.sos)),
      map((res) => res.result.sos)
    );
  };

  const min$ = callSosCalculator(data.min);
  const norm$ = callSosCalculator(data.norm);
  const max$ = callSosCalculator(data.max);

  return zip(min$, norm$, max$).pipe(
    map(([min, norm, max]) => {
      data.min.speed = min;
      data.norm.speed = norm;
      data.max.speed = max;
      const output = convertDataModel2ViewModel(data, input.unitSystem);
      return output;
    })
  );
};
