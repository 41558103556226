/**
 * Rounds a number
 *
 * @param nk - rounding off to `nk` positions
 * @param input - the number to round
 *
 * @example
 *
 * ```
 * const rnd2 = round(2) // rounds off to two positions behind the decimal point.
 * rnd2(100.005) // => 100.01
 * ```
 */
export const round = (nk: number) => (input: number) =>
  Math.round((input + Number.EPSILON) * Math.pow(10, nk)) / Math.pow(10, nk);

/**
 * Rounds two places after the decimal point
 * */
export const rnd2 = round(2);
export const rnd4 = round(4); // for SOS
