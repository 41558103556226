import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DavinciCoreModel } from '../davinci-core.model';
import { DavinciCoreService } from '../davinci-core.service';

@Component({
  selector: 'sick-davinci-modal',
  templateUrl: './davinci-modal.component.html',
  styleUrls: ['./davinci-modal.component.scss']
})
export class DavinciModalComponent implements OnChanges {
  @Input()
  config: DavinciCoreModel.ModalConfig = {};

  @Input()
  valid = true;

  @Output()
  accepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  canceled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public core: DavinciCoreService) {}

  ngOnChanges(changes) {
    // ok-button is present by default
    if (changes.config) {
      this.config = {
        showOkButton: true,
        ...changes.config.currentValue,
      };
    }
  }

  public clickCatcher(event: Event) {
    event.stopPropagation();
  }

  public closeModal(accepted: boolean) {
    if (accepted) {
      this.accepted.emit(true);
    } else {
      this.canceled.emit(true);
    }
    this.close.emit(true);
    this.core.modalActive$.next(false);
  }
}
