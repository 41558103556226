// tslint:disable:max-line-length
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core';
import {
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DavinciTaskProgressComponent } from './controls/task-progress/task-progress.component';
import { DavinciToolbarButtonComponent } from './controls/toolbar-button/davinci-toolbar-button.component';
import { DavinciCorePersistenceService } from './davinci-core-persistence.service';
import { DavinciCoreTasksService } from './davinci-core-tasks.service';
import { DavinciCoreUtilityService } from './davinci-core-utility.service';
import { DavinciCoreComponent } from './davinci-core.component';
import { DavinciCoreService } from './davinci-core.service';
import { DavinciFooterComponent } from './davinci-footer/davinci-footer.component';
import { DavinciHeaderComponent } from './davinci-header/davinci-header.component';
import { DavinciNavigationComponent } from './davinci-header/davinci-navigation/davinci-navigation.component';
import { DavinciTasksListComponent } from './davinci-header/davinci-tasks-list/dacinci-tasks-list.component';
import { DavinciModalComponent } from './davinci-modal/davinci-modal.component';
import { DavinciNavigationPaneComponent } from './davinci-navigation-pane/davinci-navigation-pane.component';
import { AuthCallbackComponent } from './davinci-open-id/auth-callback.component';
import { AuthGuardService } from './davinci-open-id/auth-guard.service';
import { AuthRoleService } from './davinci-open-id/auth-role.service';
import { AuthService } from './davinci-open-id/auth.service';
import { SilentRefreshComponent } from './davinci-open-id/silent-refresh.component';
import { DavinciResizableContainerComponent, } from './davinci-resizeable-container/davinci-resizable-container.component';
import { DavinciToolbarMenuComponent } from './davinci-toolbar/davinci-toolbar-menu/davinci-toolbar-menu.component';
import { DavinciToolbarSubmenuComponent } from './davinci-toolbar/davinci-toolbar-menu/davinci-toolbar-submenu/davinci-toolbar-submenu.component';
import { DavinciToolbarComponent } from './davinci-toolbar/davinci-toolbar.component';
import { DavinciWorkspaceTabsComponent } from './davinci-workspace-tabs/davinci-workspace-tabs.component';
import { DavinciWorkspaceComponent } from './davinci-workspace/davinci-workspace.component';

import '@sick-davinci/basic-elements/lib/button/Button';
import '@sick-davinci/basic-elements/lib/progress-line/ProgressLine';

class DavinciCoreHttpTranslateloader implements TranslateLoader {
  private davinciLoader: TranslateHttpLoader;
  private appLoader: TranslateHttpLoader;

  constructor(http: HttpClient) {
    this.davinciLoader = new TranslateHttpLoader(http, 'assets/davinci-core/i18n/');
    this.appLoader = new TranslateHttpLoader(http, 'assets/i18n/');
  }

  getTranslation(lang: string): any {
    return combineLatest([
      this.davinciLoader.getTranslation(lang),
      this.appLoader.getTranslation(lang)
    ]).pipe(
      map(([davinci, app]) => {
        return ({ ...davinci, ...app });
      })
    );
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new DavinciCoreHttpTranslateloader(http);
}

export const NgxTranslateConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
};

@NgModule({
  declarations: [
    DavinciCoreComponent,
    DavinciFooterComponent,
    DavinciHeaderComponent,
    DavinciModalComponent,
    DavinciNavigationPaneComponent,
    DavinciNavigationComponent,
    DavinciResizableContainerComponent,
    DavinciTasksListComponent,
    DavinciTaskProgressComponent,
    DavinciToolbarComponent,
    DavinciToolbarButtonComponent,
    DavinciToolbarMenuComponent,
    DavinciToolbarSubmenuComponent,
    DavinciWorkspaceComponent,
    DavinciWorkspaceTabsComponent,
    AuthCallbackComponent,
    SilentRefreshComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
  ],
  exports: [
    DavinciCoreComponent,
    DavinciFooterComponent,
    DavinciModalComponent,
    DavinciResizableContainerComponent,
    DavinciToolbarButtonComponent,
    DavinciToolbarComponent,
    DavinciWorkspaceComponent,
    DavinciWorkspaceTabsComponent,
    HttpClientModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class DavinciCoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DavinciCoreModule,
      providers: [
        AuthGuardService,
        AuthRoleService,
        AuthService,
        DavinciCorePersistenceService,
        DavinciCoreTasksService,
        DavinciCoreService,
        DavinciCoreUtilityService,
      ]
    };
  }
}
