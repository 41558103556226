<div class="container" [ngClass]="{ disabled: !passwordOk }">
  <table>
    <tr>
      <th></th>
      <th>EX-S 135</th>
      <th>EX-S 80</th>
      <th>EX</th>
      <th>EX-PR</th>
      <th>F1F-S</th>
      <th>F1F-M</th>
      <th>F1F-H</th>
      <th>F1F-P</th>
      <th>Bias90</th>
    </tr>
    <tr>
      <td>Zeropoint uncertainty only M1 [ns]</td>
      <td><input [formControl]="param(0, 0)" /></td>
      <td><input [formControl]="param(0, 1)" /></td>
      <td><input [formControl]="param(0, 2)" /></td>
      <td><input [formControl]="param(0, 3)" /></td>
      <td><input [formControl]="param(0, 4)" /></td>
      <td><input [formControl]="param(0, 5)" /></td>
      <td><input [formControl]="param(0, 6)" /></td>
      <td><input [formControl]="param(0, 7)" /></td>
      <td><input [formControl]="param(0, 8)" /></td>
    </tr>

    <tr>
      <td>Zeropoint uncertainty only M2 [ns]</td>
      <td><input [formControl]="param(1, 0)" /></td>
      <td><input [formControl]="param(1, 1)" /></td>
      <td><input [formControl]="param(1, 2)" /></td>
      <td><input [formControl]="param(1, 3)" /></td>
      <td><input [formControl]="param(1, 4)" /></td>
      <td><input [formControl]="param(1, 5)" /></td>
      <td><input [formControl]="param(1, 6)" /></td>
      <td><input [formControl]="param(1, 7)" /></td>
      <td><input [formControl]="param(1, 8)" /></td>
    </tr>

    <tr>
      <td>Angle of measurement path [°]</td>
      <td><input [formControl]="param(2, 0)" /></td>
      <td><input [formControl]="param(2, 1)" /></td>
      <td><input [formControl]="param(2, 2)" /></td>
      <td><input [formControl]="param(2, 3)" /></td>
      <td><input [formControl]="param(2, 4)" /></td>
      <td><input [formControl]="param(2, 5)" /></td>
      <td><input [formControl]="param(2, 6)" /></td>
      <td><input [formControl]="param(2, 7)" /></td>
      <td><input [formControl]="param(2, 8)" /></td>
    </tr>

    <tr>
      <td>Uncertainty CFD-coefficients [%]</td>
      <td><input [formControl]="param(3, 0)" /></td>
      <td><input [formControl]="param(3, 1)" /></td>
      <td><input [formControl]="param(3, 2)" /></td>
      <td><input [formControl]="param(3, 3)" /></td>
      <td><input [formControl]="param(3, 4)" /></td>
      <td><input [formControl]="param(3, 5)" /></td>
      <td><input [formControl]="param(3, 6)" /></td>
      <td><input [formControl]="param(3, 7)" /></td>
      <td><input [formControl]="param(3, 8)" /></td>
    </tr>
  </table>

  <table>
    <tr>
      <td>vog_th</td>
      <td><input [formControl]="param(4, 0)" /></td>
      <td>m/s</td>
      <td>Parameters for M3b blending function</td>
    </tr>
    <tr>
      <td>m</td>
      <td><input [formControl]="param(4, 1)" /></td>
      <td></td>
      <td>Parameters for M3b blending function</td>
    </tr>
    <tr>
      <td>AGC upper limit</td>
      <td><input [formControl]="param(5, 0)" /></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>&Kappa;</td>
      <td><input [formControl]="param(6, 0)" /></td>
      <td></td>
      <td>(isentropic exponent when using custom SoS values)</td>
    </tr>
  </table>

  <table>
    <tr>
      <td></td>
      <td colspan="3">
        M4-parameters for hot-tapped <=12"
      </td>
      <td colspan="3">
        <!-- M4-parameters for dry calibrated -->
      </td>
    </tr>

    <tr>
      <td></td>
      <td>u_U [m]</td>
      <td>u_Thickness [m]</td>
      <td>u_alpha [°]</td>
    </tr>

    <tr>
      <td>M4a</td>
      <td><input [formControl]="param(7, 0)" /></td>
      <td><input [formControl]="param(7, 1)" /></td>
      <td></td>
    </tr>

    <tr>
      <td>M4b</td>
      <td><input [formControl]="param(8, 0)" /></td>
      <td><input [formControl]="param(8, 1)" /></td>
      <td><input [formControl]="param(8, 2)" /></td>
    </tr>
  </table>

  <hr />

  <table>
    <tr>
      <td>Password for editing</td>
      <td colspan="2">
        <davinci-text-field [(ngModel)]="password" type="password" style="width: 100%;"></davinci-text-field>
      </td>
      <td colspan="6"></td>
      <td colspan="2">
        <davinci-button label="Password" type="outline" (click)="readOut.emit()">Read out</davinci-button>
      </td>
    </tr>
  </table>

  <hr />
</div>
