import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { User } from 'oidc-client';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthRoleService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean|Observable<boolean>|Promise<boolean> {
    return this.authService.getUser().then(user => {
      return user ? this.checkUserRights(user) : false;
    });
  }

  checkUserRights(user: User): boolean {
    let tokenRoles;
    let allowedRoles;

    if (this.authService && this.authService.oidcClientConfig) {
      tokenRoles = this.authService.oidcClientConfig.role.token_roles;
      allowedRoles = this.authService.oidcClientConfig.role.allowed_roles;
    }
    let userHasRole = false;

    if (user.profile[tokenRoles] != null) {
      for (let i = 0; i < allowedRoles.length; i++) {
        if (user.profile[tokenRoles].indexOf(allowedRoles[i]) >= 0) {
          userHasRole = true;
        } else {
          this.router.navigate(['error']);
        }
      }
    }

    return userHasRole;
  }
}
