import { Injectable } from '@angular/core';

@Injectable()
export class DavinciCoreUtilityService {
  public urlToSegmentPaths(url: string): string[] {
    url = url ? url : '';

    ['?', '#'].forEach(splitChar => {
      url = url.split(splitChar)[0];
    });

    return url
      .split('/')
      .filter(segment => segment !== '')
      .map(segment => '/' + segment);
  }
}
