import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  Router
} from '@angular/router';
import {
  BehaviorSubject,
  Subject
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { DavinciCorePersistenceService } from './davinci-core-persistence.service';
import { DavinciCoreUtilityService } from './davinci-core-utility.service';
import { DavinciCoreModel } from './davinci-core.model';

@Injectable()
export class DavinciCoreService {
  public menuCloser$: Subject<any> = new Subject();
  public paneCloser$: Subject<any> = new Subject();
  public modalActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public uiDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public language$: BehaviorSubject<string> = new BehaviorSubject('en');
  public navigationPane$: BehaviorSubject<DavinciCoreModel.NavigationPane | null>
    = new BehaviorSubject(null);
  public navigation$: BehaviorSubject<DavinciCoreModel.Navigation>
    = new BehaviorSubject(DavinciCoreModel.defaultNavigation);

  constructor(
    private persistence: DavinciCorePersistenceService,
    private router: Router,
    private utility: DavinciCoreUtilityService,
  ) {
    this.subscribeToRouter();
  }

  public init(appKey: string, version: [number, number, number]) {
    this.persistence.init(appKey, version);
    this.initNavigationPanePersistence();
  }

  private updateCurrentPath(url?: string) {
    const pathSegments = this.utility.urlToSegmentPaths(url || this.router.url);

    const path: string[] = [];
    let lastSegment = '';

    pathSegments.forEach(segment => {
      lastSegment += segment;
      path.push(lastSegment);
    });

    this.navigation$.next({
      ...this.navigation$.getValue(),
      currentPath: path,
    });
  }

  private subscribeToRouter() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => this.updateCurrentPath(event.url));
  }

  private initNavigationPanePersistence() {
    const persistedPane = this.persistence.getData('core_navigationPane');
    if (persistedPane) {
      persistedPane.isVisible = persistedPane.isSticky;
      this.navigationPane$.next(persistedPane);
    }

    this.navigationPane$.subscribe(pane => {
      this.persistence.setData('core_navigationPane', pane);
    });
  }
}
