import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UncertaintyInputData } from '../../model/model';
import { clone } from '../../utils/clone';
import { convertParameterMatrixBackend2View } from '../../utils/parameters';
import { log } from './log';

interface DefaultParameterMatrixResponse {
  result: Array<Array<number>>; // nested array of number: 9x9
}

// GET /octave/DefaultParameterMatrix
//
// Returns the default configuration for the uncertainty and solution calculation, usually referred as 'parameter_mat’.
// The default config gets reloaded statelessy, so any modification will be reinitialized upon re - execution.
export const defaultParameterMatrixHandler = (http: HttpClient) => (input: UncertaintyInputData) => {
  const output = clone(input);

  const url = environment.apiUrl + '/octave/DefaultParameterMatrix';
  return http.get<DefaultParameterMatrixResponse>(url).pipe(
    tap((res) => log(url, null, res)),
    map((res) => res.result),
    map((parameters) => {
      output.parameters = convertParameterMatrixBackend2View(parameters, input.unitSystem);
      return output;
    })
  );
};
