import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { UserRecord, UserService } from 'src/app/services/user.service';
import { startWith, map } from 'rxjs/operators';
import { UncertaintyInputData } from 'src/app/model/model';
import { defaultUncertaintyInputData } from 'src/app/model/data';

@Component({
  selector: 'app-user-chip-input',
  templateUrl: './user-chip-input.component.html',
  styleUrls: ['./user-chip-input.component.scss'],
})
export class UserChipInputComponent implements OnInit {
  _project: UncertaintyInputData = defaultUncertaintyInputData();
  state: 'loading' | 'error' | 'ready' = 'loading';
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: Observable<UserRecord[]>;

  users: UserRecord[] = [];
  allUsers: UserRecord[] = [];

  @ViewChild('userInput', { static: true }) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  @Input() set project(nextProject: UncertaintyInputData) {
    this._project = nextProject;
    this.userCtrl.setValue(null);
    this.users = this._project.userList.map((email) => this.allUsers.find((user) => user.usersickid === email));
    this.emit();
  }
  @Output() nextUserList: EventEmitter<string[]> = new EventEmitter();

  constructor(private userService: UserService) {
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      map((value: string | null) =>
        value ? this._filter(value) : this.allUsers.filter((user) => this.users.indexOf(user) === -1)
      )
    );
  }

  async ngOnInit() {
    try {
      const allUsersBackend = await this.userService.fetchAllUserRecords().toPromise();
      this.allUsers = allUsersBackend.sort((userA, userB) => userA.userfull.localeCompare(userB.userfull));
      this.state = 'ready';
      this.emit();
    } catch (e) {
      console.log(e);
      this.state = 'error';
    }
  }

  emit() {
    const nextUserList = this.users.map((user) => user.usersickid);
    this.nextUserList.emit(nextUserList);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const userToAdd = this.allUsers.find((user) => user.usersickid === value);

    if (userToAdd) {
      this.users.push(userToAdd);
      this.emit();
    }

    if (input) {
      input.value = '';
    }

    this.userCtrl.setValue(null);
  }

  remove(user: UserRecord): void {
    const idx = this.users.indexOf(user);
    this.users = [...this.users.splice(0, idx), ...this.users.splice(idx + 1)];
    this.emit();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users.push(event.option.value);
    this.userCtrl.setValue(null);
    this.emit();
  }

  private _filter(value: string): UserRecord[] {
    return this.allUsers.filter((user) => {
      const idx = this.users.indexOf(user);
      const matches = user.usersickid.startsWith(value) || user.userfull.startsWith(value);
      return idx === -1 && matches;
    });
  }
}
