import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.startsWith(environment.apiUrl)) {
      const apiKey = environment.swsApiKey;

      return next.handle(
        request.clone({
          headers: request.headers.set('x-api-key', apiKey),
          withCredentials: false,
        })
      );
    }

    return next.handle(request);
  }
}
