import { Pipe, PipeTransform } from '@angular/core';
import * as Converter from 'js-quantities';
import { UNITS, UnitSystemKey } from 'src/app/model/model';

@Pipe({
  name: 'pressure',
})
export class ConvertPressurePipe implements PipeTransform {
  transform(value: any, to: UnitSystemKey): any {
    const convert = Converter.swiftConverter(UNITS.metric.pressure, UNITS.imperial.pressure);
    if (isNaN(value)) {
      return value;
    }
    if (to === 'metric') {
      return value;
    }
    return convert(Number(value));
  }
}

@Pipe({
  name: 'temperature',
})
export class ConvertTemperaturePipe implements PipeTransform {
  transform(value: any, to: UnitSystemKey): any {
    const convert = Converter.swiftConverter(UNITS.metric.temperature, UNITS.imperial.temperature);
    if (isNaN(value)) {
      return value;
    }
    if (to === 'metric') {
      return value;
    }
    return convert(Number(value));
  }
}

@Pipe({
  name: 'speed',
})
export class ConvertSpeedPipe implements PipeTransform {
  transform(value: any, to: UnitSystemKey): any {
    const convert = Converter.swiftConverter(UNITS.metric.speed, UNITS.imperial.speed);
    if (isNaN(value)) {
      return value;
    }
    if (to === 'metric') {
      return value;
    }
    return convert(Number(value));
  }
}
