import { ProjectsPageComponent } from 'src/app/components/projects-page/projects-page.component';
import { isClassicDevice } from 'src/app/utils/isClassicDevice';
import { ALPHA_CD, ALPHA_PR } from '../../model/consts';
import { UncertaintyData, UncertaintyInputData } from '../../model/model';
import { convertParameterMatrixFromData2Backend } from '../../utils/parameters';

const defaultValue = (defaultVal: number) => (input: number) => (isNaN(input) ? defaultVal : input);
const defaultZero = defaultValue(0);
const withDefaultIfNaN = (input: number, defaultVal: number) => defaultValue(defaultVal)(input);

export function checkgas(input: UncertaintyData): number {
  // source of gas sos value (1 - soscalc, 2 - M/kappa, 3 - custom value)
  switch (input.speedOfSoundMethod) {
    case 'sos-gas-composition':
      return 1;
    case 'sos-mol-and-kappa':
      return 2;
    case 'sos-own':
      return 3;
    default:
      throw Error('Unknown sppedOfSoundMethod:' + input.speedOfSoundMethod);
  }
}

export function alpha(data: UncertaintyData): number | undefined {
  const { deviceType } = data;
  // retrieve from parameter matrix if available (row 2 contains angle per device type)
  const angleFromParameter = parameterMap(data)[2][Number(deviceType) - 1];
  // custom angle from expert mode used?
  if (data.extendedData.useCustomAngle) {
    return data.extendedData.customAngleValue;
  }
  // parameter map does not support selected device - static fallback
  if (angleFromParameter === undefined) {
    const isProbe = [4, 8].includes(Number(deviceType));
    return isProbe ? ALPHA_PR : ALPHA_CD;
  }
  // all is fine - parameter has it
  return angleFromParameter;
}

export function configMatrix(data: UncertaintyInputData) {
  const configMat = [
    [data.extendedData.useUnpairedProbesTime ? 1 : 0, data.extendedData.unpairedProbesTimeDiff], // custom unpaired probes timing
    [data.extendedData.useCustomZeroPointUncertainty ? 1 : 0, data.extendedData.customZeroPointUncertainty], // custom zeropoint NP settings
    [projectTypeConfig(data), 0], // install-based or quoting
    [data.nozzleLength, 0], // Bias90
  ];
  const matrixJoined = [];
  configMat.forEach((x) => matrixJoined.push(x.join('|')));
  const urlValue = matrixJoined.join(',');
  return encodeURIComponent(urlValue);
}

function projectTypeConfig(data: UncertaintyInputData): Number {
  const { projectType, deviceType } = data;
  if (projectType === 'projectTypeInstallBased' && isClassicDevice(Number(deviceType))) {
    return 1;
  } else if (projectType === 'projectTypeQuoting' && isClassicDevice(Number(deviceType))) {
    return 0;
  }
  return 2;
}

export function measureMatrix(data: UncertaintyData) {
  const { solutions, m0deviation } = data;
  const measureMat = [
    [solutions.m2a ? 1 : 0, m0deviation ? 1 : 0, 0],
    [solutions.m3a ? 1 : 0, solutions.deviceTypeM3a, solutions.m3a ? 1 : 0],
    [solutions.m3b ? 1 : 0, solutions.deviceTypeM3b, solutions.angleTypeM3b ? solutions.angleTypeM3b : 0],
    [solutions.m4a ? 1 : 0, solutions.m4b ? 1 : 0, 0],
  ];
  const matrixJoined = [];
  measureMat.forEach((x) => matrixJoined.push(x.join('|')));
  const urlValue = matrixJoined.join(',');
  return encodeURIComponent(urlValue);
}

export function limitsVector(data: UncertaintyData) {
  const percent = withDefaultIfNaN(data.speedUncertaintyPercent, 20);
  const above = withDefaultIfNaN(data.speedUncertaintyAbove, 0.3);
  const max = withDefaultIfNaN(data.speedUncertaintyMax, 5);

  return [percent, above, max];
}

export function kappa(data: UncertaintyData) {
  const { molWeightAndKappa: mol } = data;
  return [defaultZero(mol.minIsentropic), defaultZero(mol.normIsentropic), defaultZero(mol.maxIsentropic)];
}

// Returns an array of [ 'min', 'norm', 'max'] if related process data blocks are marked as `active`
export function processConditions(data: UncertaintyData) {
  return [data.min.active ? 'min' : null, data.norm.active ? 'norm' : null, data.max.active ? 'max' : null].filter(
    (condition) => condition !== null
  );
}

export function parameterMap(data: UncertaintyData) {
  return convertParameterMatrixFromData2Backend(data.parameters);
}

export function gasComposition(data: UncertaintyData) {
  const { gasComposition: gas } = data;
  return (
    `${gas.methan},` +
    `${gas.nitrogen},` +
    `${gas.carbondioxid},` +
    `${gas.ethan},` +
    `${gas.propan},` +
    `${gas.nButhan},` +
    `${gas.iButhan},` +
    `${gas.nPenthan},` +
    `${gas.iPenthan},` +
    `${gas.nHexan},` +
    `${gas.nHepthan},` +
    `${gas.nOcthan},` + // TODO: typo nOctan
    `${gas.nNonane},` +
    `${gas.nDecane},` +
    `${gas.hydrogen},` +
    `${gas.oxygen},` +
    `${gas.carbonMonoxid},` +
    `${gas.water},` +
    `${gas.hydrogenSulfid},` +
    `${gas.helium},` +
    `${gas.argon}`
  );
}

export function pressures(data: UncertaintyData) {
  const { min, norm, max } = data;
  return [defaultZero(min.pressure), defaultZero(norm.pressure), defaultZero(max.pressure)];
}

export function temperatures(data: UncertaintyData) {
  const { min, norm, max } = data;
  return [defaultZero(min.temperature), defaultZero(norm.temperature), defaultZero(max.temperature)];
}

export function customSoS(data: UncertaintyData) {
  const { min, norm, max } = data;
  return [defaultZero(min.speed), defaultZero(norm.speed), defaultZero(max.speed)];
}

export function customMolWeight(data: UncertaintyData) {
  const { molWeightAndKappa: mol } = data;
  return [defaultZero(mol.minMolWeight), defaultZero(mol.normMolWeight), defaultZero(mol.maxMolWeight)];
}

export function vogs(data: UncertaintyData) {
  const { vogVector: v } = data;
  return [v.velo1, v.velo2, v.velo3, v.velo4, v.velo5, v.velo6];
}
