/**
 * Helper functions to convert the parameter matrix.
 *
 * ------------------------------------------------
 * backend --(1)--> data --(2)--> view (metric) --(3)--> view (imperial)
 *
 * The convert pipeline looks like this:
 *
 *s
 * (1) First, we convert the data from the backend to a normalized ("data") presentation using
 *   convertParameterMatrixFromBackend2Data(...)
 *
 * (2) In order to display the parameter matrix, we convert the values to a metric view model ("view")
 *   convertParameterMatrixData2View(...)
 *
 * (3) If the user switched the units system (metric <-> imperial), we convert the view model using
 *  convertParameterMatrixView2View(...)
 *
 *  Note: currently we do nothing and copy those values over
 *
 *  ------------------------------------------------
 *
 * To convert the parameter matrix back to the backend presentation we use this pipe
 *
 * view (imperial | metric ) --(1)--> view (metric) --(2)--> data  --(3)--> backend
 *
 * (1) First we convert the view model to a metric view model (Note: currently this does nothing)
 *     convertParameterMatrixView2View(...)
 *
 * (2) Next, we conver the metric view model to a normalized data model
 *     convertParameterMatrixView2Data(...)
 *
 * (3) And than to a backend representation using
 *     convertParameterMatrixFromData2Backend(...)
 *
 */
import { ParameterMatrix, UnitSystemKey } from '../model/model';
import { PARAMETER_MATRIX_COLS } from '../model/consts';
import { clone } from './clone';
import { rnd2 } from './round';

type UserInput = string | number | null;

const mult = (fact: number) => (input: number) => rnd2(input * Math.pow(10, fact));
const copy = (input: any) => input;

const nanoData2View = mult(9);
const nanoView2Data = mult(-9);
const percentData2View = mult(2);
const percentView2Data = mult(-2);

const makeRow = () => Array(PARAMETER_MATRIX_COLS);

const parametersData2View = [
  // Row 0
  makeRow().fill(nanoData2View),
  // Row 1
  makeRow().fill(nanoData2View),
  // Row 2
  makeRow().fill(copy),
  // Row 3
  makeRow().fill(percentData2View),
  // Row 4
  makeRow().fill(copy),
  // Row 5
  makeRow().fill(copy),
  // Row 6
  makeRow().fill(copy),
  // Row 7
  makeRow().fill(copy),
  // Row 8
  makeRow().fill(copy),
  // Row 9
  makeRow().fill(copy),
];

const parametersView2Data = [
  // Row 0
  makeRow().fill(nanoView2Data),
  // Row 1
  makeRow().fill(nanoView2Data),
  // Row 2
  makeRow().fill(copy),
  // Row 3
  makeRow().fill(percentView2Data),
  // Row 4
  makeRow().fill(copy),
  // Row 5
  makeRow().fill(copy),
  // Row 6
  makeRow().fill(copy),
  // Row 7
  makeRow().fill(copy),
  // Row 8
  makeRow().fill(copy),
  // Row 9
  makeRow().fill(copy),
];

export const convertParameterMatrixData2View: (matrix: ParameterMatrix<number>) => ParameterMatrix<UserInput> = (
  matrix
) =>
  matrix.map((rows, rowIdx) =>
    rows.map((item, colIdx) => {
      const converter = parametersData2View[rowIdx][colIdx];
      return {
        entry: converter(item.entry),
      };
    })
  ) as ParameterMatrix<UserInput>;

export const convertParameterMatrixView2Data: (matrix: ParameterMatrix<UserInput>) => ParameterMatrix<number> = (
  matrix
) =>
  matrix.map((rows, rowIdx) =>
    rows.map((item, colIdx) => {
      const converter = parametersView2Data[rowIdx][colIdx];
      const value = Number(item.entry);
      if (!converter) {
        console.error('no converter for', rowIdx, colIdx);
      }
      return {
        entry: converter(value),
      };
    })
  ) as ParameterMatrix<number>;

export const convertParameterMatrixView2View: (
  from: UnitSystemKey,
  to: UnitSystemKey
) => (matrix: ParameterMatrix<UserInput>) => ParameterMatrix<UserInput> = (_from, _to) => (matrix) => clone(matrix);

export const convertParameterMatrixFromBackend2Data: (matrix: Array<Array<number>>) => ParameterMatrix<number> = (
  matrix
) => matrix.map((row) => row.map((value) => ({ entry: value }))) as ParameterMatrix<number>;

export const convertParameterMatrixFromData2Backend: (matrix: ParameterMatrix<number>) => Array<Array<number>> = (
  matrix
) => matrix.map((row) => row.map((item) => item.entry));

/**
 * Convinient function for the
 *
 *       backend --(1)--> data --(2)--> view (metric) --(3)--> view (imperial)
 *
 * pipeline.
 *
 * Note: currently we don't convert metric <-> imperial in the parameter matrix
 *
 * @param parameters - the array as provided by the octave backend
 * @param the traget unit system, e.g.: 'metric' | 'imperial'
 */
export const convertParameterMatrixBackend2View = (parameters: number[][], to: UnitSystemKey) => {
  const parametersNorm = convertParameterMatrixFromBackend2Data(parameters);
  const paramViewMetric = convertParameterMatrixData2View(parametersNorm);
  const paramView = convertParameterMatrixView2View('metric', to)(paramViewMetric);
  return paramView;
};
