import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DavinciCoreService } from '@sick-davinci/core-ng';
import { Observable } from 'rxjs';
import { DEVICE_LIST } from 'src/app/model/consts';
import { User } from '../../app.component';
import { FlowsicLogEntry } from '../../services/persistence.service';

@Component({
  selector: 'app-list-data-modal',
  templateUrl: './list-data-modal.component.html',
  styleUrls: ['./list-data-modal.component.scss'],
})
export class ListDataModalComponent {
  @Input() user: User;
  @Input() entryList$: Observable<FlowsicLogEntry[]>;

  @Output() entrySelected: EventEmitter<FlowsicLogEntry> = new EventEmitter();

  items = [];

  deviceList = DEVICE_LIST;

  constructor(public coreService: DavinciCoreService) {}

  onEntryClicked(entry) {
    this.coreService.modalActive$.next(false);
    this.entrySelected.emit(entry);
    return false;
  }
}
