import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UncertaintyInputData, UnitSystemLabels } from 'src/app/model/model';
import { SosService } from 'src/app/services/sos.service';
import { clone } from 'src/app/utils/clone';
import { rnd2, rnd4 } from 'src/app/utils/round';

@Component({
  selector: 'app-gas-composition-dialog',
  templateUrl: './gas-composition-dialog.component.html',
  styleUrls: ['./gas-composition-dialog.component.scss'],
})
export class GasCompositionDialogComponent {
  @Input() data: UncertaintyInputData;
  @Input() form: FormGroup;
  @Input() units: UnitSystemLabels;

  _open = false;
  @Input() set open(newValue: boolean) {
    this._open = newValue;
    if (newValue === true) {
      this.previousFormData = clone(this.form.getRawValue());
      this.formatNumberStrings(this.previousFormData);
    }
  }
  get open() {
    return this._open;
  }

  @Output() ok = new EventEmitter();
  @Output() cancel = new EventEmitter();

  private previousFormData: UncertaintyInputData;

  get sum() {
    return SosService.sumGasComposition(this.data);
  }

  hasError(path: string): boolean {
    const formControl = this.form.get(['gasComposition', path]);
    return !formControl.valid && formControl.touched;
  }

  get gasFormInvalid() {
    const formInvalid = this.form.get('gasComposition').invalid;
    const sum = SosService.sumGasComposition(this.data);
    return (sum >= 0.995 && sum <= 1.005) || formInvalid; // allow rounding error margin
  }

  get errorLabel() {
    if (isNaN(this.sum)) {
      return 'Please check your input';
    }

    if (this.sum > 1.001 || this.sum < 0.998) {
      return 'Composition sum is larger than 100%: ' + rnd4(this.sum * 100);
    }

    if (this.sum < 0) {
      return 'Sum is less than zero';
    }

    return null;
  }

  emitCancel() {
    this.cancel.emit(this.previousFormData);
  }

  normalize() {
    const sum = SosService.sumGasComposition(this.data);
    const factor = 1.0 / sum;

    Object.keys(this.data.gasComposition).forEach((element) => {
      this.data.gasComposition[element] = (this.data.gasComposition[element] * factor).toFixed(4);
    });
    this.form.patchValue(this.data);
  }

  formatNumberStrings(inputData: UncertaintyInputData) {
    Object.keys(inputData.gasComposition).forEach((element) => {
      this.data.gasComposition[element] = parseFloat(inputData.gasComposition[element]).toFixed(4);
    });
    this.form.patchValue(this.data);
  }

  emitOk() {
    this.ok.emit(this.form.getRawValue());
  }
}
