<sick-davinci-workspace>
  <div class="outter-container">
    <div class="container" style="width: 800px">
      <!-- Header -->
      <div class="row">
        <h1 class="col-1-to-4">Flare Gas Application</h1>
      </div>

      <!-- Search and New Project -->
      <div class="row">
        <h2 class="col-1-to-2" style="margin: 0">Projects</h2>
        <davinci-text-field class="col-3" label="Search" [(ngModel)]="searchTerm"></davinci-text-field>
        <davinci-button class="col-4" (click)="newProject()"><mat-icon>add</mat-icon> New Project</davinci-button>
      </div>

      <!-- Loading Spinner -->
      <div *ngIf="state === 'loading'" class="row">
        <div class="col-1-to-4 spinner center">
          <mat-spinner></mat-spinner>
        </div>
      </div>

      <!-- Error -->
      <div class="row" *ngIf="state === 'error'">
        <div class="col-1-to-4 center">
          <img src="assets/imgs/undraw_bug.svg" style="margin-top: 8rem; margin-bottom: 3rem; max-width: 500px" />
        </div>
        <div class="col-1-to-4 center" style="font-size: 1.5rem; font-weight: bold">
          Error loading project. Try again later :(
        </div>
      </div>

      <!-- Cards -->
      <div *ngIf="state === 'ready'">
        <div *ngFor="let item of pagedData" class="project-card" (click)="editProject(item)">
          <div class="background"></div>

          <div class="project-name">{{ item.projectName }}</div>

          <div class="created">
            {{ item.created | date: 'medium' }}
          </div>

          <div class="reference">
            <span class="label">Reference</span>
            <span class="value">{{ item.reference }}</span>
          </div>

          <div class="tag">
            <span class="label">Tag</span>
            <span class="value">{{ item.tag }}</span>
          </div>

          <div class="po">
            <span class="label">PO Number</span>
            <span class="value">
              {{ item.poNumber }}
            </span>
          </div>

          <div class="part">
            <span class="label">Part Number</span>
            <span class="value">
              {{ item.partNumber }}
            </span>
          </div>

          <div class="serial">
            <span class="label">Serial Number</span>
            <span class="value">
              {{ item.serialNumber }}
            </span>
          </div>

          <div class="device">
            <span class="label">Device Type</span>
            <span class="value">
              {{ deviceList[item.deviceType] }}
            </span>
          </div>

          <div class="unit">
            <span class="label">Unit</span>
            <span class="value">
              {{ item.displayUnitSystem }}
            </span>
          </div>

          <div class="users">
            <span class="label">Users</span>
            <span class="value">{{ formatUsers(item) }}</span>
          </div>

          <div class="chevron">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </div>

          <div class="actions">
            <button mat-icon-button (click)="shareDialog.openDialog(item); $event.stopPropagation()">
              <mat-icon>share</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="projectEditDialogDelete(item); $event.stopPropagation()"
              *ngIf="item.userid === actualUserId"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- // Cards -->

      <!-- Empty image -->
      <div class="row" *ngIf="state === 'ready' && filteredData.length === 0">
        <div class="col-1-to-4 center">
          <img src="assets/imgs/undraw_empty.svg" style="margin-top: 8rem; margin-bottom: 3rem; max-width: 500px" />
        </div>
        <div class="col-1-to-4 center" style="font-size: 1.5rem; font-weight: bold">Nothing found :(</div>
      </div>

      <!-- Paginator -->
      <div class="row" [style.visibility]="state === 'ready' && filteredData.length > 0 ? 'visible' : 'hidden'">
        <mat-paginator
          class="col-1-to-4"
          #paginator
          [length]="filteredData.length"
          [pageSize]="page.pageSize"
          [pageSizeOptions]="[5, 10, 20, 50, 100]"
          (page)="onPage($event)"
        ></mat-paginator>
      </div>
    </div>
  </div>
</sick-davinci-workspace>

<!-- ************ -->
<!-- Share Dialog -->
<!-- ************ -->
<app-dialog [open]="shareDialog.open">
  <div header>
    <div mat-card-title>Share project?</div>
  </div>
  <div body>
    <app-user-chip-input
      [project]="shareDialog.project"
      (nextUserList)="shareDialog.onNextUserList($event)"
    ></app-user-chip-input>
  </div>

  <div footer>
    <div class="row no-margin-bottom">
      <!-- Cancel -->
      <davinci-button class="col-3" type="outline" (click)="shareDialog.cancel()"> Cancel </davinci-button>
      <!-- Share -->
      <davinci-button class="col-4" (click)="shareDialog.ok()">Share</davinci-button>
    </div>
  </div>
</app-dialog>

<!-- ************************ -->
<!-- Delete UserRecord Dialog -->
<!-- ***********************  -->

<app-dialog [open]="showDeleteUserRecordDialog" (dismiss)="deleteUserRecordDialogCancel()">
  <div header>
    <div mat-card-title>Delete project?</div>
  </div>

  <div body>Do you really want to delete this project?</div>

  <div footer>
    <div class="row no-margin-bottom">
      <davinci-button class="col-3" type="outline" (click)="deleteUserRecordDialogYes()"> Yes please! </davinci-button>
      <!-- Cancel button -->
      <davinci-button class="col-4" (click)="deleteUserRecordDialogCancel()">No!</davinci-button>
    </div>
  </div>
</app-dialog>
