<!--  Loading -->
<div *ngIf="state === 'loading'"></div>

<!-- Ready-->
<mat-form-field *ngIf="state === 'ready'" class="chip-list">
  <mat-chip-list #chipList aria-label="User selection">
    <mat-chip *ngFor="let user of users" [selectable]="selectable" [removable]="removable" (removed)="remove(user)">
      {{ user.usersickid }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      autofocus
      placeholder="Users"
      #userInput
      [formControl]="userCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" panelWidth="300px">
    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
      <div class="option">
        <div class="id">{{ user.usersickid }}</div>
        <div class="name">{{ user.userfull }}</div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<!-- Error -->
<div *ngIf="state === 'error'">
  Error loading users
</div>
