import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'safeNumber',
})
export class SafeNumberPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  transform(value: any, ...args: any[]) {
    if (isNaN(value)) {
      return value;
    }

    const numberPipe = new DecimalPipe(this.localeId);
    return numberPipe.transform(value, ...args);
  }
}
