<sick-davinci-workspace class="home">
  <div class="container">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <h1 class="sick-blue">Flare Gas Application Evaluation Datasheet</h1>
      <div class="row">
        <h2 class="col-1-to-3" style="margin-top: 0">FLOWSIC100 Flare/ FLOWSIC100 Flare-XT</h2>
        <davinci-button class="col-4" type="button" (click)="goToProjects()">Projects</davinci-button>

        <davinci-button class="col-4" id="load-fixture" *ngIf="activeProfile !== 'production'" (click)="loadFixture()">
          Load test data</davinci-button
        >
      </div>

      <!-- ************************ -->
      <!-- General Information -->
      <!--- *********************** -->

      <h3>General Information</h3>

      <div class="row" *ngIf="errorMessage.length > 0">
        <div class="col-1-to-4 error">
          {{ errorMessage }}
        </div>
      </div>
      <div class="row">
        <davinci-text-field
          formControlName="projectName"
          [error]="hasError('projectName')"
          label="Project name*"
          placeholder="Project name"
        ></davinci-text-field>

        <davinci-text-field formControlName="reference" label="Reference (CRM/ZTA)"></davinci-text-field>

        <davinci-text-field
          formControlName="tag"
          required
          placeholder="TAG no."
          label="TAG No."
          [error]="hasError('tag')"
        >
        </davinci-text-field>

        <davinci-text-field formControlName="poNumber" label="PO Number"></davinci-text-field>
        <davinci-text-field formControlName="partNumber" label="Part Number"></davinci-text-field>
        <davinci-text-field formControlName="serialNumber" label="Serial Number"></davinci-text-field>
        <davinci-drop-down
          formControlName="deviceType"
          label="Device type*"
          required
          [error]="
            form.controls.deviceType.invalid && (form.controls.deviceType.touched || form.controls.projectType.touched)
          "
        >
          <davinci-option value="1">{{ deviceList[1] }}</davinci-option>
          <davinci-option value="2">{{ deviceList[2] }}</davinci-option>
          <davinci-option value="3" *ngIf="form.controls.projectType.value === 'projectTypeInstallBased'">{{
            deviceList[3]
          }}</davinci-option>
          <davinci-option value="4">{{ deviceList[4] }}</davinci-option>
          <davinci-option value="5">{{ deviceList[5] }}</davinci-option>
          <davinci-option value="6" *ngIf="!this.userService.isSsu">{{ deviceList[6] }}</davinci-option>
          <davinci-option value="7">{{ deviceList[7] }}</davinci-option>
          <davinci-option value="8">{{ deviceList[8] }}</davinci-option>
          <davinci-option value="9">{{ deviceList[9] }}</davinci-option>
        </davinci-drop-down>

        <davinci-drop-down
          *ngIf="data.deviceType == '9'"
          formControlName="nozzleLength"
          label="Nozzle distance*"
          required
        >
          <davinci-option value="1">9"</davinci-option>
          <davinci-option value="2">10"</davinci-option>
          <davinci-option value="3">6.4"</davinci-option>
        </davinci-drop-down>
      </div>
      <div class="row">
        <davinci-drop-down
          formControlName="pathConfiguration"
          label="Path configuration*"
          required
          [error]="form.controls.diameter.errors"
        >
          <davinci-option value="1">1-path</davinci-option>
          <davinci-option value="2" *ngIf="allowTwoPathSetup">2-path</davinci-option>
        </davinci-drop-down>

        <davinci-drop-down
          formControlName="diameter"
          name="Nominal pipe size*"
          label="Nominal pipe size*"
          [unit]="units.diameter"
          [error]="hasError('diameter')"
          required
        >
          <davinci-option *ngFor="let nominalSize of nominalSizes" [value]="nominalSize">
            {{ nominalSize }} ''
          </davinci-option>
        </davinci-drop-down>

        <davinci-drop-down
          formControlName="installType"
          name="Type of installation"
          label="Type of installation*"
          required
        >
          <davinci-option value="1">Instrument</davinci-option>
          <davinci-option value="2">Meter, SICK spool</davinci-option>
          <davinci-option value="3">Meter, SICK spool, flow-calib.</davinci-option>
        </davinci-drop-down>

        <davinci-drop-down formControlName="exZone" label="ExZone*" required>
          <davinci-option value="1">Zone IIc</davinci-option>
          <davinci-option value="2">Zone IIa</davinci-option>
          <davinci-option value="3">Zone 2</davinci-option>
        </davinci-drop-down>
      </div>

      <!-- ************************ -->
      <!-- Options  -->
      <!--- *********************** -->

      <div class="spacer"></div>

      <h3>Options</h3>
      <div class="row">
        <davinci-drop-down
          class="col-1-and-2"
          id="unit-selector"
          formControlName="unitSystem"
          name="Units"
          label="Units"
        >
          <davinci-option id="option-metric-units" value="metric">Metric/SI (m, bar, °C)</davinci-option>
          <davinci-option id="option-imperial-units" value="imperial">Imperial (inch, psi, °F)</davinci-option>
        </davinci-drop-down>

        <!-- ************************ -->
        <!-- Install based or Quoting -->
        <!--- *********************** -->
        <davinci-drop-down
          class="col-3-and-4"
          id="type-selector"
          formControlName="projectType"
          name="Type"
          label="Project Type*"
          required
        >
          <davinci-option id="option-type-quoting" value="projectTypeQuoting">Quoting</davinci-option>
          <davinci-option id="option-type-install-based" value="projectTypeInstallBased" *ngIf="allowInstallBased"
            >Installed base</davinci-option
          >
        </davinci-drop-down>
      </div>

      <!-- <select formControlName="projectType" class="col-3-and-4">
        <option id="option-type-quoting" value="projectTypeQuoting">Quoting</option>
        <option id="option-type-install-based" value="projectTypeInstallBased" *ngIf="allowInstallBased">Installed base</option>
      </select> -->

      <div class="row" *ngIf="data.alarmMarker.spec">
        <davinci-callout class="col-1-to-4" type="warning"> Device out of Spec </davinci-callout>
      </div>

      <!-- ************************ -->
      <!-- Process data -->
      <!-- ************************ -->

      <div class="spacer"></div>

      <div class="row">
        <h3 class="col-1">Process data*</h3>
      </div>

      <div class="row">
        <div class="col-2 checkbox-wrap left" formGroupName="min">
          <davinci-checkbox formControlName="active" name="min"></davinci-checkbox>
          <label>min</label>
        </div>
        <div class="col-3 checkbox-wrap left" formGroupName="norm">
          <davinci-checkbox formControlName="active" name="norm"></davinci-checkbox>
          <label>norm</label>
        </div>
        <div class="col-4 checkbox-wrap left" formGroupName="max">
          <davinci-checkbox formControlName="active" name="max"></davinci-checkbox>
          <label>max</label>
        </div>

        <label class="col-1">
          <div>
            Absolute pressure in {{ units.pressure }}*
            <div class="range">
              [{{ ranges.pressure.from | pressure: unitSystem | number: '1.2-2' }} -
              {{ ranges.pressure.to | pressure: unitSystem | number: '1.2-2' }}]
            </div>
          </div>
        </label>
        <section class="col-2" *ngIf="data.min?.active" formGroupName="min">
          <app-unit-field
            controlName="pressure"
            placeholder="min pressure"
            [unit]="units.pressure"
            [error]="hasError('min.pressure')"
            (blur)="formatNumber('min.pressure')"
          ></app-unit-field>
        </section>

        <section class="col-3" *ngIf="data.norm?.active" formGroupName="norm">
          <app-unit-field
            controlName="pressure"
            placeholder="norm pressure"
            [unit]="units.pressure"
            [error]="hasError('norm.pressure')"
            (blur)="formatNumber('norm.pressure')"
          ></app-unit-field>
        </section>

        <section class="col-4" *ngIf="data.max?.active" formGroupName="max">
          <app-unit-field
            controlName="pressure"
            placeholder="max pressure"
            [unit]="units.pressure"
            [error]="hasError('max.pressure')"
            (blur)="formatNumber('max.pressure')"
          ></app-unit-field>
        </section>

        <label class="col-1">
          <div>
            Temperature in {{ units.temperature }}*
            <div class="range">
              [{{ ranges.temperature.from | temperature: unitSystem | number: '1.2-2' }} -
              {{ ranges.temperature.to | temperature: unitSystem | number: '1.2-2' }}]
            </div>
          </div>
        </label>
        <section class="col-2" *ngIf="data.min?.active" formGroupName="min">
          <app-unit-field
            controlName="temperature"
            placeholder="min temperature"
            [unit]="units.temperature"
            [error]="hasError('min.temperature')"
            (blur)="formatNumber('min.temperature')"
          ></app-unit-field>
        </section>

        <section class="col-3" *ngIf="data.norm?.active" formGroupName="norm">
          <app-unit-field
            controlName="temperature"
            placeholder="norm temperature"
            [unit]="units.temperature"
            [error]="hasError('norm.temperature')"
            (blur)="formatNumber('norm.temperature')"
          ></app-unit-field>
        </section>

        <section class="col-4" *ngIf="data.max?.active" formGroupName="max">
          <app-unit-field
            controlName="temperature"
            placeholder="max temperature"
            [unit]="units.temperature"
            [error]="hasError('max.temperature')"
            (blur)="formatNumber('max.temperature')"
          ></app-unit-field>
        </section>
      </div>

      <!-- ************************ -->
      <!-- SoS Method Selector-->
      <!--- *********************** -->

      <div class="row">
        <label class="col-1">SoS Method</label>
        <davinci-drop-down
          class="col-2-and-3"
          id="speed-of-sound-method-selector"
          formControlName="speedOfSoundMethod"
          name="Speed of Sound from"
          label="Speed of Sound from"
        >
          <davinci-option id="option-sos-gas-composition" value="sos-gas-composition">Gas composition</davinci-option>
          <davinci-option id="option-sos-mol-and-kappa" value="sos-mol-and-kappa">Mol weight and kappa</davinci-option>
          <davinci-option id="option-sos-own" value="sos-own">Own</davinci-option>
        </davinci-drop-down>

        <div class="col-4">
          <davinci-button
            *ngIf="data.speedOfSoundMethod === 'sos-gas-composition'"
            type="outline"
            [ngClass]="{ disabled: disableDefineGasCompositionButton }"
            (click)="openGasCompositionModal()"
          >
            Define gas composition
          </davinci-button>

          <davinci-button
            *ngIf="data.speedOfSoundMethod === 'sos-mol-and-kappa'"
            type="outline"
            (click)="computeSoSUsingMolMass()"
          >
            Compute SoS
          </davinci-button>
        </div>
      </div>

      <!-- *************************  -->
      <!-- Own speed of sound method  -->
      <!-- ************************** -->
      <div class="row" *ngIf="data.speedOfSoundMethod === 'sos-own'">
        <div class="col-1">
          <label
            >Speed of sounds in {{ units.speed }}*
            <div class="range">
              [{{ ranges.sos.from | speed: unitSystem | number: '1.2-2' }} -
              {{ ranges.sos.to | speed: unitSystem | number: '1.2-2' }}]
            </div>
          </label>
        </div>

        <div class="col-2" formGroupName="min">
          <section *ngIf="data.min?.active">
            <app-unit-field
              controlName="speed"
              placeholder="min speed"
              [unit]="units.speed"
              [error]="hasError('min.speed')"
              (blur)="formatNumber('min.speed')"
            ></app-unit-field>
          </section>
        </div>
        <div class="col-3" formGroupName="norm">
          <section *ngIf="data.norm?.active">
            <app-unit-field
              controlName="speed"
              placeholder="norm speed"
              [unit]="units.speed"
              [error]="hasError('norm.speed')"
              (blur)="formatNumber('norm.speed')"
            ></app-unit-field>
          </section>
        </div>
        <div class="col-4" formGroupName="max">
          <section *ngIf="data.max?.active">
            <app-unit-field
              controlName="speed"
              placeholder="max speed"
              [unit]="units.speed"
              [error]="hasError('max.speed')"
              (blur)="formatNumber('max.speed')"
            ></app-unit-field>
          </section>
        </div>
      </div>

      <!-- *************************  -->
      <!-- Mol                        -->
      <!-- ************************   -->

      <div class="row" *ngIf="data.speedOfSoundMethod === 'sos-mol-and-kappa'" formGroupName="molWeightAndKappa">
        <label class="col-1">Mol weight in {{ units.mol }}* </label>

        <section class="col-2" *ngIf="data.min?.active">
          <app-unit-field
            controlName="minMolWeight"
            placeholder="min mol weight"
            [unit]="units.mol"
            (blur)="formatNumber('molWeightAndKappa.minMolWeight')"
          ></app-unit-field>
        </section>

        <section class="col-3" *ngIf="data.norm?.active">
          <app-unit-field
            controlName="normMolWeight"
            placeholder="norm mol weight"
            [unit]="units.mol"
            (blur)="formatNumber('molWeightAndKappa.normMolWeight')"
          ></app-unit-field>
        </section>

        <section class="col-4" *ngIf="data.max?.active">
          <app-unit-field
            controlName="maxMolWeight"
            placeholder="max mol weight"
            [unit]="units.mol"
            (blur)="formatNumber('molWeightAndKappa.maxMolWeight')"
          ></app-unit-field>
        </section>
      </div>

      <div class="row" *ngIf="data.speedOfSoundMethod === 'sos-mol-and-kappa'" formGroupName="molWeightAndKappa">
        <label class="col-1">Isentropic exp kappa* </label>

        <section class="col-2" *ngIf="data.min?.active">
          <app-unit-field
            controlName="minIsentropic"
            placeholder="min isentropic exp. kappa"
            (blur)="formatNumber('molWeightAndKappa.minIsentropic')"
          ></app-unit-field>
        </section>

        <section class="col-3" *ngIf="data.norm?.active">
          <app-unit-field
            controlName="normIsentropic"
            placeholder="norm isentropic exp. kappa"
            (blur)="formatNumber('molWeightAndKappa.normIsentropic')"
          ></app-unit-field>
        </section>

        <section class="col-4" *ngIf="data.max?.active">
          <app-unit-field
            controlName="maxIsentropic"
            placeholder="max isentropic exp. kappa"
            (blur)="formatNumber('molWeightAndKappa.maxIsentropic')"
          ></app-unit-field>
        </section>
      </div>

      <!-- *************************  -->
      <!-- Computed sound method  -->
      <!-- ************************** -->

      <div class="row" *ngIf="data.speedOfSoundMethod !== 'sos-own'">
        <div class="col-1">
          <label
            >Speed of sounds in {{ units.speed }}*
            <div class="range">
              [{{ ranges.sos.from | speed: unitSystem | number: '1.2-2' }} -
              {{ ranges.sos.to | speed: unitSystem | number: '1.2-2' }}]
            </div>
          </label>
        </div>

        <div
          class="col-2"
          formGroupName="min"
          *ngIf="data.min?.active"
          [ngClass]="{ invalid: form.controls.min.controls.speed.invalid }"
        >
          <section class="result">
            <span>{{ data.min.speed | number: '1.2-2' | min }}</span>
            <span class="range">{{ units.speed }}</span>
          </section>
        </div>
        <div
          class="col-3"
          formGroupName="norm"
          *ngIf="data.norm?.active"
          [ngClass]="{ invalid: form.controls.norm.controls.speed.invalid }"
        >
          <section class="result">
            <span>{{ data.norm.speed | number: '1.2-2' | min }}</span>
            <span class="range">{{ units.speed }}</span>
          </section>
        </div>
        <div
          class="col-4"
          formGroupName="max"
          *ngIf="data.max?.active"
          [ngClass]="{ invalid: form.controls.max.controls.speed.invalid }"
        >
          <section class="result">
            <span>{{ data.max.speed | number: '1.2-2' | min }}</span>
            <span class="range">{{ units.speed }}</span>
          </section>
        </div>
      </div>

      <div class="row" *ngIf="showSosOutOfRangeErrorMessage">
        <div class="col-2-to-4 error">
          Process conditions out of range for SOS calculation. Please check the input values or contact GBC04
          application & support.
        </div>
      </div>

      <!-- *************************  -->
      <!-- Options -->
      <!-- ************************** -->
      <div class="spacer"></div>
      <h3>Options</h3>
      <div class="row">
        <div class="col-1">
          <label> Speed uncertainty by customer </label>
        </div>
        <davinci-text-field
          class="col-2"
          formControlName="speedUncertaintyPercent"
          name="speed uncertainty"
          label="%*"
          [error]="hasError('speedUncertaintyPercent')"
          (blur)="formatNumber('speedUncertaintyPercent')"
        >
        </davinci-text-field>
        <davinci-text-field
          class="col-3"
          formControlName="speedUncertaintyMax"
          name="max uncertainty speed"
          label="up to {{ units.speed }}*"
          [error]="hasError('speedUncertaintyMax')"
          (blur)="formatNumber('speedUncertaintyMax')"
        ></davinci-text-field>

        <davinci-text-field
          class="col-4"
          formControlName="speedUncertaintyAbove"
          name="uncertainty above max speed"
          label="% above*"
          [error]="hasError('speedUncertaintyAbove')"
          (blur)="formatNumber('speedUncertaintyAbove')"
        >
        </davinci-text-field>
      </div>

      <div class="row">
        <div class="col-1" *ngIf="!showM0Deviation">
          <div class="checkbox-wrap">
            <label>Active sound correlation</label>
            <davinci-checkbox formControlName="activeSoundCorrelation" name="activeSoundCorrelation"></davinci-checkbox>
          </div>
        </div>

        <div class="col-2 checkbox-wrap centered" *ngIf="!showM0Deviation">
          <label>Envelope</label>
          <davinci-checkbox formControlName="envelope" name="envelope"></davinci-checkbox>
        </div>

        <div class="col-3 checkbox-wrap centered" *ngIf="showM0DeviationForm && !isGeReplacementMode">
          <label
            >Show deviation<br />
            w/o correction</label
          >
          <davinci-checkbox formControlName="m0deviation" name="m0deviation"></davinci-checkbox>
        </div>

        <div class="col-4">
          <davinci-button type="outline" (click)="openVogVectorModal()">Define Velocity</davinci-button>
        </div>
      </div>

      <!-- *************************  -->
      <!-- Expert options           -->
      <!-- ************************** -->

      <div class="spacer" *ngIf="showExpertForm"></div>

      <!-- Enhanced options für expert level -->
      <div class="row" formGroupName="extendedData" *ngIf="showExpertForm">
        <div class="checkbox-wrap col-1">
          <label>Custom path angle<br /></label>
          <davinci-checkbox formControlName="useCustomAngle" name="useCustomAngle"></davinci-checkbox>
        </div>
        <app-unit-field
          class="col-2"
          *ngIf="data.extendedData.useCustomAngle"
          controlName="customAngleValue"
          placeholder="custom angle"
          label="Path angle"
          [unit]="units.angle"
        >
        </app-unit-field>
        <app-unit-field
          class="col-3"
          controlName="additionalPathAngle"
          label="Additional path angle"
          [unit]="units.angle"
        >
        </app-unit-field>
      </div>

      <div class="row" formGroupName="extendedData" *ngIf="showExpertForm">
        <div class="checkbox-wrap col-1">
          <label>Unpaired probes timing<br /></label>
          <davinci-checkbox formControlName="useUnpairedProbesTime" name="useUnpairedProbesTime"></davinci-checkbox>
        </div>
        <app-unit-field
          class="col-2"
          *ngIf="data.extendedData.useUnpairedProbesTime"
          controlName="unpairedProbesTimeDiff"
          placeholder="Unpaired probes timing"
          label="Unpaired probes timing [ns]"
          unit="ns"
        >
        </app-unit-field>

        <div class="row" *ngIf="showExpertForm"></div>
        <div class="checkbox-wrap col-1">
          <label>Custom zero point uncertainty<br /></label>
          <davinci-checkbox formControlName="useCustomZeroPointUncertainty" name="useCustomZeroPointUncertainty">
          </davinci-checkbox>
        </div>
        <app-unit-field
          class="col-2"
          *ngIf="data.extendedData.useCustomZeroPointUncertainty"
          controlName="customZeroPointUncertainty"
          placeholder="Zero point uncertainty"
          label="Zero point uncertainty in %"
          unit="%"
        >
        </app-unit-field>

        <davinci-button class="col-4" type="outline" (click)="openParametersModal()">Device Parameter </davinci-button>
      </div>

      <div class="spacer"></div>
      <h3>Explanation</h3>
      <div class="row">
        <label class="col-1"> Project information </label>
        <davinci-text-area class="col-2-to-4" formControlName="explanation" multiline style="font-size: 1rem">
        </davinci-text-area>
      </div>

      <div class="spacer"></div>
      <div class="row" style="margin-top: 1rem">
        <davinci-button
          class="col-2-to-3"
          [ngClass]="{ disabled: disableCalculateButton }"
          type="submit"
          (click)="submitForm()"
          >Calculate</davinci-button
        >
        <davinci-button class="col-4" *ngIf="persistenceAvailable" type="outline" (click)="saveResult()"
          >Save
        </davinci-button>
      </div>

      <div *ngIf="errorMessage.length > 0" class="row">
        <div class="col-1-to-4 error">
          {{ errorMessage }}
        </div>
      </div>

      <div *ngIf="data.alarmMarker.genericMessages?.length > 0 && showExpertForm" class="row">
        <davinci-callout
          type="error"
          class="col-1-to-4"
          *ngFor="let genericMessage of data.alarmMarker.genericMessages"
        >
          {{ genericMessage }}
        </davinci-callout>
      </div>
      <div *ngIf="data.alarmMarker.genericMessages?.length > 0 && !showExpertForm" class="row">
        <davinci-callout class="col-1-to-4" type="error">
          <p><b>The evaluation can not be completed with valid calculation results.</b></p>
          <p>Please check the input data or contact GBC04 pre-sales for further evaluation.</p>
        </davinci-callout>
      </div>

      <div *ngIf="showSolutions" formGroupName="solutions">
        <div class="spacer"></div>
        <h3>Solutions</h3>
        <div class="row">
          <davinci-callout class="col-1-to-4" type="warning" *ngIf="data.alarmMarker.solution">
            No solution found. Please contact GBC04.
          </davinci-callout>
          <davinci-callout class="col-1-to-4" type="warning" *ngIf="data.alarmMarker.customSolution">
            Review by Custom Solutions
          </davinci-callout>
        </div>

        <div class="row">
          <!-- M1-->
          <davinci-checkbox class="col-1" formControlName="m1">M1</davinci-checkbox>
          <div class="col-2-and-3">Firmware update (MCU 1.24.01 / FLSE 1.05.00)</div>
          <davinci-button class="col-4" (click)="solutionFinder()"> Search </davinci-button>

          <!-- M2A-->
          <davinci-checkbox class="col-1" formControlName="m2a">M2a</davinci-checkbox>
          <div class="col-2-and-3">Advanced zero flow adjustment!</div>
          <davinci-button
            class="col-4"
            type="outline"
            [disabled]="!showExpertForm"
            (click)="solutionFinderCustomSearch()"
            >Custom Search</davinci-button
          >

          <!-- M3A-->
          <davinci-checkbox class="col-1" formControlName="m3a">M3a</davinci-checkbox>
          <davinci-drop-down class="col-2-and-3" formControlName="deviceTypeM3a" label="Device type">
            <davinci-option value="0">&nbsp;</davinci-option>
            <davinci-option value="1">{{ deviceList[1] }}</davinci-option>
            <davinci-option value="5">{{ deviceList[5] }}</davinci-option>
            <davinci-option value="6">{{ deviceList[6] }}</davinci-option>
            <davinci-option value="7">{{ deviceList[7] }}</davinci-option>
          </davinci-drop-down>
          <davinci-button class="col-4" type="outline" (click)="solutionFinderReject()">Clear</davinci-button>

          <!-- M3B-->
          <davinci-checkbox class="col-1" formControlName="m3b">M3b</davinci-checkbox>
          <davinci-drop-down class="col-2-and-3" formControlName="deviceTypeM3b" label="Device type">
            <davinci-option value="0">&nbsp;</davinci-option>
            <davinci-option value="1">{{ deviceList[1] }}</davinci-option>
            <davinci-option value="5">{{ deviceList[5] }}</davinci-option>
            <davinci-option value="6">{{ deviceList[6] }}</davinci-option>
            <davinci-option value="7">{{ deviceList[7] }}</davinci-option>
          </davinci-drop-down>

          <!-- M3C-->
          <davinci-checkbox class="col-1" formControlName="m3c">M3c</davinci-checkbox>
          <davinci-drop-down class="col-2-and-3" formControlName="deviceTypeM3c" label="Device type">
            <davinci-option value="0">&nbsp;</davinci-option>
            <davinci-option value="7">{{ deviceList[7] }}</davinci-option>
          </davinci-drop-down>

          <!-- M4A-->
          <davinci-checkbox class="col-1" formControlName="m4a">M4a</davinci-checkbox>

          <!-- M4B-->
          <davinci-checkbox class="col-1" formControlName="m4b">M4b</davinci-checkbox>
        </div>
      </div>

      <div *ngIf="showSolutions" class="row">
        <div class="col-1-to-3 vertical">
          <table class="result-table">
            <thead>
              <tr>
                <th>Solution</th>
                <th>Speed in {{ units.speed }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="left">M1</td>
                <td>{{ data.solutions.vMinLimit1 }}</td>
              </tr>
              <tr>
                <td class="left">M1+M2</td>
                <td>{{ data.solutions.vMinLimit2 }}</td>
              </tr>
              <tr>
                <td class="left">M1+M2+M3a/c</td>
                <td>{{ data.solutions.vMinLimit3 }}</td>
              </tr>
              <tr>
                <td class="left">M1+M2+M3b</td>
                <td>{{ data.solutions.vMinLimit4 }}</td>
              </tr>
            </tbody>
          </table>

          <ul>
            <li>M1: Firmware update (MCU 1.24.01 / FLSE 1.05.00)</li>
            <li>M2a: Advanced zeroflow adjustment</li>
            <li>M3a: Complete sender/receiver unit replacement</li>
            <li>M3b: Installation of additional path <=75 degree</li>
            <li>M3c: Exchange of sender/receiver electronics to XT electronics</li>
            <li>M4a: Wall thickness measurement - for offset adjustment</li>
            <li>M4b: Inline geometry measurement - for offset adjustment</li>
          </ul>
        </div>

        <div class="col-4 vertical">
          <davinci-button type="outline" (click)="searchVminLimit()">Calculate vmin Limits</davinci-button>
        </div>
      </div>

      <!-- *************************  -->
      <!-- Results                    -->
      <!-- ************************** -->

      <div class="spacer"></div>
      <div class="calculated-params" *ngIf="showResults">
        <h2>Results</h2>
        <div class="row">
          <div class="column first" style="width: 400px">
            <label></label>
            <label>Max velocity in {{ units.speed }}</label>
            <label>Max flow rate in {{ units.flowRate }}</label>
            <label *ngIf="data.activeSoundCorrelation">Max velocity ASC in {{ units.speed }}</label>
            <label *ngIf="data.activeSoundCorrelation">Max flow rate ASC in {{ units.flowRate }}</label>
            <label *ngIf="showExpertForm">AGC in dB</label>
          </div>
          <div class="column" *ngIf="data.min.active">
            <label class="header">min</label>
            <div class="result">{{ data.results.calculatedParams?.min?.maxVelocity | number: '1.2-2' }}</div>
            <div class="result">
              {{ data.results.calculatedParams?.min?.maxFlowRate | number: '1.2-2' }}
            </div>
            <div *ngIf="data.activeSoundCorrelation" class="result">
              {{ data.results.calculatedParams?.min?.maxVelocityAsc | number: '1.2-2' }}
            </div>
            <div *ngIf="data.activeSoundCorrelation" class="result">
              {{ data.results.calculatedParams?.min?.maxFlowRateAsc | number: '1.2-2' }}
            </div>
            <div *ngIf="showExpertForm" class="result">
              {{ data.min.agc | number: '1.2-2' }}
            </div>
          </div>
          <div class="column" *ngIf="data.norm.active">
            <label class="header">norm</label>
            <div class="result">{{ data.results.calculatedParams?.norm?.maxVelocity | number: '1.2-2' }}</div>
            <div class="result">
              {{ data.results.calculatedParams?.norm?.maxFlowRate | number: '1.2-2' }}
            </div>
            <div *ngIf="data.activeSoundCorrelation" class="result">
              {{ data.results.calculatedParams?.norm?.maxVelocityAsc | number: '1.2-2' }}
            </div>
            <div *ngIf="data.activeSoundCorrelation" class="result">
              {{ data.results.calculatedParams?.norm?.maxFlowRateAsc | number: '1.2-2' }}
            </div>
            <div *ngIf="showExpertForm" class="result">
              {{ data.norm.agc | number: '1.2-2' }}
            </div>
          </div>
          <div class="column" *ngIf="data.max.active">
            <label class="header">max</label>
            <div class="result">{{ data.results.calculatedParams?.max?.maxVelocity | number: '1.2-2' }}</div>
            <div class="result">
              {{ data.results.calculatedParams?.max?.maxFlowRate | number: '1.2-2' }}
            </div>
            <div *ngIf="data.activeSoundCorrelation" class="result">
              {{ data.results.calculatedParams?.max?.maxVelocityAsc | number: '1.2-2' }}
            </div>
            <div *ngIf="data.activeSoundCorrelation" class="result">
              {{ data.results.calculatedParams?.max?.maxFlowRateAsc | number: '1.2-2' }}
            </div>
            <div *ngIf="showExpertForm" class="result">
              {{ data.max.agc | number: '1.2-2' }}
            </div>
          </div>
        </div>
      </div>

      <!-- ---------------------- -->
      <!-- Gas Composition Dialog -->
      <!-- ---------------------- -->

      <app-gas-composition-dialog
        [units]="units"
        [form]="form"
        [data]="data"
        [open]="gasCompositionDialog.open"
        (cancel)="gasCompositionDialog.onCancel($event)"
        (ok)="gasCompositionDialog.onOk()"
      >
      </app-gas-composition-dialog>

      <!-- ----------------- -->
      <!-- Vog Vector Dialog -->
      <!-------------------- -->
      <app-modal-dialog dialog="vog-vector">
        <app-vog-vector-dialog [units]="units" [form]="form"></app-vog-vector-dialog>
      </app-modal-dialog>

      <!-- ----------------- -->
      <!-- Parameters Dialog -->
      <!-- ----------------- -->
      <app-modal-dialog dialog="parameters">
        <app-parameters-overview-dialog [units]="units" [form]="form" (readOut)="defaultParameterMatrix()">
        </app-parameters-overview-dialog>
      </app-modal-dialog>

      <!-- ----------------- -->
      <!-- Result Deviation  -->
      <!-- ----------------- -->

      <div *ngIf="showResults" class="results">
        <table class="result-table">
          <thead>
            <tr>
              <th>VoG [{{ units.speed }}]</th>
              <th>Flowrate [{{ units.flowRate }}]</th>
              <th colspan="3">Measurement Uncertainty of Flow (2 &sigma;)<sup>1</sup></th>
            </tr>
            <tr>
              <th colspan="2"></th>
              <th *ngIf="data.min.active">min</th>
              <th *ngIf="data.norm.active">norm</th>
              <th *ngIf="data.max.active">max</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let resultRow of this.resultData">
              <td>{{ resultRow.vog | safeNumber: '1.2-2' }}</td>
              <td>{{ resultRow.flowRate | safeNumber: '1.1-1' }}</td>
              <td *ngIf="data.min.active">{{ resultRow.uncertainty.min | number: '1.1-1' }}</td>
              <td *ngIf="data.norm.active">{{ resultRow.uncertainty.norm | number: '1.1-1' }}</td>
              <td *ngIf="data.max.active">{{ resultRow.uncertainty.max | number: '1.1-1' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="footnotes">
          <sup>1</sup>For fully developed flow profiles; based on ultrasonic transit time measurement.<br />
          <span *ngIf="data.activeSoundCorrelation"
            ><sup>2</sup>Increased uncertainty at max. VoG when switching to Active Sound Correlation technology
            (ASC).</span
          ><br />
          <span *ngIf="data.activeSoundCorrelation"
            ><sup>3</sup>Extended measuring range based on Active Sound Correlation technology (ASC), 130% of last
            velocity measured with ultrasonic time difference.</span
          >
        </div>
      </div>

      <!-- ----------------- -->
      <!-- Deviation Chart   -->
      <!-- ----------------- -->
      <div class="row" [style.display]="showResults ? 'grid' : 'none'" [style.min-height]="0">
        <div class="col-1-to-4" [style.opacity]="showResults ? 1 : 0" id="plotly_container_deviation"></div>
      </div>

      <!-- ----------------- -->
      <!-- M0 Deviation      -->
      <!-- ----------------- -->
      <div *ngIf="showResults && showM0Deviation && showDebug" class="results">
        <table class="result-table">
          <thead>
            <tr>
              <th>VoG in [{{ units.speed }}]</th>
              <th>Flowrate [{{ units.flowRate }}]</th>
              <th colspan="3">
                Estim. deviation w/o<br />correction in %<br />
                <span style="color: red">(confidential)</span>
              </th>
            </tr>
            <tr>
              <th colspan="2"></th>
              <th *ngIf="data.min.active">min</th>
              <th *ngIf="data.norm.active">norm</th>
              <th *ngIf="data.max.active">max</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let resultRow of this.resultData">
              <td>{{ resultRow.vog | safeNumber: '1.2-2' }}</td>
              <td>{{ resultRow.flowRate | safeNumber: '1.1-1' }}</td>
              <td *ngIf="data.min.active">{{ resultRow.m0deviation.min | number: '1.1-1' }}</td>
              <td *ngIf="data.norm.active">{{ resultRow.m0deviation.norm | number: '1.1-1' }}</td>
              <td *ngIf="data.max.active">{{ resultRow.m0deviation.max | number: '1.1-1' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- ----------------- -->
      <!-- M0min Dev Chart   -->
      <!-- ----------------- -->
      <div
        class="row"
        [style.display]="showResults && data.m0deviation && data.min.active ? 'grid' : 'none'"
        [style.min-height]="0"
      >
        <div class="col-1-to-4" id="plotly_container_m0min"></div>
      </div>

      <!-- ----------------- -->
      <!-- M0norm Dev Chart  -->
      <!-- ----------------- -->
      <div
        class="row"
        [style.display]="showResults && data.m0deviation && data.norm.active ? 'grid' : 'none'"
        [style.min-height]="0"
      >
        <div class="col-1-to-4" id="plotly_container_m0norm"></div>
      </div>

      <!-- ----------------- -->
      <!-- M0max Dev Chart   -->
      <!-- ----------------- -->
      <div
        class="row"
        [style.display]="showResults && data.m0deviation && data.max.active ? 'grid' : 'none'"
        [style.min-height]="0"
      >
        <div class="col-1-to-4" id="plotly_container_m0max"></div>
      </div>

      <div class="row" *ngIf="data.alarmMarker.agc">
        <davinci-callout class="col-1-to-4" type="warning"> Signal strength is weak. </davinci-callout>
      </div>

      <div class="row" *ngIf="showResults">
        <davinci-button class="col-3" *ngIf="persistenceAvailable" type="button" (click)="saveResult()"
          >Save result
        </davinci-button>
        <davinci-button class="col-4" *ngIf="showResults" type="button" id="resultAnchor" (click)="generatePdf(true)">
          Download as PDF</davinci-button
        >
      </div>
    </form>
  </div>

  <div *ngIf="showDebug">
    <hr />
    <h3>Debug</h3>

    <div class="row">
      <davinci-button class="col-1" (click)="debugShowAllAlarmMarkers()">Show all alarm marker</davinci-button>
    </div>

    <div class="row">
      <textarea
        id="shareDataTextArea"
        class="col-1-to-4"
        rows="20"
        [(ngModel)]="shareData"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>

      <davinci-button class="col-3" type="outline" (click)="clearShareDataTextArea()">Clear</davinci-button>
      <davinci-button class="col-4" (click)="copySharedDataToClipboard()">Copy to clipboard</davinci-button>
    </div>
  </div>

  <div class="spinner-container" *ngIf="userService.state === 'loading'">
    <div class="spinner"></div>
  </div>

  <div class="spinner-container" *ngIf="userService.state === 'error'">
    <h1>Sorry...</h1>

    <img
      src="/assets/imgs/undraw_secure_login.svg"
      style="max-width: 380px; margin-top: 3rem; margin-bottom: 3rem"
      alt="access image"
    />

    <h2>You are not allowed to use this SICK app.</h2>
    <div style="max-width: 475px; font-size: 1.2rem; margin-top: 2rem; color: #666">
      Please contact
      <a href="mailto:ronny.winkler@sick.de?subject=FlowTwin%20Access">Ronny Winkler (ronny.winkler@sick.de)</a> or
      <a href="mailto:claus.girschik@sick.de?subject=FlowTwin%20Access">Claus Girschik (claus.girschik@sick.de)</a> to
      request access.
    </div>
  </div>

  <div class="spinner-container" *ngIf="showBusyIndicator">
    <h1>Computing...</h1>
    <div class="spinner"></div>
    <h2 class="hint"></h2>
  </div>
</sick-davinci-workspace>
