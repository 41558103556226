<sick-davinci-workspace>
  <div class="outter-container">
    <div class="container">
      <!-- **************** -->
      <!-- Header -->
      <!-- ***************  -->

      <div class="row">
        <h1 class="col-1-to-4">Flare Gas Application</h1>
      </div>

      <div class="row">
        <h2 class="col-1-to-3" style="margin: 0;">User Management</h2>
        <davinci-button class="col-4" (click)="addUserRecord()">Add user</davinci-button>
      </div>

      <!-- **************** -->
      <!-- Table -->
      <!-- ***************  -->

      <div class="row" style="margin-top: 3rem;">
        <div class="col-1-to-4 mat-elevation-z4">
          <div class="row">
            <davinci-text-field
              class="col-4"
              style="width: 200px; margin: 1rem;"
              label="Search"
              [(ngModel)]="searchTerm"
            ></davinci-text-field>
          </div>
          <mat-table [dataSource]="dataSource">
            <!-- Name -->
            <ng-container matColumnDef="userfull">
              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.userfull }} </mat-cell>
            </ng-container>

            <!-- SICK ID -->
            <ng-container matColumnDef="usersickid">
              <mat-header-cell *matHeaderCellDef> SICK ID </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.usersickid }} </mat-cell>
            </ng-container>

            <!-- GBC-04 Expert -->
            <ng-container matColumnDef="gbc04expert">
              <mat-header-cell *matHeaderCellDef> Expert </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon color="primary" *ngIf="element.gbc04expert === 1">check_circle</mat-icon>
                <span *ngIf="element.gbc04expert === 0">-</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="gbc04">
              <mat-header-cell *matHeaderCellDef> GBC-04 </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon color="primary" *ngIf="element.gbc04 === 1">check_circle</mat-icon>
                <span *ngIf="element.gbc04 === 0">-</span>
              </mat-cell>
            </ng-container>

            <!-- SSU  -->
            <ng-container matColumnDef="ssu">
              <mat-header-cell *matHeaderCellDef> SSU </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon color="primary" *ngIf="element.ssu === 1">check_circle</mat-icon>
                <span *ngIf="element.ssu === 0">-</span>
              </mat-cell>
            </ng-container>

            <!-- Admin -->
            <ng-container matColumnDef="admin">
              <mat-header-cell *matHeaderCellDef> Admin1 </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon color="primary" *ngIf="element.admin === 1">check_circle</mat-icon>
                <span *ngIf="element.admin === 0">-</span>
              </mat-cell>
            </ng-container>

            <!-- Feature M0 -->
            <ng-container matColumnDef="m0deviation">
              <mat-header-cell *matHeaderCellDef> M0 </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon color="primary" *ngIf="element.m0deviation === 1">check_circle</mat-icon>
                <span *ngIf="element.m0deviation === 0">-</span>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" (click)="editUserRecord(row)"></mat-row>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</sick-davinci-workspace>

<!-- **************** -->
<!-- Edit User Dialog -->
<!-- ***************  -->

<app-dialog [open]="showUserEditDialog" (dismiss)="userEditDialogCancel()">
  <div header>
    <div mat-card-title *ngIf="userEditDialogType === 'edit-user'">Edit User</div>
    <div mat-card-title *ngIf="userEditDialogType === 'new-user'">Add User</div>
  </div>

  <div body>
    <form [formGroup]="userEditForm">
      <div class="row">
        <davinci-text-field
          class="col-1-to-4"
          formControlName="userfull"
          label="Name*"
          [error]="hasError('userfull')"
        ></davinci-text-field>

        <davinci-text-field
          class="col-1-to-4"
          formControlName="usersickid"
          label="SICK-ID*"
          [error]="hasError('usersickid')"
        ></davinci-text-field>

        <div
          class="col-1-to-4 error user-edit-sick-id-in-use"
          *ngIf="userEditSickIdInUse && userEditDialogType === 'new-user'"
        >
          <mat-icon>warning</mat-icon> Sick ID is in use!
        </div>

        <div class="col-1">GBC04</div>
        <davinci-checkbox class="col-2" formControlName="gbc04" label="SICK-ID"></davinci-checkbox>

        <div class="col-3">GBC04 Expert</div>
        <davinci-checkbox class="col-4" formControlName="gbc04expert" label="SICK-ID"></davinci-checkbox>

        <div class="col-1">SSU</div>
        <davinci-checkbox class="col-2" formControlName="ssu" label="SICK-ID"></davinci-checkbox>

        <div class="col-3">Admin</div>
        <davinci-checkbox class="col-4" formControlName="admin" label="SICK-ID"></davinci-checkbox>

        <div class="col-1">Feat M0 deviation</div>
        <davinci-checkbox class="col-2" formControlName="m0deviation" label="SICK-ID"></davinci-checkbox>
      </div>
    </form>
  </div>

  <div footer>
    <div class="row no-margin-bottom">
      <davinci-button
        class="col-1"
        type="outline"
        (click)="userEditDialogDelete()"
        *ngIf="userEditDialogType === 'edit-user'"
      >
        Delete
      </davinci-button>
      <!-- Cancel button -->
      <davinci-button class="col-3" type="outline" (click)="userEditDialogCancel()">
        Cancel
      </davinci-button>
      <davinci-button class="col-4" (click)="userEditDialogSave()" [ngClass]="{ disabled: !userEditDialogIsValid }">
        Save
      </davinci-button>
    </div>
  </div>
</app-dialog>

<!-- **************** -->
<!-- Delete User Dialog -->
<!-- ***************  -->

<app-dialog [open]="showDeleteUserDialog" (dismiss)="delteUserDialogCancel()">
  <div header>
    <div mat-card-title>Delte user?</div>
  </div>

  <div body>
    Do you really want to delete this user?
  </div>

  <div footer>
    <div class="row no-margin-bottom">
      <davinci-button class="col-3" type="outline" (click)="deleteUserDialogYes()">
        Yes please!
      </davinci-button>
      <!-- Cancel button -->
      <davinci-button class="col-4" (click)="delteUserDialogCancel()">No!</davinci-button>
    </div>
  </div>
</app-dialog>
