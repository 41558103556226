import { Component, OnInit } from '@angular/core';
import { DavinciCoreModel, DavinciCoreService } from '@sick-davinci/core-ng';
import { OAuthService } from 'angular-oauth2-oidc';
import { ReplaySubject } from 'rxjs';
import * as semver from 'semver';
import { environment } from 'src/environments/environment';
import * as packageJson from '../../package.json';
import { OAuthServiceMock } from '../tests/OAuthServiceMock';
import { HomeComponent } from './components/home/home.component';
import { AppInitializerService } from './services';
import { ModalService } from './services/modal.service';
import { FlowsicLogEntry, PersistenceService } from './services/persistence.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from './services/user.service';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface User {
  name: string;
  id: string;
}

const SUPPORTED_API_VERSION = '~0.5.0';

@Component({
  selector: 'app-flare',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  customBranding: DavinciCoreModel.CustomHeaderBranding | null = null;

  toolbarConfig: DavinciCoreModel.Toolbar;

  modalConfig: DavinciCoreModel.ModalConfig = {
    showCancelButton: false,
    title: 'List of saved entries',
    titleBar: 'Click entry to load data in form',
  };

  user = {
    name: 'anonymous',
    id: '',
  };

  defaultMenu = [];

  frontendVersion = packageJson.name + ' ' + packageJson.version;
  supportedAPIversion = SUPPORTED_API_VERSION;
  backendAPIVersion = '?';

  error: string;

  /**
   *
   */
  entryList$: ReplaySubject<FlowsicLogEntry[]>;

  homeComponent: HomeComponent;

  persistenceAvailable = false;

  constructor(
    // inject the DavinciCoreService for access to APIs
    private init: AppInitializerService,
    public coreService: DavinciCoreService,
    public modalService: ModalService,
    private authService: OAuthService,
    private persistenceService: PersistenceService,
    private userSerive: UserService,
    private http: HttpClient,
    private router: Router
  ) {
    this.entryList$ = new ReplaySubject(1);
    this.defaultMenu = [
      {
        label: 'Logout',
        callback: () => {
          this.authService.logOut();
        },
      },
    ];

    const buttons: { iconClass: string; callback: any }[] = [];
    this.toolbarConfig = {
      buttons,
      menu: this.defaultMenu,
      type: 'header',
    };

    this.getAPIVersion().subscribe(
      (version) => {
        if (!semver.satisfies(version, SUPPORTED_API_VERSION)) {
          this.error =
            'The Backend API version ' +
            version +
            ' is not supported. Supported versions are: ' +
            SUPPORTED_API_VERSION;
        }
        this.backendAPIVersion = version;
      },
      (error) => {
        this.error = error.message;
      }
    );
    if (environment.activeProfile === 'local' && environment.oidc.issuer.indexOf('http') < 0) {
      // skip authentication against SICK ID
      this.authService = (<unknown>new OAuthServiceMock()) as OAuthService;
    }
  }

  ngOnInit() {
    if (this.init.hasBearerToken) {
      const claims = this.authService.getIdentityClaims();
      this.userSerive.fetchUserRecord().subscribe((res) => {});
      let name = claims['name'];
      if (!name) {
        name = claims['given_name'] + ' ' + claims['family_name'];
      }
      const companyName = claims['company'];
      if (companyName) {
        name += ', ' + companyName;
      }
      this.user = {
        name,
        id: claims['sub'],
      };
      this.customBranding = { user: this.user.name, text: 'FLOWtwin™', imageUrl: 'assets/imgs/FLOWtwin_Logo.jpg' };
    }

    // Admin
    this.userSerive.userRecord$.pipe(filter((msg) => msg.state === 'ready')).subscribe(() => {
      if (this.userSerive.isAdmin) {
        this.toolbarConfig.menu = [
          {
            label: 'Flare',
            callback: () => {
              this.router.navigate(['/']);
            },
          },
          {
            label: 'Users',
            callback: () => {
              this.router.navigate(['/admin/users']);
            },
          },
          ...this.defaultMenu,
        ];
      }
    });
  }

  /**
   * if the home component is loaded subscribe to it's
   * loadData event, so we can query data and populate
   * the entryList$ which the modal has subscribed to
   */
  onComponentActivated($component: Component) {
    if ($component instanceof HomeComponent) {
      this.homeComponent = $component;
      this.homeComponent.user = this.user;
      this.homeComponent.persistenceAvailable = this.persistenceService.isApiAvailable();
    }
  }

  getAPIVersion() {
    if (!environment.apiUrl || environment.apiUrl.length < 1) {
      throw new Error('No apiUrl provided in environment!');
    }
    return this.http.get(environment.apiUrl + '/version', {
      responseType: 'text',
    });
  }
}
