import { Injectable } from '@angular/core';
import { DavinciCoreModel, DavinciCoreService } from '@sick-davinci/core-ng';
import { take, filter } from 'rxjs/operators';

export type ModalDialogId = 'load' | 'gas-composition' | 'vog-vector' | 'parameters';

const ModalConfigs: { [k in ModalDialogId]: DavinciCoreModel.ModalConfig } = {
  ['load']: {
    showCancelButton: false,
    title: 'List of saved entries',
    titleBar: 'Click entry to load data in form',
  },
  ['gas-composition']: {
    showCancelButton: false,
    title: 'Gas Composition',
    titleBar: 'Define your gas composition',
  },
  ['vog-vector']: {
    showCancelButton: false,
    title: 'Velocity Vector',
    titleBar: 'Define your velocity vector',
  },
  ['parameters']: {
    showCancelButton: false,
    title: 'Parameter  Overview',
    titleBar: 'Define your Parameters',
  },
};

export type ModalData = {
  [k in ModalDialogId]: any;
};

@Injectable({ providedIn: 'root' })
export class ModalService {
  dialog: ModalDialogId = 'load';
  config: DavinciCoreModel.ModalConfig = ModalConfigs.load;

  constructor(private coreService: DavinciCoreService) {}

  public openModal(type: ModalDialogId, onClose: () => any = () => null) {
    this.config = ModalConfigs[type];
    this.dialog = type;
    this.coreService.modalActive$.next(true);
    this.coreService.modalActive$
      .pipe(
        filter((active) => active === false),
        take(1)
      )
      .subscribe(onClose);
  }
}
