import * as Converter from 'js-quantities';
import {
  ParameterMatrix,
  ResultRow,
  UncertaintyData,
  UncertaintyInputData,
  UNITS,
  UnitSystemKey,
} from '../model/model';
import { numberParser } from '../utils/numberParser';
import {
  convertParameterMatrixData2View,
  convertParameterMatrixView2Data,
  convertParameterMatrixView2View,
} from './parameters';

/**
 * We have two types of models: a view model (UncertaintyInputData) and a normalized data model (UncertaintyData)
 *
 * The view model (UncertaintyInputData) contains the user input, either 'metric' or 'imperial' and user friendly
 * formated percent number, temperature in celius or fahrenheit, ...
 *
 * The data model (UncertaintyData) model holds normalized data, e.g. temperature in Celvin, 99.9 percent as 0.999, ...
 *
 * We convert from VieModel to DataModel:
 *
 *  - UncertaintyInputData('metric' or 'imperial') => UncertaintyInputData('metric') => normalized UncertaintyData (UncertaintyData)
 *
 * from DataModel to ViewModel
 *
 *  - normalized UncertaintyData => => UncertaintyInputData('metric') => UncertaintyInputData('metric' or 'imperial')
 */

// A function that converts values, e.g. distance from meter to feet
type ConverterNumberFunction = (num: number | string | null | undefined) => number | null | undefined;
type ConvertMatrixFunction = (matrix: ParameterMatrix<number | string | null>) => ParameterMatrix<number>;

interface ConverterFunctions {
  dropDown: ConverterNumberFunction;
  flowRate: ConverterNumberFunction;
  flowRateHp: ConverterNumberFunction; // number without truncating after two decimal places
  matrix: ConvertMatrixFunction;
  mol: ConverterNumberFunction;
  num: ConverterNumberFunction;
  numHp: ConverterNumberFunction; // number without truncating after two decimal places
  percent: ConverterNumberFunction;
  pressure: ConverterNumberFunction;
  smallLength: ConverterNumberFunction;
  sos: ConverterNumberFunction;
  speed: ConverterNumberFunction;
  speedHp: ConverterNumberFunction; // number without truncating after two decimal places
  temperature: ConverterNumberFunction;
  agc: ConverterNumberFunction;
}

//
// Functions
//

// allows us to chain functions: chain(a,b,c) === x => c(b(a(x))
const chain = (...funcs: Function[]) => (input: any) => {
  try {
    return funcs.reduce((val, f) => f(val), input);
  } catch (e) {
    return input;
  }
};

// simple copy values over
const copy = (input: any) => input;

// parses a number, defaults to 0 for `null` or `undefined`
const localeNumberUs = numberParser('en-US');
const num = (input: any = 0) => localeNumberUs(input);

// parses a boolean
const bool = (b: string | boolean | null | undefined) => {
  if (typeof b === 'string') {
    if (b === 'true') {
      return true;
    }

    if (b === 'false') {
      return false;
    }

    if (b.charCodeAt(0) === 0) {
      return false;
    }

    return b === '0' ? false : true;
  }
  return !!b;
};

// converts a number into a string  in fixed-point notation.
const fix = (fractionDigits: number) => (input: number) => input.toFixed(fractionDigits);
const fix0 = fix(0);
const fix2 = fix(2);
const fix4 = fix(4);

// return null or undefined if input is null or undefined - and interrupts the chain
const nill = (input: any) => {
  if (input == null) {
    throw Error();
  }
  return input;
};

// convert result rows
const resultRows = (props: {
  speed: ConverterNumberFunction;
  flowRate: ConverterNumberFunction;
  num: ConverterNumberFunction;
}) => (resultRowsArray: ResultRow[]) => {
  const { speed, flowRate, num: number } = props;
  return resultRowsArray.map((row) => ({
    vog: isNaN(Number(row.vog)) ? row.vog : speed(row.vog), // row.vog kann auch ein String sein wie `v_max`
    flowRate: isNaN(Number(row.flowRate)) ? row.flowRate : flowRate(row.flowRate), // flowRate kann auch ein String sein wie `Q_max`
    uncertainty: {
      min: number(row.uncertainty.min),
      norm: number(row.uncertainty.norm),
      max: number(row.uncertainty.max),
    },
    m0deviation: {
      min: number(row.m0deviation.min),
      norm: number(row.m0deviation.norm),
      max: number(row.m0deviation.max),
    },
  }));
};

// Converter function for a ViewModel to ViewModel transformation
export const converters: (from: UnitSystemKey, to: UnitSystemKey) => ConverterFunctions = (from, to) => {
  const unitsFrom = UNITS[from];
  const unitsTo = UNITS[to];

  const flowRate = Converter.swiftConverter(unitsFrom.flowRate, unitsTo.flowRate);
  const mol = Converter.swiftConverter(unitsFrom.mol, unitsTo.mol);
  const pressure = Converter.swiftConverter(unitsFrom.pressure, unitsTo.pressure);
  const smallLength = Converter.swiftConverter(unitsFrom.smallLength, unitsTo.smallLength);
  const sos = Converter.swiftConverter(unitsFrom.sos, unitsTo.sos);
  const speed = Converter.swiftConverter(unitsFrom.speed, unitsTo.speed);
  const temperature = Converter.swiftConverter(unitsFrom.temperature, unitsTo.temperature);

  return {
    dropDown: chain(num, fix0),
    flowRate: chain(num, flowRate, fix2, num),
    flowRateHp: chain(num, flowRate, fix4, num),
    matrix: convertParameterMatrixView2View(from, to) as ConvertMatrixFunction,
    mol: chain(num, mol, fix2, num),
    num: copy,
    numHp: copy,
    percent: copy,
    agc: copy,
    pressure: chain(num, pressure, fix2, num),
    smallLength: chain(num, smallLength, fix2, num),
    sos: chain(num, sos, fix2, num),
    speed: chain(num, speed, fix2, num),
    speedHp: chain(num, speed, fix4, num),
    temperature: chain(num, temperature, fix2, num),
  };
};

// Converter functions for  a metric view model to normalized data model
const viewModel2DataModelConverters: () => ConverterFunctions = () => {
  const percent = (n: number) => n / 100;
  const temperature = (tempInCelsius: number) => tempInCelsius + 273.15;

  return {
    dropDown: num,
    flowRate: num,
    flowRateHp: num,
    matrix: convertParameterMatrixView2Data,
    mol: num,
    num: num,
    numHp: num,
    percent: percent,
    pressure: num,
    smallLength: num,
    sos: num,
    speed: num,
    speedHp: num,
    agc: num,
    temperature: chain(num, temperature),
  };
};

// Converter function for a data model to a metric view model transformation
const dataModelToViewModelConverters: () => ConverterFunctions = () => {
  const percent = (n: number) => n * 100;
  const temperature = (tempInKelvin: number) => tempInKelvin - 273.15;

  return {
    dropDown: chain(num, fix0),
    flowRate: chain(num, fix2, num),
    flowRateHp: chain(num),
    matrix: convertParameterMatrixData2View as ConvertMatrixFunction,
    mol: chain(num, fix2, num),
    num: chain(num, fix2, num),
    numHp: chain(num),
    percent: chain(num, percent, fix2, num),
    pressure: chain(num, fix2, num),
    smallLength: chain(num, fix2, num),
    sos: chain(num, fix2, num),
    speed: chain(num, fix2, num),
    speedHp: chain(num, fix4, num),
    agc: chain(num, fix2, num),
    temperature: chain(num, temperature, fix2, num),
  };
};

const _convert = (props: {
  model: UncertaintyInputData | UncertaintyData;
  usingConverterFunctions: ConverterFunctions;
  to: UnitSystemKey;
}): UncertaintyData => {
  const { usingConverterFunctions: converterFunctions, to, model: input } = props;
  const {
    pressure,
    sos,
    temperature,
    speed,
    speedHp,
    percent,
    num: number,
    numHp: numberHp,
    matrix,
    dropDown,
    flowRate,
    flowRateHp,
    agc,
  } = converterFunctions;

  const nullOrSpeed = chain(nill, speedHp); // if the input is null => null, otherwise convert using `speed`
  const nullOrFlowRate = chain(nill, flowRateHp); // if the input is null => null, otherwise convert  `flowRate`

  const ret: UncertaintyData = {
    id: copy(input.id),
    version: copy(input.version),
    frontendVersion: copy(input.frontendVersion),
    backendVersion: copy(input.backendVersion),

    user: copy(input.user),
    userList: copy(input.userList),

    min: {
      active: bool(input.min.active),
      pressure: pressure(input.min.pressure),
      temperature: temperature(input.min.temperature),
      speed: sos(input.min.speed),
      agc: agc(input.min.agc),
    },
    norm: {
      active: bool(input.norm.active),
      pressure: pressure(input.norm.pressure),
      temperature: temperature(input.norm.temperature),
      speed: sos(input.norm.speed),
      agc: agc(input.norm.agc),
    },
    max: {
      active: bool(input.max.active),
      pressure: pressure(input.max.pressure),
      temperature: temperature(input.max.temperature),
      speed: sos(input.max.speed),
      agc: agc(input.max.agc),
    },

    projectName: input.projectName,
    projectType: input.projectType,
    reference: input.reference,
    tag: input.tag,
    poNumber: input.poNumber,
    partNumber: input.partNumber,
    serialNumber: input.serialNumber,
    diameter: input.diameter,
    innerDiameter: input.innerDiameter,
    nozzleLength: input.nozzleLength,
    deviceType: input.deviceType,
    pathConfiguration: input.pathConfiguration,
    installType: input.installType,
    speedUncertaintyPercent: number(input.speedUncertaintyPercent),
    speedUncertaintyMax: speed(input.speedUncertaintyMax),
    speedUncertaintyAbove: number(input.speedUncertaintyAbove),
    unitSystem: to,
    exZone: input.exZone,
    explanation: input.explanation,
    speedOfSoundMethod: input.speedOfSoundMethod,
    activeSoundCorrelation: bool(input.activeSoundCorrelation && !input.m0deviation),
    envelope: bool(input.envelope && !input.m0deviation),
    m0deviation: bool(input.m0deviation),
    gasComposition: {
      argon: percent(input.gasComposition.argon),
      carbondioxid: percent(input.gasComposition.carbondioxid),
      carbonMonoxid: percent(input.gasComposition.carbonMonoxid),
      ethan: percent(input.gasComposition.ethan),
      helium: percent(input.gasComposition.helium),
      hydrogen: percent(input.gasComposition.hydrogen),
      hydrogenSulfid: percent(input.gasComposition.hydrogenSulfid),
      iButhan: percent(input.gasComposition.iButhan),
      iPenthan: percent(input.gasComposition.iPenthan),
      methan: percent(input.gasComposition.methan),
      nButhan: percent(input.gasComposition.nButhan),
      nDecane: percent(input.gasComposition.nDecane),
      nHepthan: percent(input.gasComposition.nHepthan),
      nHexan: percent(input.gasComposition.nHexan),
      nitrogen: percent(input.gasComposition.nitrogen),
      nNonane: percent(input.gasComposition.nNonane),
      nOcthan: percent(input.gasComposition.nOcthan),
      nPenthan: percent(input.gasComposition.nPenthan),
      oxygen: percent(input.gasComposition.oxygen),
      propan: percent(input.gasComposition.propan),
      water: percent(input.gasComposition.water),
    },

    molWeightAndKappa: {
      minMolWeight: number(input.molWeightAndKappa.minMolWeight),
      normMolWeight: number(input.molWeightAndKappa.normMolWeight),
      maxMolWeight: number(input.molWeightAndKappa.maxMolWeight),
      minIsentropic: number(input.molWeightAndKappa.minIsentropic),
      normIsentropic: number(input.molWeightAndKappa.normIsentropic),
      maxIsentropic: number(input.molWeightAndKappa.maxIsentropic),
    },

    vogVector: {
      velo1: speed(input.vogVector.velo1),
      velo2: speed(input.vogVector.velo2),
      velo3: speed(input.vogVector.velo3),
      velo4: speed(input.vogVector.velo4),
      velo5: speed(input.vogVector.velo5),
      velo6: speed(input.vogVector.velo6),
    },
    extendedData: {
      useCustomAngle: bool(input.extendedData.useCustomAngle),
      customAngleValue: number(input.extendedData.customAngleValue),
      additionalPathAngle: number(input.extendedData.additionalPathAngle),
      useUnpairedProbesTime: bool(input.extendedData.useUnpairedProbesTime),
      useCustomZeroPointUncertainty: bool(input.extendedData.useCustomZeroPointUncertainty),
      customZeroPointUncertainty: number(input.extendedData.customZeroPointUncertainty),
      unpairedProbesTimeDiff: number(input.extendedData.unpairedProbesTimeDiff),
    },

    solutions: {
      m1: true, // immer true,
      m2a: bool(input.solutions.m2a),
      m3a: bool(input.solutions.m3a),
      m3b: bool(input.solutions.m3b),
      m3c: bool(input.solutions.m3c),
      m4a: bool(input.solutions.m4a),
      m4b: bool(input.solutions.m4b),
      deviceTypeM3a: dropDown(input.solutions.deviceTypeM3a),
      angleTypeM3b: number(input.solutions.angleTypeM3b),
      deviceTypeM3b: dropDown(input.solutions.deviceTypeM3b),
      deviceTypeM3c: dropDown(input.solutions.deviceTypeM3c),
      vMinLimit1: speed(input.solutions.vMinLimit1), // M1
      vMinLimit2: speed(input.solutions.vMinLimit2), // M1+m2
      vMinLimit3: speed(input.solutions.vMinLimit3), // M1+M2+M3a/c
      vMinLimit4: speed(input.solutions.vMinLimit4), // M1+M2+M3b
    },

    parameters: matrix(input.parameters),

    alarmMarker: {
      agc: bool(input.alarmMarker.agc),
      spec: bool(input.alarmMarker.spec),
      solution: bool(input.alarmMarker.solution),
      customSolution: bool(input.alarmMarker.customSolution),
      genericMessages: input.alarmMarker.genericMessages,
    },

    results: {
      md5: copy(input.results.md5),
      resultRows: resultRows({ speed: speedHp, flowRate: flowRateHp, num: numberHp })(input.results.resultRows),
      envelopeResultRows: resultRows({ speed: speedHp, flowRate: flowRateHp, num: numberHp })(
        input.results.envelopeResultRows
      ),
      calculatedParams: input.results.calculatedParams
        ? {
            min: {
              molWeight: numberHp(input.results.calculatedParams.min.molWeight),
              maxVelocity: speedHp(input.results.calculatedParams.min.maxVelocity),
              maxFlowRate: flowRateHp(input.results.calculatedParams.min.maxFlowRate),
              maxVelocityAsc: chain(nill, speedHp)(input.results.calculatedParams.min.maxVelocityAsc),
              maxFlowRateAsc: chain(nill, flowRateHp)(input.results.calculatedParams.min.maxFlowRateAsc),
            },
            norm: {
              molWeight: numberHp(input.results.calculatedParams.norm.molWeight),
              maxVelocity: speedHp(input.results.calculatedParams.norm.maxVelocity),
              maxFlowRate: flowRateHp(input.results.calculatedParams.norm.maxFlowRate),
              maxVelocityAsc: nullOrSpeed(input.results.calculatedParams.norm.maxVelocityAsc),
              maxFlowRateAsc: nullOrFlowRate(input.results.calculatedParams.norm.maxFlowRateAsc),
            },
            max: {
              molWeight: numberHp(input.results.calculatedParams.max.molWeight),
              maxVelocity: speedHp(input.results.calculatedParams.max.maxVelocity),
              maxFlowRate: flowRateHp(input.results.calculatedParams.max.maxFlowRate),
              maxVelocityAsc: nullOrSpeed(input.results.calculatedParams.max.maxVelocityAsc),
              maxFlowRateAsc: nullOrFlowRate(input.results.calculatedParams.max.maxFlowRateAsc),
            },
          }
        : null,
    },
  };

  return ret;
};

export const convertViewModelToViewModel = (props: {
  input: UncertaintyInputData;
  from: UnitSystemKey;
  to: UnitSystemKey;
}): UncertaintyInputData => {
  const { input, from, to } = props;
  const converterFunctions = converters(from, to);
  const viewModel = _convert({ model: input, usingConverterFunctions: converterFunctions, to });
  return (viewModel as unknown) as UncertaintyInputData;
};

export const convertViewModel2DataModel = (input: UncertaintyInputData): UncertaintyData => {
  const metricViewModel = convertViewModelToViewModel({ input, from: input.unitSystem, to: 'metric' });
  const normalizedDataModel = _convert({
    model: metricViewModel,
    usingConverterFunctions: viewModel2DataModelConverters(),
    to: 'metric',
  });
  return normalizedDataModel;
};

export const convertDataModel2ViewModel: (input: UncertaintyData, to: UnitSystemKey) => UncertaintyInputData = (
  input,
  to
) => {
  const metricViewModel = _convert({
    model: input,
    usingConverterFunctions: dataModelToViewModelConverters(),
    to: 'metric',
  });

  const viewModel = convertViewModelToViewModel({ input: metricViewModel, from: 'metric', to });
  return viewModel;
};
