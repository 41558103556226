import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UncertaintyInputData } from '../model/model';
import { clone } from '../utils/clone';
import { convertViewModel2DataModel } from '../utils/convert';
import { rnd2, rnd4 } from '../utils/round';

@Injectable({
  providedIn: 'root',
})
export class SosService {
  constructor(private http: HttpClient) {}

  static sumGasComposition(input: UncertaintyInputData) {
    const gas = { ...input.gasComposition };

    Object.keys(gas).forEach((element) => {
      gas[element] = rnd4(gas[element] / 100.0);
    });

    function sumComp(obj) {
      return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
    }

    return rnd4(sumComp(gas));
  }

  static isValidGasComposition(input: UncertaintyInputData) {
    return SosService.sumGasComposition(input) === 1;
  }

  /**
   * Computes the SoS based using
   * - kappa
   * - mol mass
   * - temperature
   *
   * (using SI units)
   */
  static sosUsingMolMass(input: UncertaintyInputData): UncertaintyInputData {
    const data = convertViewModel2DataModel(input);

    const computeSosUsingMolMass = (params: { kappa: number; molWeight: number; temperature: number }) => {
      const { kappa, molWeight, temperature } = params;
      return rnd2(Math.sqrt(((kappa * 8314) / molWeight) * temperature));
    };

    const {
      minMolWeight,
      normMolWeight,
      maxMolWeight: maxMolWeight,
      minIsentropic,
      normIsentropic,
      maxIsentropic,
    } = data.molWeightAndKappa;

    const minTemp = data.min.temperature - 273.15;
    const normTemp = data.norm.temperature - 273.15;
    const maxTemp = data.max.temperature - 273.15;

    const output = clone(input);

    output.min.speed = data.min.active
      ? computeSosUsingMolMass({
          kappa: minIsentropic,
          temperature: minTemp,
          molWeight: minMolWeight,
        })
      : null;

    output.norm.speed = data.norm.active
      ? computeSosUsingMolMass({
          kappa: normIsentropic,
          temperature: normTemp,
          molWeight: normMolWeight,
        })
      : null;

    output.max.speed = data.max.active
      ? computeSosUsingMolMass({
          kappa: maxIsentropic,
          temperature: maxTemp,
          molWeight: maxMolWeight,
        })
      : null;

    return output;
  }
}
