import {
  Component,
  Input,
} from '@angular/core';
import { DavinciCoreModel } from '../davinci-core.model';

@Component({
  selector: 'sick-davinci-workspace',
  templateUrl: './davinci-workspace.component.html',
  styleUrls: ['./davinci-workspace.component.scss']
})
export class DavinciWorkspaceComponent {
  @Input()
  toolbarConfig: DavinciCoreModel.Toolbar | null = null;
}
