import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Split from 'split.js';

interface WorkspaceState {
  domElement: HTMLElement;
  size: number;
  minSize: number;
  inactive: boolean;
}

@Component({
  selector: 'sick-davinci-resizable-container',
  templateUrl: './davinci-resizable-container.component.html',
  styleUrls: ['./davinci-resizable-container.component.scss']
})
export class DavinciResizableContainerComponent implements OnInit, OnChanges {
  @Input()
  type: 'columns' | 'rows' = 'columns';

  @Input()
  initialSizes: number[];

  @Input()
  minSizes: number[];

  @Input()
  inactive: boolean[] = [];

  host: HTMLElement;
  workspaces: Array<WorkspaceState> = [];
  splitJS: Split;

  constructor(private elRef: ElementRef) {
    this.host = elRef.nativeElement;
  }

  ngOnInit() {
    this.initWorkspaces();
    this.validateInputs();
    this.updateSplitJs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.inactive && !changes.inactive.firstChange) || (changes.minSize && !changes.minSize.firstChange)) {
      this.updateWorkspacesState();
    }

    if (changes.type) {
      this.host.classList.remove('columns');
      this.host.classList.remove('rows');
      this.host.classList.add(this.type);
    }
  }

  private updateWorkspacesState() {
    this.workspaces.forEach((workspace, index) => {
      workspace.minSize = this.minSizes[index];

      if (workspace.inactive && !this.inactive[index]) {
        this.activateWorkspace(index);
      } else if (!workspace.inactive && this.inactive[index]) {
        this.deactivateWorkspace(index);
      }
    });

    this.updateSplitJs();
  }

  private activateWorkspace(index: number) {
    const changingWS = this.workspaces[index];

    let sizes: Array<number> = [];
    if (this.splitJS) {
      sizes = this.splitJS && this.splitJS.getSizes();
    } else {
      sizes = [100];
    }
    this.workspaces
      .filter(workspace => !workspace.inactive)
      .map((workspace, i) => {
        workspace.size = sizes[i] - (sizes[i] * changingWS.size) / 100;
      });

    changingWS.domElement.style.display = 'flex';
    changingWS.inactive = false;
  }

  private deactivateWorkspace(index: number) {
    if (!this.splitJS) {
      return; // prevent last Workspace from being disabled
    }

    const changingWS = this.workspaces[index];
    const activeWorkspaces: Array<WorkspaceState> = this.workspaces.filter(workspace => !workspace.inactive);
    const activeIndex = activeWorkspaces.indexOf(changingWS);
    activeWorkspaces.splice(activeIndex, 1);

    const sizes: Array<number> = this.splitJS.getSizes();
    changingWS.size = sizes[activeIndex];
    sizes.splice(activeIndex, 1);

    changingWS.domElement.style.display = 'none';
    changingWS.inactive = true;

    activeWorkspaces.map((workspace, i) => {
      workspace.size = sizes[i] + sizes[i] * (changingWS.size / (100 - changingWS.size));
      return workspace;
    });
  }

  private updateSplitJs() {
    if (this.splitJS) {
      this.splitJS.destroy();
      this.splitJS = undefined;
    }

    const domElements: Array<HTMLElement> = [];
    const minSize: Array<number> = [];
    const sizes: Array<number> = [];

    this.workspaces.forEach(workspace => {
      if (!workspace.inactive) {
        domElements.push(workspace.domElement);
        minSize.push(workspace.minSize);
        sizes.push(workspace.size);
      }
    });

    if (domElements.length < 2) {
      return;
    }

    this.splitJS = Split(domElements, {
      sizes,
      minSize,
      cursor: this.type === 'columns' ? 'col-resize' : 'row-resize',
      direction: this.type === 'columns' ? 'horizontal' : 'vertical',
      gutter: (_i: unknown, gutterDirection: 'horizontal' | 'vertical') => {
        const splitter = document.createElement('div');
        splitter.className = `sick-davinci-splitter ${gutterDirection}`;
        return splitter;
      },
      gutterSize: 8,
      gutterStyle: () => {
        return {};
      },
      snapOffset: 0
    });
  }

  private initWorkspaces() {
    const childrenList = Array.from(this.host.children);
    let childElement: Element;

    for (childElement of childrenList) {
      if (childElement.tagName === 'SICK-DAVINCI-WORKSPACE') {
        const index = this.workspaces.length;
        const domElement = childElement as HTMLElement;
        domElement.classList.add('resizable');

        this.workspaces.push({
          domElement,
          size: this.initialSizes[index],
          minSize: this.minSizes[index],
          inactive: this.inactive[index] === undefined ? false : this.inactive[index]
        });
      } else {
        console.warn(
          '"SICK-DAVINCI-RESIZABLE-CONTAINER" should not contain other elements but' +
            ' "SICK-DAVINCI-WORKSPACE", but found a "' +
            childElement.tagName +
            '". Unless you know what you do, this' +
            ' may lead to unexpected behavior of the resizable areas!'
        );
      }
    }
  }

  private validateInputs() {
    const numberOfWorkspaces = this.workspaces.length || 0;
    const numberOfMinSizes = (this.minSizes && this.minSizes.length) || 0;
    const numberOfInitialSizes = (this.initialSizes && this.initialSizes.length) || 0;

    if (numberOfWorkspaces !== numberOfMinSizes || numberOfWorkspaces !== numberOfInitialSizes) {
      throw new Error(
        'DavinciResizableContainerComponent: ' + 'numbers of workspaces, initialSizes and minSizes do not match'
      );
    }
  }
}
