import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { groupLogger } from '../utils/groupLogger';

export interface UserRecord {
  userfull: string;
  gbc04expert: 0 | 1;
  admin: 0 | 1;
  gbc04: 0 | 1;
  ssu: 0 | 1;
  usersickid: string;
  m0deviation: 0 | 1;
}

type UserServiceState = 'loading' | 'ready' | 'error';

export interface UserServiceMessage {
  userRecord: UserRecord;
  state: UserServiceState;
}

const url = environment.userManagement;

const { logStart, logEnd, log, logError } = groupLogger({
  name: 'UserService',
  backgroundColor: 'RebeccaPurple',
  color: 'white',
  symbol: '👥',
});

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private authService: OAuthService) {}

  userRecord: UserRecord = {
    userfull: '',
    admin: 0,
    gbc04: 0,
    gbc04expert: 0,
    ssu: 0,
    usersickid: '',
    m0deviation: 0,
  };

  state: UserServiceState = 'loading';

  userRecord$ = new BehaviorSubject<UserServiceMessage>({
    userRecord: this.userRecord,
    state: this.state,
  });

  get isAdmin() {
    return this.userRecord.admin === 1;
  }

  get isGbc04() {
    return this.userRecord.gbc04 === 1;
  }

  get isGbc04expert() {
    return this.userRecord.gbc04expert === 1;
  }

  get isSsu() {
    return this.userRecord.ssu === 1;
  }

  get isM0deviation() {
    return this.userRecord.m0deviation === 1;
  }

  private dispatchMessage() {
    this.userRecord$.next({
      userRecord: this.userRecord,
      state: this.state,
    });
  }

  fetchUserRecord() {
    this.state = 'loading';

    const handleSuccess = (userRecord: UserRecord) => {
      this.userRecord = userRecord;
      this.state = 'ready';
      console.log(userRecord);
      logEnd();
      this.dispatchMessage();
    };

    const handleError = (err: any) => {
      this.state = 'error';
      console.log(err);
      logEnd();
      this.dispatchMessage();
      return throwError(err);
    };

    return this.http.get<UserRecord[]>(url, this.headers).pipe(
      tap(() => logStart('GET /usermgmt-record', '👤')),
      map((res) => res[0]),
      tap((res) => {
        if (res == null) {
          handleError('not allowed');
        } else {
          handleSuccess(res);
        }
      }),
      catchError(handleError)
    );
  }

  fetchAllUserRecords() {
    const urlAll = url + '?getAllUsers=1';
    return this.http
      .get<UserRecord[]>(urlAll, this.headers)
      .pipe(tap((res) => log('GET /usermgmt-record?getAllUsers=1', 'res', res)));
  }

  postUserRecord(newUserRecord: UserRecord) {
    return this.http.post(url, newUserRecord, this.headers).pipe(
      tap((res) => {
        logStart('POST /usermgmt-record', '👶');
        console.log('newUserRecord:', newUserRecord);
        console.log('res:', res);
        logEnd();
      })
    );
  }

  deleteUserRecord(recordToDelete: UserRecord) {
    const urlDel = url + '?id=' + recordToDelete.usersickid;
    return this.http.delete(urlDel, this.headers).pipe(
      tap((res) => {
        logStart(`DELETE /usermgmt-record?id=${recordToDelete.usersickid}`, '🗑️');
        console.log('recordToDelete:', recordToDelete);
        console.log('res:', res);
        logEnd();
      })
    );
  }

  //
  // Utils
  //
  get headers() {
    const token = this.authService.getIdToken();
    const headers = { headers: { Authorization: token } };
    return headers;
  }
}
