import { Injectable } from '@angular/core';
import { defaultUncertaintyInputData } from '../model/data';
import { UncertaintyInputData } from '../model/model';
import { convertViewModelToViewModel } from '../utils/convert';

const createImperialProject = () =>
  convertViewModelToViewModel({
    input: defaultUncertaintyInputData(),
    from: 'metric',
    to: 'imperial',
  });

@Injectable({
  providedIn: 'root',
})
export class DataService {
  data: UncertaintyInputData = createImperialProject();

  newProject() {
    this.data = createImperialProject();
  }
}
