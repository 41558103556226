import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEnUs from '@angular/common/locales/es-US';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { BasicElementsFormLibModule } from '@sick-davinci/basic-elements-ng-form';
import { Option } from '@sick-davinci/basic-elements/lib//option/Option';
import { Button } from '@sick-davinci/basic-elements/lib/button/Button';
import { Checkbox } from '@sick-davinci/basic-elements/lib/checkbox/Checkbox';
import { Callout } from '@sick-davinci/basic-elements/lib/callout/Callout';
import { DropDown } from '@sick-davinci/basic-elements/lib/drop-down/DropDown';
import { Spinner } from '@sick-davinci/basic-elements/lib/spinner/Spinner';
import { TextField } from '@sick-davinci/basic-elements/lib/text-field/TextField';
import { TextArea } from '@sick-davinci/basic-elements/lib/text-area/TextArea';
import { DavinciCoreModule, DavinciCoreService, NgxTranslateConfig } from '@sick-davinci/core-ng';
import { OAuthModule, OAuthResourceServerErrorHandler, OAuthService } from 'angular-oauth2-oidc';
import 'hammerjs';
import * as packageJson from '../../package.json';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import {
  ConvertPressurePipe,
  ConvertSpeedPipe,
  ConvertTemperaturePipe,
} from './components/convert-pipe/convert-pipe.pipe';
import { DialogComponent } from './components/dialog/dialog.component';
import { GasCompositionDialogComponent } from './components/gas-composition-dialog/gas-composition-dialog.component';
import { HomeComponent } from './components/home/home.component';
import { ListDataModalComponent } from './components/list-data-modal/list-data-modal.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { ParametersOverviewDialogComponent } from './components/parameters-overview-dialog/parameters-overview-dialog.component';
import { ProjectsPageComponent } from './components/projects-page/projects-page.component';
import { UnitFieldComponent } from './components/unit-field/unit-field.component';
import { UserChipInputComponent } from './components/user-chip-input/user-chip-input.component';
import { VogVectorDialogComponent } from './components/vog-vector-dialog/vog-vector-dialog.component';
import { OAuthErrorInterceptor } from './interceptors/oauth-error.interceptor';
import { OAuthInterceptor } from './interceptors/oauth.interceptor';
import { RequestInterceptor } from './interceptors/request-interceptor';
import { SafeNumberPipe } from './pipes/safeNumber.pipe';
import { AppInitializerService, PersistenceService } from './services';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MinPipe } from './pipes/min.pipe';

// Disable angular material ripples a SICK look and feel
// see https://material.angular.io/components/ripple/overview
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

registerLocaleData(localeEnUs);

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AdminUsersComponent,
    AppComponent,
    ConvertPressurePipe,
    ConvertSpeedPipe,
    ConvertTemperaturePipe,
    DialogComponent,
    GasCompositionDialogComponent,
    HomeComponent,
    ListDataModalComponent,
    ModalDialogComponent,
    ParametersOverviewDialogComponent,
    SafeNumberPipe,
    UnitFieldComponent,
    UserChipInputComponent,
    VogVectorDialogComponent,
    ProjectsPageComponent,
    MinPipe,
  ],
  imports: [
    AppRoutingModule, // Routing is part of the app and should live in its own module
    BasicElementsFormLibModule,
    BrowserAnimationsModule,
    BrowserModule,
    DavinciCoreModule.forRoot(), // Import DavinCiCore functionality
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSliderModule,
    MatTableModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    OAuthModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forRoot(NgxTranslateConfig),
  ],
  providers: [
    AppInitializerService,
    OAuthService,
    PersistenceService,
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.initialize(),
      multi: true,
      deps: [AppInitializerService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true,
    },
    {
      provide: OAuthResourceServerErrorHandler,
      useExisting: OAuthErrorInterceptor,
    },
    TextField,
    TextArea,
    DropDown,
    Option,
    Checkbox,
    Callout,
    Button,
    Spinner,
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private core: DavinciCoreService) {
    const appVersion = getVersionAsArray(packageJson.version);
    core.init(packageJson.name, appVersion);
  }
}

function getVersionAsArray(version: string): [number, number, number] {
  // remove alpha, beta ...
  if (version.indexOf('-') > -1) {
    version = version.split('-')[0];
  }

  const result = version.split('.').map((strVal) => parseInt(strVal, 10));

  return [result[0] || 0, result[1] || 0, result[2] || 0];
}
