import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { calculateMolecularWeight } from 'src/app/utils/calculateMolecularWeight';
import { calculateNoiseVelocity } from 'src/app/utils/calculateNoiseVelocity';
import { environment } from '../../../environments/environment';
import {
  AbstractProcessData,
  CalculatedParams,
  MeterPerSec,
  PreParams,
  ResultRow,
  UncertaintyData,
  UncertaintyInputData,
} from '../../model/model';
import { convertDataModel2ViewModel, convertViewModel2DataModel } from '../../utils/convert';
import { isDeviceSelectionValid } from '../../utils/isDeviceSelectionValid';
import { urlEncodeQuery } from '../../utils/urlEncoder';
import { log } from './log';
import {
  alpha,
  checkgas,
  gasComposition,
  kappa,
  measureMatrix,
  temperatures,
  customSoS,
  pressures,
  vogs,
  customMolWeight,
  configMatrix,
} from './query-builder';
import { tagResults } from 'src/app/utils/tagResults';
import { clone } from 'src/app/utils/clone';
import { patchEnvelopeValue } from 'src/app/utils/patchEnvelope';
import { bool } from 'aws-sdk/clients/signer';

interface AgcVek {
  min?: number;
  norm?: number;
  max?: number;
}
interface QVMaxVek {
  min?: number;
  norm?: number;
  max?: number;
}

// GET /octave/MasterFunction
//
interface MasterFunctionResponse {
  result: {
    AGC_vek: AgcVek;
    v_max_vek: QVMaxVek;
    Uncertainty_vek: [
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { values: { min?: number; norm?: number; max?: number } }
    ];
    sos_vek: { min?: number; norm?: number; max?: number };
    measure_mat?: number; // error cases
    Deviation?: [
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { velocity: number; flowrate: number; values: { min?: number; norm?: number; max?: number } },
      { values: { min?: number; norm?: number; max?: number } }
    ];
    qmax_vek: QVMaxVek;
    vmax_asc_vek: QVMaxVek;
    qmax_asc_vek: QVMaxVek;
    inner_diameter: number;
  };
  calledfunction: string;
}

// Returns an array of [ 'min', 'norm', 'max'] if related process data blocks are marked as `active`
function processConditions(data: UncertaintyData) {
  return [data.min.active ? 'min' : null, data.norm.active ? 'norm' : null, data.max.active ? 'max' : null].filter(
    (condition) => condition !== null
  );
}

//
// AGC Alarms
//
function alarmAgc(data: UncertaintyData, res: MasterFunctionResponse) {
  const { AGC_vek } = res.result;
  const { parameters } = data;
  const defaultForMax = function (num) {
    return typeof num === 'number' ? num : -Infinity;
  };

  const maxAgc = Math.max(defaultForMax(AGC_vek.min), defaultForMax(AGC_vek.norm), defaultForMax(AGC_vek.max));
  return maxAgc >= parameters[5][0].entry;
}

function alarmSpec(data: UncertaintyData, res: MasterFunctionResponse) {
  const { deviceType, diameter, pathConfiguration } = data;
  // TODO: make deviceType and pathConfiguration a number
  return isDeviceSelectionValid(Number(deviceType), Number(pathConfiguration), diameter);
}

function calculateResults(data: UncertaintyData, res: MasterFunctionResponse): ResultRow[] {
  const uncertaintyVek = res.result.Uncertainty_vek;
  const m0deviationVek = res.result.Deviation;

  // The uncertaintyVek looks like this

  // { velocity: number; values: { min?: number; norm?: number; max?: number } },
  // { velocity: number; values: { min?: number; norm?: number; max?: number } },
  // { velocity: number; values: { min?: number; norm?: number; max?: number } },
  // { velocity: number; values: { min?: number; norm?: number; max?: number } },
  // { velocity: number; values: { min?: number; norm?: number; max?: number } },
  // { velocity: number; values: { min?: number; norm?: number; max?: number } },
  // {                   values: { min?: number; norm?: number; max?: number } }

  interface Vek {
    velocity: number;
    flowrate: number;
    values: { min?: number; norm?: number; max?: number };
  }

  function numberRow(vog: MeterPerSec, vek: Vek, m0deviation: Vek): ResultRow {
    return {
      vog,
      flowRate: vek.flowrate,
      uncertainty: vek.values,
      m0deviation: m0deviation.values,
    };
  }

  function descriptionRow(
    vogDescription: string,
    flowRateDescription: string,
    uncertainty: { min?: number; norm?: number; max?: number },
    m0deviation: { min?: number; norm?: number; max?: number }
  ) {
    return {
      vog: vogDescription,
      flowRate: flowRateDescription,
      uncertainty,
      m0deviation,
    };
  }
  const resultRows = [
    numberRow(data.vogVector.velo1, uncertaintyVek[0], m0deviationVek[0]),
    numberRow(data.vogVector.velo2, uncertaintyVek[1], m0deviationVek[1]),
    numberRow(data.vogVector.velo3, uncertaintyVek[2], m0deviationVek[2]),
    numberRow(data.vogVector.velo4, uncertaintyVek[3], m0deviationVek[3]),
    numberRow(data.vogVector.velo5, uncertaintyVek[4], m0deviationVek[4]),
    numberRow(data.vogVector.velo6, uncertaintyVek[5], m0deviationVek[5]),
    descriptionRow('Vmax', 'Qmax', uncertaintyVek[6].values, m0deviationVek[6].values),
  ];

  if (!data.activeSoundCorrelation) {
    return resultRows;
  }

  //
  // With noise
  //
  const { min, norm, max } = uncertaintyVek[6].values;

  const noiseValues = (type: number) => ({
    min: calculateNoiseVelocity(min, type),
    norm: calculateNoiseVelocity(norm, type),
    max: calculateNoiseVelocity(max, type),
  });

  const noiseRows = [
    descriptionRow('Vmax ²', 'Qmax ²', noiseValues(3), { min: 0, norm: 0, max: 0 }),
    descriptionRow('Vmax, ASC ³', 'Qmax, ASC ³', noiseValues(7), { min: 0, norm: 0, max: 0 }),
  ];

  return [...resultRows, ...noiseRows];
}

function calculateParams(data: UncertaintyData, res: MasterFunctionResponse): CalculatedParams {
  function calculatePreParams(
    processData: AbstractProcessData<number>,
    max_Velocity: number,
    max_FlowRate: number,
    max_VelocityAsc: number,
    max_FlowRateAsc: number
  ): PreParams | undefined {
    const { active, temperature, speed } = processData;

    if (!active) {
      return {
        molWeight: -1,
        maxVelocity: -1,
        maxFlowRate: -1,
        maxFlowRateAsc: -1,
      };
    }

    const molWeight = calculateMolecularWeight(temperature, speed);
    const maxVelocity = max_Velocity;
    const maxVelocityAsc = data.activeSoundCorrelation ? max_VelocityAsc : null;
    const maxFlowRate = max_FlowRate;
    const maxFlowRateAsc = data.activeSoundCorrelation ? max_FlowRateAsc : null;

    return {
      molWeight,
      maxVelocity,
      maxFlowRate,
      maxVelocityAsc,
      maxFlowRateAsc,
    };
  }

  const { min, norm, max, m0deviation } = data;
  const { v_max_vek, qmax_vek, vmax_asc_vek, qmax_asc_vek } = res.result;

  const calculatedParams: CalculatedParams = {
    min: calculatePreParams(min, v_max_vek.min, qmax_vek.min, vmax_asc_vek.min, qmax_asc_vek.min),
    norm: calculatePreParams(norm, v_max_vek.norm, qmax_vek.norm, vmax_asc_vek.norm, qmax_asc_vek.norm),
    max: calculatePreParams(max, v_max_vek.max, qmax_vek.max, vmax_asc_vek.max, qmax_asc_vek.max),
  };

  return calculatedParams;
}

// GET /octave/DefaultParameterMatrix
//
// Returns the default configuration for the uncertainty and solution calculation, usually referred as 'parameter_mat’.
// The default config gets reloaded statelessy, so any modification will be reinitialized upon re - execution.
export const masterFunctionHandler = (http: HttpClient) => (input: UncertaintyInputData, clearFlags: boolean) => {
  //
  const data = convertViewModel2DataModel(input);

  const queryObj = {
    pressures: pressures(data),
    temperatures: temperatures(data),
    vogs: vogs(data),
    customSos: customSoS(data),
    customMolWeight: customMolWeight(data),
    kappa: kappa(data),
    checkgas: checkgas(data),
    gasComposition: gasComposition(data),
    installType: data.installType,
    deviceType: data.deviceType,
    diameter: data.diameter,
    paths: data.pathConfiguration,
    alpha: alpha(data),
    measureMatrix: measureMatrix(data),
    configMatrix: configMatrix(data),
    processConditions: processConditions(data),
  };

  const query = urlEncodeQuery(queryObj);
  const url = environment.apiUrl + '/octave/MasterFunction?' + query;

  // Responses handler

  function handleAlarms(res: MasterFunctionResponse) {
    if (clearFlags === true) {
      data.alarmMarker.agc = false;
      data.alarmMarker.spec = false;
      data.alarmMarker.customSolution = false;
      data.alarmMarker.solution = false;
      data.alarmMarker.genericMessages = [];
    }
    data.alarmMarker.agc = alarmAgc(data, res);
    data.alarmMarker.spec = !alarmSpec(data, res);
  }

  function handleSosVector(res: MasterFunctionResponse) {
    const { sos_vek } = res.result;
    data.min.speed = data.min.active ? sos_vek.min : data.min.speed;
    data.norm.speed = data.norm.active ? sos_vek.norm : data.norm.speed;
    data.max.speed = data.max.speed ? sos_vek.max : data.max.speed;
  }

  function handleAgcValues(res: MasterFunctionResponse) {
    const { AGC_vek } = res.result;

    if ([AGC_vek.min, AGC_vek.norm, AGC_vek.max].some((item) => Number.isNaN(item) || item < 0)) {
      data.alarmMarker.genericMessages.push('At least one invalid AGC value found!');
    }

    data.min.agc = data.min.active ? AGC_vek.min : data.min.agc;
    data.norm.agc = data.norm.active ? AGC_vek.norm : data.norm.agc;
    data.max.agc = data.max.active ? AGC_vek.max : data.max.agc;
  }

  function handleResultsCalculation(res: MasterFunctionResponse) {
    data.results.calculatedParams = calculateParams(data, res);
    data.results.resultRows = calculateResults(data, res);
    data.results.envelopeResultRows = calculateResultsWithEnvelope(res);
    data.innerDiameter = Number(res.result.inner_diameter).toString();
  }

  function calculateResultsWithEnvelope(res: MasterFunctionResponse): ResultRow[] {
    const patchedData = clone(data.results.resultRows);

    patchedData.forEach((element) => {
      element.uncertainty.max = patchEnvelopeValue(
        element.vog,
        element.uncertainty.max,
        data.speedUncertaintyMax,
        data.speedUncertaintyPercent,
        data.speedUncertaintyAbove
      );
      element.uncertainty.norm = patchEnvelopeValue(
        element.vog,
        element.uncertainty.norm,
        data.speedUncertaintyMax,
        data.speedUncertaintyPercent,
        data.speedUncertaintyAbove
      );
      element.uncertainty.min = patchEnvelopeValue(
        element.vog,
        element.uncertainty.min,
        data.speedUncertaintyMax,
        data.speedUncertaintyPercent,
        data.speedUncertaintyAbove
      );
    });
    return patchedData;
  }

  function tagData() {
    const d = convertDataModel2ViewModel(data, 'metric');
    const d2 = convertViewModel2DataModel(d);
    data.results.md5 = tagResults(d2);
  }

  function verifyAgc(res: MasterFunctionResponse) {
    const { AGC_vek } = res.result;
  }

  return http.get<MasterFunctionResponse>(url).pipe(
    tap((res) => log(url, queryObj, res)),
    tap(handleAlarms),
    tap(handleSosVector),
    tap(handleAgcValues),
    tap(handleResultsCalculation),
    tap(tagData),
    map(() => convertDataModel2ViewModel(data, input.unitSystem))
  );
};
