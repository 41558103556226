import { getOidcConfig } from '../app/oidc-config';

const identityProviderUrl = 'id.uat.sick.com/auth/realms/sickservices';

const oidcConfig = getOidcConfig(identityProviderUrl);

export const environment = {
  apiUrl: 'https://dev.api.flowsic-digitaldesign.sickag.cloud',
  persistenceApiUrl: 'https://ddb.dev.flowsic-digitaldesign.sickag.cloud',
  awsRegion: 'eu-central-1',
  apiVersionUrl: 'version',
  swsApiKey: '',
  production: false,
  frontend_url: oidcConfig.frontend_url,
  oidc: oidcConfig.oidc,
  activeProfile: 'development',
  providerUrl: identityProviderUrl,
  frontendVersion: require('../../package.json').version,
  userManagement: 'https://ddb.dev.flowsic-digitaldesign.sickag.cloud/usermgmt-record',
};
