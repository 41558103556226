import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { md5 } from 'src/app/utils/md5';
import { FormGroup, FormArray } from '@angular/forms';
import { UncertaintyInputData } from 'src/app/model/model';
import { OctaveService } from 'src/app/services/octave.service';

@Component({
  selector: 'app-parameters-overview-dialog',
  templateUrl: './parameters-overview-dialog.component.html',
  styleUrls: ['./parameters-overview-dialog.component.scss'],
})
export class ParametersOverviewDialogComponent implements OnInit {
  constructor(private octave: OctaveService) {}

  @Input() form: FormGroup;
  @Output() readOut: EventEmitter<any> = new EventEmitter();

  password = '';

  param(row: number, col: number) {
    const parameters = this.form.get(`parameters`) as FormArray;
    const rowFormArray = parameters.get([row]) as FormArray;
    const entryGroup = rowFormArray.get([col]);
    const entry = entryGroup.get('entry');
    return entry;
  }

  get passwordOk() {
    return md5(this.password) === '66f4e7a35b34e98c3317a400afce1def';
  }

  ngOnInit() {}
}
