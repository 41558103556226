import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DavinciCoreModel } from '../davinci-core.model';
import { DavinciCoreService } from '../davinci-core.service';
import NavigationItem = DavinciCoreModel.NavigationItem;

@Component({
  selector: 'sick-davinci-navigation-pane',
  templateUrl: './davinci-navigation-pane.component.html',
  styleUrls: ['./davinci-navigation-pane.component.scss']
})
export class DavinciNavigationPaneComponent implements OnInit {
  @HostBinding('class.visible') isVisible = false;
  @HostBinding('class.sticky') isSticky = false;

  openSectionsStatus: Array<boolean> = [];
  tree: NavigationItem[];
  path: string[];

  constructor(private core: DavinciCoreService, private router: Router) {}

  @HostListener('click', ['$event']) onPaneClick(event: Event) {
    event.stopPropagation();
    this.core.menuCloser$.next();
  }

  ngOnInit() {
    this.core.navigation$.subscribe(navigation => {
      if (this.tree !== navigation.tree) {
        this.tree = navigation.tree;
        this.openSectionsStatus = [];
        navigation.tree.forEach(() => this.openSectionsStatus.push(false));
      }

      this.path = navigation.currentPath;
    });

    this.core.navigationPane$.subscribe((pane: DavinciCoreModel.NavigationPane) => {
      pane = pane || DavinciCoreModel.defaultNavigationPane;

      this.isVisible = pane && (pane.isSticky || pane.isVisible);
      this.isSticky = pane && pane.isSticky;
    });

    window.addEventListener('click', () => {
      this.closePane();
    });

    this.core.paneCloser$.subscribe(() => {
      this.closePane();
    });
  }

  onLabelClick(item: NavigationItem) {
    this.route(item);
    this.core.paneCloser$.next();
  }

  onAccordionClick(event: MouseEvent, item: NavigationItem, itemIndex: number) {
    event.stopPropagation();
    const hasSubItems = item.sub && item.sub.length;
    if (itemIndex >= 0 && itemIndex < this.openSectionsStatus.length && hasSubItems) {
      this.openSectionsStatus[itemIndex] = !this.openSectionsStatus[itemIndex];
    }
  }

  onStickyClicked(event: Event) {
    event.stopPropagation();
    const pane = this.core.navigationPane$.getValue() || DavinciCoreModel.defaultNavigationPane;
    this.core.menuCloser$.next();
    this.core.navigationPane$.next({
      hasSearch: pane.hasSearch,
      isVisible: true,
      isSticky: !pane.isSticky
    });
  }

  route(item: NavigationItem) {
    if (!item.disabled) {
      this.router.navigateByUrl(item.route);
      return true;
    }

    return false;
  }

  private closePane() {
    const pane = this.core.navigationPane$.getValue();
    if (pane && !pane.isSticky && pane.isVisible) {
      this.core.navigationPane$.next({
        ...pane,
        isVisible: false
      });
    }
  }
}
