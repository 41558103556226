<div class="scroll-container">
  <table class="saved-entries-list">
    <tr>
      <th style="text-align: left;">Created</th>
      <th style="text-align: left;">Project</th>
      <th style="text-align: left;">Reference</th>
      <th style="text-align: left;">Tag</th>
      <th style="text-align: left;">PO #</th>
      <th style="text-align: left;">Part Number</th>
      <th style="text-align: left;">Serial</th>
      <th style="text-align: left;">DeviceType</th>
      <th style="text-align: left;">Unit system</th>
    </tr>
    <tr *ngFor="let entry of entryList$ | async" (click)="onEntryClicked(entry)">
      <td>{{ entry.created | date: 'medium' }}</td>
      <td>{{ entry.projectName }}</td>
      <td>{{ entry.reference }}</td>
      <td>{{ entry.tag }}</td>
      <td>{{ entry.poNumber }}</td>
      <td>{{ entry.partNumber }}</td>
      <td>{{ entry.serialNumber }}</td>
      <td>{{ deviceList[entry.deviceType] }},</td>
      <td class="small">({{ entry.displayUnitSystem }})</td>
    </tr>
  </table>
</div>
<div *ngIf="(entryList$ | async)?.length < 1">
  No data available
</div>
