import {
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'sick-davinci-workspace-tabs',
  templateUrl: './davinci-workspace-tabs.component.html',
  styleUrls: ['./davinci-workspace-tabs.component.scss']
})
export class DavinciWorkspaceTabsComponent implements OnInit {
  @Input()
  tabLabels: string[] = [];

  activeTabIndex = 0;
  containerElement: HTMLElement;
  tabList: Element[] = [];

  constructor(private elRef: ElementRef) {
    this.containerElement = elRef.nativeElement;
  }

  ngOnInit() {
    const ngContentChildren = Array.from(this.containerElement.children[1].children);
    let childElement: Element;

    for (childElement of ngContentChildren) {
      if (childElement.tagName === 'SICK-DAVINCI-WORKSPACE') {
        childElement.classList.add('tab');
        this.tabList.push(childElement);
      } else {
        console.warn('DavinciWorkspaceTabsComponent :: all transcluded, direct children of' +
          ' SICK-DAVINCI-WORKSPACE-TABS should be of type SICK-DAVINCI-WORKSPACE. Otherwise tabs may not work or' +
          ' display as expected!');
      }
    }
    if (this.tabList.length !== this.tabLabels.length) {
      console.error('DavinciWorkspaceTabsComponent :: number of labels and tabs is not matching!');
    }

    this.tabList[this.activeTabIndex].classList.add('active');
  }

  changeTab(tabIndex: number) {
    this.tabList[this.activeTabIndex].classList.remove('active');
    this.activeTabIndex = tabIndex;
    this.tabList[this.activeTabIndex].classList.add('active');
  }
}
