<div class="backdrop" *ngIf="open" (mousedown)="close($event)">
  <mat-card class="dialog-container" (mousedown)="$event.stopPropagation()">
    <div class="dialog-header">
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="dialog-body">
      <ng-content select="[body]"></ng-content>
    </div>
    <div class="dialog-footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </mat-card>
</div>
