interface GroupLoggerProps {
  name: string;
  color: string;
  backgroundColor: string;
  symbol: string;
}

export function groupLogger(props: GroupLoggerProps) {
  const { name, color, backgroundColor, symbol } = props;

  const logStart = (tag = '', symb?: string) => {
    console.groupCollapsed(
      `${symb ? symb : symbol} %c [${name}] ${tag}`.trim(),
      `color: ${color}; background:${backgroundColor}; padding:2px`
    );
  };

  const logEnd = console.groupEnd.bind(console);

  const log = (tag: string, ...args: any) => {
    logStart(tag);
    console.log(...args);
    logEnd();
  };

  const logError = (tag: string, ...args: any) => {
    logStart('', '🚨');
    console.error(...args);
    logEnd();
  };

  return { logStart, logEnd, log, logError };
}
