import {
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import {
  NavigationEnd,
  Router
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { DavinciCoreService } from '../../davinci-core.service';
import { DavinciCoreModel } from '../../davinci-core.model';

@Component({
  selector: 'sick-davinci-toolbar-menu',
  templateUrl: './davinci-toolbar-menu.component.html',
  styleUrls: ['./davinci-toolbar-menu.component.scss']
})
export class DavinciToolbarMenuComponent implements OnInit, OnChanges {
  isVisible = false;
  menuButton: DavinciCoreModel.ToolbarButton = {
    iconClass: 'more_vert',
    callback: (event: Event) => this.toggleMenu(event)
  };

  @Input()
  menu: DavinciCoreModel.ToolbarMenuItem[] = [];

  constructor(private davinciCore: DavinciCoreService, private router: Router) {}

  ngOnInit() {
    window.addEventListener('click', () => {
      this.isVisible = false;
    });

    this.davinciCore.menuCloser$.subscribe(issuer => {
      if (issuer !== this) {
        this.isVisible = false;
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this.isVisible = false);
  }

  ngOnChanges() {
    this.isVisible = false;
  }

  toggleMenu(event: Event) {
    event.stopPropagation();
    this.davinciCore.paneCloser$.next();
    this.davinciCore.menuCloser$.next(this);
    this.isVisible = !this.isVisible;
  }
}
