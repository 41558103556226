import { Component, Input, OnChanges, ElementRef } from '@angular/core';
import { DavinciCoreModel } from '../../davinci-core.model';

@Component({
  selector: 'sick-davinci-toolbar-button',
  templateUrl: './davinci-toolbar-button.component.html',
  styleUrls: ['./davinci-toolbar-button.component.scss']
})
export class DavinciToolbarButtonComponent implements OnChanges {
  @Input()
  button: DavinciCoreModel.ToolbarButton;

  constructor(private elRef: ElementRef) {
  }

  ngOnChanges() {
    const isHeaderButton = this.elRef.nativeElement.classList.contains('header');
    if (isHeaderButton && this.button && !this.button.title) {
      this.button.title = this.button.label;
    }
  }
}
