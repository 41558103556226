import {
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { DavinciCoreTasksService } from '../davinci-core-tasks.service';
import { DavinciCoreModel } from '../davinci-core.model';
import { DavinciCoreService } from '../davinci-core.service';

@Component({
  selector: 'sick-davinci-header',
  templateUrl: './davinci-header.component.html',
  styleUrls: ['./davinci-header.component.scss']
})
export class DavinciHeaderComponent implements OnChanges, OnInit {
  @Input()
  toolbarConfig: DavinciCoreModel.Toolbar = {
    buttons: null,
    menu: null,
    type: 'header',
  };

  @Input()
  customBranding: DavinciCoreModel.CustomHeaderBranding | null = null;

  navigation: DavinciCoreModel.Navigation;
  navigationPane: DavinciCoreModel.NavigationPane;
  hasNavigation = false;

  constructor(
    private core: DavinciCoreService,
    private router: Router,
  ) {
  }

  ngOnChanges(changes) {
    if (this.customBranding && this.customBranding.text) {
      this.customBranding.text = this.customBranding.text.substr(0, 32);
    }
  }

  ngOnInit() {
    this.core.navigation$.subscribe(
      (navigation: DavinciCoreModel.Navigation) => {
        this.navigation = navigation;
        this.hasNavigation = navigation.tree.length > 0;
      }
    );

    this.core.navigationPane$.subscribe(
      (navigationPane: DavinciCoreModel.NavigationPane) => this.navigationPane = navigationPane
    );
  }

  goHome() {
    this.router.navigateByUrl('/');
  }

  toggleNavigationPane(event: Event) {
    event.stopPropagation();
    const pane = this.core.navigationPane$.getValue() || DavinciCoreModel.defaultNavigationPane;
    this.core.menuCloser$.next();
    this.core.navigationPane$.next({
      ...pane,
      isVisible: pane.isSticky ? false : !pane.isVisible,
      isSticky: false,
    });
  }
}
