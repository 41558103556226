import { Injectable } from '@angular/core';
import { OAuthService, JwksValidationHandler, AuthConfig } from 'angular-oauth2-oidc';
import { TranslateService } from '@ngx-translate/core';
// import { NotificationService } from './shared/services/notificationService';
import { environment } from '../../environments/environment';
// import { LicensingService } from './shared/services/licensingService';
import { OAuthServiceMock } from '../../tests/OAuthServiceMock';

@Injectable()
export class AppInitializerService {
  public hasPermissionToUseApp = false;
  public hasBearerToken = false;

  constructor(private oauthService: OAuthService, private translate: TranslateService) {
    if (environment.activeProfile === 'local' && environment.oidc.issuer.indexOf('http') < 0) {
      // skip authentication against SICK ID
      this.oauthService = (<unknown>new OAuthServiceMock()) as OAuthService;
    }
  }

  public async initialize(): Promise<void> {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');

    // Trigger loading translations
    this.translate.use('en').subscribe((res) => {});

    // ... then setup oidc and try to login
    const oidcDefaults: AuthConfig = {
      redirectUri: window.location.origin + '/index.html',
      responseType: 'code',
      showDebugInformation: true,
    };

    const oidcConfig = Object.assign(oidcDefaults, environment.oidc);
    console.log('OIDC Conig:' + JSON.stringify(oidcConfig));
    this.oauthService.configure(oidcConfig);
    this.oauthService.events.subscribe((event) => console.log('Auth event "%s": %o', event.type, event));

    this.oauthService.setupAutomaticSilentRefresh();

    if (!this.oauthService.hasValidIdToken()) {
      const result = await this.oauthService.loadDiscoveryDocumentAndLogin();
      if (result) {
        this.hasBearerToken = true;
      } else {
        console.error('failed to get bearer');
        this.hasBearerToken = false;
      }
    } else {
      const result = await this.oauthService.loadDiscoveryDocument();
      if (result) {
        this.hasBearerToken = true;
      }
    }
  }
}
