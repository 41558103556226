import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UncertaintyInputData } from '../../model/model';
import { convertDataModel2ViewModel, convertViewModel2DataModel } from '../../utils/convert';
import { urlEncodeQuery } from '../../utils/urlEncoder';
import { log } from './log';
import {
  alpha,
  checkgas,
  customMolWeight,
  customSoS,
  gasComposition,
  kappa,
  limitsVector,
  pressures,
  temperatures,
  vogs,
} from './query-builder';
import { isClassicDevice } from 'src/app/utils/isClassicDevice';

type MeasureMat = number[][] | number; // number im Fehlerfall
interface SolutionFinderResponse {
  result: MeasureMat;
}

function alarmCustomSolution(measure_mat: MeasureMat) {
  return measure_mat[1][0] === 1 || measure_mat[2][0] === 1 || measure_mat[3][1] === 1;
}

function alarmSolution(measure_mat: MeasureMat) {
  return measure_mat[0][0] === 99;
}

// GET /octave/SolutionFinder
//
export const solutionFinderHandler = (http: HttpClient) => (input: UncertaintyInputData) => {
  const data = convertViewModel2DataModel(input);

  const queryObj = {
    pressures: pressures(data),
    temperatures: temperatures(data),
    customSos: customSoS(data),
    customMolWeight: customMolWeight(data),
    kappa: kappa(data),
    checkgas: checkgas(data),
    vogs: vogs(data),
    gasComposition: gasComposition(data),
    installType: data.installType,
    deviceType: data.deviceType,
    diameter: data.diameter,
    paths: data.pathConfiguration,
    alpha: alpha(data),
    limitsVector: limitsVector(data),
  };

  const query = urlEncodeQuery(queryObj);
  const url = environment.apiUrl + '/octave/SolutionFinder?' + query;

  return http.get<SolutionFinderResponse>(url).pipe(
    tap((res) => log(url, queryObj, res)),
    map((res) => res.result),
    map((measure_mat) => {
      // added: new devices are NP checked using new NP box
      if (!Array.isArray(measure_mat) || measure_mat[0][0] === 99) {
        data.solutions.m2a = false;
        data.solutions.m3a = false;
        data.solutions.m3b = false;
        data.solutions.m3c = false;
        data.solutions.m4a = false;
        data.solutions.m4b = false;
        data.solutions.deviceTypeM3a = 0;
        data.solutions.deviceTypeM3b = 0;
        data.solutions.angleTypeM3b = 0;
        data.solutions.deviceTypeM3c = 0;
        data.alarmMarker.solution = alarmSolution(measure_mat);
        return convertDataModel2ViewModel(data, input.unitSystem);
      }
      data.solutions.m2a = measure_mat[0][0] === 1 || !isClassicDevice(Number(queryObj.deviceType));
      data.solutions.m3a = measure_mat[1][0] === 1;
      data.solutions.m3b = measure_mat[2][0] === 1;
      data.solutions.m4a = measure_mat[3][0] === 1;
      data.solutions.m4b = measure_mat[3][1] === 1;

      data.solutions.deviceTypeM3a = measure_mat[1][1];
      data.solutions.deviceTypeM3b = measure_mat[2][1];
      data.solutions.angleTypeM3b = measure_mat[2][2];
      data.solutions.deviceTypeM3c = measure_mat[1][1];

      // Device Type M3a: [1,5,6,8] --> M3a
      // Device Type M3a: [1,7] --> M3c
      if (measure_mat[1][0] === 1) {
        data.solutions.m3a = [1, 5, 6, 8].includes(data.solutions.deviceTypeM3a);
        data.solutions.m3c = [1, 7].includes(data.solutions.deviceTypeM3a);
      }

      data.alarmMarker.customSolution = alarmCustomSolution(measure_mat);

      return convertDataModel2ViewModel(data, input.unitSystem);
    })
  );
};
