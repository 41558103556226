<app-dialog [open]="open" (dismiss)="emitCancel()">
  <div header>
    <h2>
      Gas Composition
    </h2>
  </div>

  <div body>
    <form [formGroup]="form">
      <div class="body" formGroupName="gasComposition">
        <div class="grid">
          <div class="col1">
            <div class="gas">Methane</div>
            <div class="formula">CH<sub>4</sub></div>
          </div>

          <div class="col2">
            <davinci-text-field formControlName="methan" [error]="hasError('methan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">
              Nitrogen
            </div>
            <div class="formula">N<sub>2</sub></div>
          </div>

          <div class="col2">
            <davinci-text-field formControlName="nitrogen" [error]="hasError('nitrogen')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">
              Carbon dioxide
            </div>
            <div class="formula">CO<sub>2</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="carbondioxid" [error]="hasError('carbondioxid')">
              <span class="unit">{{ units.molFraction }}</span>
            </davinci-text-field>
          </div>

          <div class="col1">
            <div class="gas">
              Ethane
            </div>
            <div class="formula">C<sub>2</sub>H<sub>6</sub></div>
          </div>

          <div class="col2">
            <davinci-text-field formControlName="ethan" [error]="hasError('ethan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">
              Propane
            </div>
            <div class="formula">C<sub>3</sub>H<sub>8</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="propan" [error]="hasError('propan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas"><i>n</i>-Butane</div>
            <div class="formula"><i>n</i>-C<sub>4</sub>H<sub>10</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="nButhan" [error]="hasError('nButhan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">
              i-Butane
            </div>
            <div class="formula"><i>i</i>-C<sub>4</sub>H<sub>10</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="iButhan" [error]="hasError('iButhan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">
              n-Pentane
            </div>
            <div class="formula"><i>n</i>-C<sub>5</sub>H<sub>12</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="nPenthan" [error]="hasError('nPenthan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">
              i-Pentane
            </div>
            <div class="formula"><i>i</i>-C<sub>5</sub>H<sub>12</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="iPenthan" [error]="hasError('iPenthan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">
              n-Hexane
            </div>
            <div class="formula"><i>n</i>-C<sub>6</sub>H<sub>14</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="nHexan" [error]="hasError('nHexan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>
        </div>

        <div class="grid">
          <div class="col1">
            <div class="gas">
              n-Heptane
            </div>
            <div class="formula"><i>n</i>-C<sub>7</sub>H<sub>16</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="nHepthan" [error]="hasError('nHepthan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">n-Octane</div>
            <div class="formula"><i>n</i>-C<sub>8</sub>H<sub>18</sub></div>
          </div>

          <div class="col2">
            <davinci-text-field formControlName="nOcthan" [error]="hasError('nOcthan')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">n-Nonane</div>
            <div class="formula"><i>n</i>-C<sub>9</sub>H<sub>20</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="nNonane" [error]="hasError('nNonane')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">n-Decane</div>
            <div class="formula"><i>n</i>-C<sub>10</sub>H<sub>22</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="nDecane" [error]="hasError('nDecane')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">Hydrogen</div>
            <div class="formula">H<sub>2</sub></div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="hydrogen" [error]="hasError('hydrogen')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">Oxygen</div>
            <div class="formula">O2</div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="oxygen" [error]="hasError('oxygen')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">Carbon monoxide</div>
            <div class="formula">CO</div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="carbonMonoxid" [error]="hasError('carbonMonoxid')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">Water</div>
            <div class="formula">H<sub>2</sub>O</div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="water" [error]="hasError('water')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">Hydrogen sulfide</div>
            <div class="formula">H<sub>2</sub>S</div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="hydrogenSulfid" [error]="hasError('hydrogenSulfid')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">Helium</div>
            <div class="formula">He</div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="helium" [error]="hasError('helium')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>

          <div class="col1">
            <div class="gas">Argon</div>
            <div class="formula">Ar</div>
          </div>
          <div class="col2">
            <davinci-text-field formControlName="argon" [error]="hasError('argon')"
              ><span class="unit">{{ units.molFraction }}</span></davinci-text-field
            >
          </div>
        </div>
      </div>
    </form>
  </div>

  <div footer>
    <div class="row no-margin-bottom">
      <div class="col-1-to-2">
        <div class="sum" [ngClass]="{ perfect: !gasFormInvalid }" *ngIf="!errorLabel">
          Composition sum: {{ sum * 100 | number: '1.4' }}
        </div>
        <div class="errorLabel" *ngIf="errorLabel">{{ errorLabel }}</div>
      </div>
      <!-- Cancel -->
      <davinci-button class="col-2" type="outline" (click)="emitCancel()">
        Cancel
      </davinci-button>

      <!-- Normalize -->
      <davinci-button class="col-3" type="outline" (click)="normalize()">
        Normalize
      </davinci-button>

      <!-- Ok button -->
      <davinci-button class="col-4" (click)="emitOk()" [ngClass]="{ disabled: sum !== 1 }">
        Ok
      </davinci-button>
    </div>
  </div>
</app-dialog>
