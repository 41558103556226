import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RANGES } from '../model/consts';
import { UncertaintyInputData } from '../model/model';
import { UserService } from './user.service';
import { numberParser } from '../utils/numberParser';
import { converters } from '../utils/convert';

const parseNumber = numberParser('en-US');

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private userService: UserService) {}

  get isExpert() {
    return this.userService.isGbc04expert;
  }

  form = (data: UncertaintyInputData): FormGroup => {
    const self = this;
    const fb = new FormBuilder();

    // Helper function to get the unit system and converters from the form data
    function getConverters(control: AbstractControl) {
      const unitSystemControl = control.root.get('unitSystem');
      if (unitSystemControl) {
        return converters(unitSystemControl.value || 'metric', 'metric');
      }
      return converters('metric', 'metric');
    }

    // Whether a min/norm/max process data block is active
    function isActive(control: AbstractControl) {
      try {
        return control.parent.get('active').value;
      } catch (e) {}
      return false;
    }

    function validateTemperature(control: AbstractControl) {
      if (!isActive(control)) {
        return null;
      }
      const { from, to } = RANGES.temperature;
      const { temperature } = getConverters(control);
      const value = parseNumber(control.value);
      const valueInC = temperature(value);
      const isValid = valueInC >= from && valueInC <= to;
      return isValid ? null : { temperature: false };
    }

    function validatePressure(control: AbstractControl) {
      if (!isActive(control)) {
        return null;
      }
      const { from, to } = RANGES.pressure;
      const { pressure } = getConverters(control);
      const value = pressure(parseNumber(control.value));
      const isValid = value >= from && value <= to;
      return isValid ? null : { pressure: false };
    }

    function validateSoS(control: AbstractControl) {
      if (self.isExpert) {
        return null;
      }

      if (!isActive(control)) {
        return null;
      }
      const { from, to } = RANGES.sos;
      const { speed } = getConverters(control);
      const value = speed(parseNumber(control.value));
      const isValid = value >= from && value <= to;
      return isValid ? null : { speed: false };
    }

    function isNumber(control: AbstractControl) {
      return parseNumber(control.value) === NaN ? { isNaN: true } : null;
    }

    function validateDeviceType(control: AbstractControl) {
      // See https://deagxjira.sickcn.net/jira/browse/GBC04ADSW-131
      // FlareV3: Frontend - Device Type = Ex 42 kHz for Type = Installed base only
      const projectType = control.root.get('projectType');
      const deviceType = control.root.get('deviceType');

      if (!projectType) {
        return null;
      }

      if (projectType.value === 'projectTypeQuoting' && control.value === '3') {
        return { GBC04ADSW131: false };
      }
      return null;
    }

    // If we activate/deactivate a process block (min/norm/max) or the speed uncertainty block,
    // we have to re-validate some parts of the from. However Angular does not make that easy: we have to
    // manual "touch" the related controls. TODO: is there a better way?
    function revalidate(...keys: string[]) {
      return function (control: AbstractControl) {
        try {
          const parent = control.parent;
          keys.forEach((key) => {
            parent.get(key).updateValueAndValidity();
          });
        } catch (e) {}
        return null;
      };
    }

    const revalidateProcessData = revalidate('pressure', 'temperature', 'speed');

    const { min, max, required } = Validators;

    const formGroup = fb.group({
      projectName: [data.projectName, required],
      projectType: [data.projectType],
      reference: [data.reference],
      tag: [data.tag, required],
      poNumber: [data.poNumber],
      partNumber: [data.partNumber],
      serialNumber: [data.serialNumber],
      diameter: [data.diameter, required],
      nozzleLength: [data.nozzleLength],
      deviceType: [data.deviceType, [validateDeviceType, required]],
      pathConfiguration: [data.pathConfiguration, required],
      installType: [data.installType, required],
      speedOfSoundMethod: [data.speedOfSoundMethod, required],
      exZone: [{ value: data.exZone, disabled: true }, required],
      explanation: [data.explanation],
      min: fb.group({
        active: [data.min.active, revalidateProcessData],
        pressure: [data.min.pressure, [isNumber, validatePressure]],
        temperature: [data.min.temperature, [isNumber, validateTemperature]],
        speed: [data.min.speed, [isNumber, validateSoS]],
        agc: [data.min.agc],
      }),
      norm: fb.group({
        active: [data.norm.active, revalidateProcessData],
        pressure: [data.norm.pressure, [isNumber, validatePressure]],
        temperature: [data.norm.temperature, [isNumber, validateTemperature]],
        speed: [data.norm.speed, [isNumber, validateSoS]],
        agc: [data.norm.agc],
      }),
      max: fb.group({
        active: [data.max.active, revalidateProcessData],
        pressure: [data.max.pressure, [isNumber, validatePressure]],
        temperature: [data.max.temperature, [isNumber, validateTemperature]],
        speed: [data.max.speed, validateSoS],
        agc: [data.max.agc],
      }),
      speedUncertaintyPercent: [data.speedUncertaintyPercent, [required, min(0), max(100)]],
      speedUncertaintyMax: [data.speedUncertaintyMax, [required]],
      speedUncertaintyAbove: [data.speedUncertaintyAbove, [required]],

      unitSystem: [data.unitSystem],

      activeSoundCorrelation: [data.activeSoundCorrelation],
      envelope: [data.envelope],
      m0deviation: [data.m0deviation],

      gasComposition: fb.group({
        argon: [data.gasComposition.argon, [required, min(0), max(100)]],
        carbondioxid: [data.gasComposition.carbondioxid, [required, min(0), max(100)]],
        carbonMonoxid: [data.gasComposition.carbonMonoxid, [required, min(0), max(100)]],
        ethan: [data.gasComposition.ethan, [required, min(0), max(100)]],
        helium: [data.gasComposition.helium, [required, min(0), max(100)]],
        hydrogen: [data.gasComposition.hydrogen, [required, min(0), max(100)]],
        hydrogenSulfid: [data.gasComposition.hydrogenSulfid, [required, min(0), max(100)]],
        iButhan: [data.gasComposition.iButhan, [required, min(0), max(100)]],
        nButhan: [data.gasComposition.nButhan, [required, min(0), max(100)]],
        iPenthan: [data.gasComposition.iPenthan, [required, min(0), max(100)]],
        methan: [data.gasComposition.methan, [required, min(0), max(100)]],
        nDecane: [data.gasComposition.nDecane, [required, min(0), max(100)]],
        nHepthan: [data.gasComposition.nHepthan, [required, min(0), max(100)]],
        nHexan: [data.gasComposition.nHexan, [required, min(0), max(100)]],
        nitrogen: [data.gasComposition.nitrogen, [required, min(0), max(100)]],
        nNonane: [data.gasComposition.nNonane, [required, min(0), max(100)]],
        nOcthan: [data.gasComposition.nOcthan, [required, min(0), max(100)]],
        nPenthan: [data.gasComposition.nPenthan, [required, min(0), max(100)]],
        oxygen: [data.gasComposition.oxygen, [required, min(0), max(100)]],
        propan: [data.gasComposition.propan, [required, min(0), max(100)]],
        water: [data.gasComposition.water, [required, min(0), max(100)]],
      }),

      molWeightAndKappa: fb.group({
        minMolWeight: [data.molWeightAndKappa.minMolWeight, required],
        normMolWeight: [data.molWeightAndKappa.normMolWeight, required],
        maxMolWeight: [data.molWeightAndKappa.maxMolWeight, required],
        minIsentropic: [data.molWeightAndKappa.minIsentropic, required],
        normIsentropic: [data.molWeightAndKappa.normIsentropic, required],
        maxIsentropic: [data.molWeightAndKappa.maxIsentropic, required],
      }),

      vogVector: fb.group({
        // TODO: min/max - those are just my educated guess?
        velo1: [data.vogVector.velo1, [required, min(0), max(90000)]],
        velo2: [data.vogVector.velo2, [required, min(0), max(90000)]],
        velo3: [data.vogVector.velo3, [required, min(0), max(90000)]],
        velo4: [data.vogVector.velo4, [required, min(0), max(90000)]],
        velo5: [data.vogVector.velo5, [required, min(0), max(90000)]],
        velo6: [data.vogVector.velo6, [required, min(0), max(90000)]],
      }),

      extendedData: fb.group({
        useCustomAngle: [data.extendedData.useCustomAngle, [isNumber]],
        customAngleValue: [data.extendedData.customAngleValue, [isNumber]],
        additionalPathAngle: [data.extendedData.additionalPathAngle, [isNumber]],
        useUnpairedProbesTime: [data.extendedData.useUnpairedProbesTime, [isNumber]],
        unpairedProbesTimeDiff: [data.extendedData.unpairedProbesTimeDiff, [isNumber]],
        useCustomZeroPointUncertainty: [data.extendedData.useCustomZeroPointUncertainty, [isNumber]],
        customZeroPointUncertainty: [data.extendedData.customZeroPointUncertainty, [isNumber]],
      }),

      solutions: fb.group({
        m1: [{ value: data.solutions.m1, disabled: true }],
        m2a: [data.solutions.m2a],
        m3a: [data.solutions.m3a],
        m3b: [data.solutions.m3b],
        m3c: [data.solutions.m3c],
        m4a: [data.solutions.m4a],
        m4b: [data.solutions.m4b],
        deviceTypeM3a: [data.solutions.deviceTypeM3a],
        deviceTypeM3b: [data.solutions.deviceTypeM3b],
        angleTypeM3b: [data.solutions.angleTypeM3b],
        deviceTypeM3c: [data.solutions.deviceTypeM3c],
        vMinLimit1: [data.solutions.vMinLimit1],
        vMinLimit2: [data.solutions.vMinLimit2],
        vMinLimit3: [data.solutions.vMinLimit3],
        vMinLimit4: [data.solutions.vMinLimit4],
      }),

      parameters: fb.array(
        data.parameters.map((row) => {
          const entries = row.map((entry) => fb.group({ entry: [entry.entry, required] }));
          return fb.array(entries);
        })
      ),
    });

    return formGroup;
  }
}
