import { Injectable } from '@angular/core';
import * as Converter from 'js-quantities';
import {
  PhysicalQuantity,
  UncertaintyInputData,
  CalculatedParams,
  ResultRow,
  UnitSystemKey,
  UNITS,
} from '../model/model';

@Injectable({
  providedIn: 'root',
})
export class ConverterService {
  currentUnitSystem: UnitSystemKey;

  convertPreParameter(
    data: CalculatedParams,
    fromUnitSystem: UnitSystemKey,
    toUnitSystem: UnitSystemKey
  ): CalculatedParams {
    const result = {};
    const convert = (value: number, physicalQuantity: PhysicalQuantity): number => {
      return this.convert(value, physicalQuantity, fromUnitSystem, toUnitSystem);
    };
    ['min', 'norm', 'max'].forEach((type) => {
      if (data[type]) {
        result[type] = {
          molWeight: data[type].molWeight,
          maxVelocity: convert(data[type].maxVelocity, 'speed'),
          maxFlowRate: convert(data[type].maxFlowRate, 'flowRate'),
        };
      }
    });
    return result;
  }

  convertResultRows(data: ResultRow[], fromUnitSystem: UnitSystemKey, toUnitSystem: UnitSystemKey): ResultRow[] {
    return data.map((row) => {
      const vog = this.convert(row.vog, 'speed', fromUnitSystem, toUnitSystem);
      const flowRate = this.convert(row.flowRate, 'flowRate', fromUnitSystem, toUnitSystem);
      return {
        vog,
        flowRate,
        uncertainty: row.uncertainty,
        m0deviation: row.m0deviation,
      };
    });
  }

  convert(
    value: number | string,
    physicalQuantity: PhysicalQuantity,
    fromUnitSystem: string,
    toUnitSystem: string
  ): number;
  convert(value, physicalQuantity, fromUnitSystem, toUnitSystem) {
    if (!value || isNaN(Number(value))) {
      return value;
    }
    console.log(
      physicalQuantity,
      value,
      UNITS[fromUnitSystem][physicalQuantity],
      UNITS[toUnitSystem][physicalQuantity]
    );
    const c = new Converter(Number(value), UNITS[fromUnitSystem][physicalQuantity]);
    const res = parseFloat(c.to(UNITS[toUnitSystem][physicalQuantity]).scalar.toFixed(2));
    console.log(res);
    return res;
  }

  convertRanges(ranges, fromUnitSystem: UnitSystemKey, toUnitSystem: UnitSystemKey) {
    Object.keys(ranges).forEach((key: PhysicalQuantity) => {
      ranges[key] = {
        from: this.convert(ranges[key].from, key, fromUnitSystem, toUnitSystem),
        to: this.convert(ranges[key].to, key, fromUnitSystem, toUnitSystem),
      };
    });
  }
}
