import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { DavinciCoreService } from '@sick-davinci/core-ng';
import jsonDiff from 'json-diff';
import { Layout, ScatterData } from 'plotly.js';
import * as Plotly from 'plotly.js/dist/plotly-basic.js';
import { debounceTime, distinctUntilChanged, filter, map, skip, take, first, tap } from 'rxjs/operators';
import { DEVICE_LIST, RANGES, NOMINAL_SIZES } from 'src/app/model/consts';
import { defaultUncertaintyInputData } from 'src/app/model/data';
import {
  InputNumValue,
  ParameterMatrix,
  UncertaintyInputData,
  unitLabels,
  UnitSystemKey,
  UnitSystemLabels,
  UncertaintyData,
} from 'src/app/model/model';
import { PdfService, PersistenceService } from 'src/app/services/';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
import { OctaveService } from 'src/app/services/octave.service';
import { FlowsicLogEntry } from 'src/app/services/persistence.service';
import { SosService } from 'src/app/services/sos.service';
import { UserService } from 'src/app/services/user.service';
import { clone } from 'src/app/utils/clone';
import {
  convertDataModel2ViewModel,
  convertViewModel2DataModel,
  convertViewModelToViewModel,
} from 'src/app/utils/convert';
import { numberParser } from 'src/app/utils/numberParser';
import { FormService } from 'src/app/services/form.service';
import { patchEnvelopeValue } from 'src/app/utils/patchEnvelope';
import { patchEnvelopeSupportPoints } from 'src/app/utils/patchEnvelope';
import { environment } from '../../../environments/environment';
import { formInputFixture } from '../../../tests/fixtures';
import { ResultRow } from '../../model/model';
import { isDeviceSelectionValid } from '../../utils/isDeviceSelectionValid';
import { tagResults } from '../../utils/tagResults';
import { uuid } from '../../utils/uuid';

const localeNumberUs = numberParser('en-US');
const num = (input: any = 0) => localeNumberUs(input);

const defaultChartData: {
  data: Array<Partial<ScatterData>>;
  layout: Partial<Layout>;
} = {
  data: [],
  layout: {
    width: 800,
    height: 600,
    title: {
      title: 'Chart',
    },
    xaxis: {
      title: 'VoG [m/s]',
      type: 'log',
      showexponent: 'all',
    },
    yaxis: {
      title: 'Uncertainty [%]',
      range: [0, 25],
    },
    legend: {
      x: 1,
      xanchor: 'right',
      y: 1,
      yanchor: 'top ',
    },
  },
};

const defaultUser = {
  name: 'anonymous',
  id: '',
};

const shortDebounceTime = 10;
const defaultDebounceTime = 100;

@Component({
  selector: 'app-flare-frontend-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  activeProfile = environment.activeProfile; // 'development';
  chartData_deviation = defaultChartData;
  customerProvidedUncertaintyError = false;
  email: string;
  errorMessage = '';
  persistenceAvailable = false; // gets injected during mount of component
  showBusyIndicator = false;
  // GBC04ADSW-267: process values are converted at project startup
  // keep the result state in a local variable and use this one instead of heavyweight
  // converter-bound operation - variable is set only on project load and result calculation
  hasResultsAvailable = false;
  showValidation = false;
  unitSystem: UnitSystemKey = 'imperial';
  user = defaultUser;
  form: FormGroup = this.formService.form(this.data);
  parameterMatrix: ParameterMatrix<InputNumValue> = this.data.parameters;

  private chartElId_deviation = 'plotly_container_deviation';
  private chartElId_m0min = 'plotly_container_m0min';
  private chartElId_m0norm = 'plotly_container_m0norm';
  private chartElId_m0max = 'plotly_container_m0max';

  get ranges() {
    return RANGES;
  }

  get deviceList() {
    return DEVICE_LIST;
  }

  get showExpertForm() {
    return this.userService.isGbc04expert;
  }

  get showM0DeviationForm() {
    // show M0Deviation form control only in case of user rights and non Projectype Quoting (GBC04ADSW-153)
    return this.userService.isM0deviation && this.data.projectType !== 'projectTypeQuoting';
  }

  get isGeReplacementMode() {
    return ['9'].includes(this.data.deviceType);
  }

  get showM0Deviation() {
    return this.data.m0deviation && this.showM0DeviationForm;
  }

  get allowInstallBased() {
    return ['1', '2', '3', '4'].includes(this.data.deviceType);
  }

  nominalSizes = NOMINAL_SIZES;

  setNominalSizes() {
    const deviceType = Number(this.data.deviceType);
    const numberOfPaths = Number(this.data.pathConfiguration);
    this.nominalSizes = NOMINAL_SIZES.filter((nominalSize) => {
      return isDeviceSelectionValid(deviceType, numberOfPaths, nominalSize, this.userService);
    });
  }

  get allowTwoPathSetup() {
    // exclusion list
    return !['9'].includes(this.data.deviceType);
  }

  get units(): UnitSystemLabels {
    return unitLabels[this.unitSystem];
  }

  get disableCalculateButton() {
    const data = this.form.getRawValue() as UncertaintyInputData;
    const valid = this.formIsValid && (data.min.active || data.norm.active || data.max.active);
    return !valid;
  }

  get disableDefineGasCompositionButton() {
    const isValid = (group: keyof UncertaintyInputData) =>
      !this.form.get(group).get('pressure').invalid && !this.form.get(group).get('temperature').invalid;

    const minIsValid = this.data.min.active ? isValid('min') : true;
    const normIsValid = this.data.norm.active ? isValid('norm') : true;
    const maxIsValid = this.data.max.active ? isValid('max') : true;
    const oneIsActive = this.data.min.active || this.data.norm.active || this.data.max.active;

    const ret = minIsValid && normIsValid && maxIsValid && oneIsActive;
    return !ret;
  }

  get formIsValid() {
    type ControlNames = keyof UncertaintyInputData;
    const relevant: ControlNames[] = [
      'activeSoundCorrelation',
      'deviceType',
      'diameter',
      'nozzleLength',
      'envelope',
      'extendedData',
      'gasComposition',
      'installType',
      'm0deviation',
      'max',
      'min',
      'molWeightAndKappa',
      'norm',
      'pathConfiguration',
      'projectName',
      'projectType',
      'speedUncertaintyAbove',
      'speedUncertaintyMax',
      'speedUncertaintyPercent',
      'vogVector',
    ];

    const someAreInvalid = relevant.map((name) => this.form.get(name).invalid).some((invalid) => invalid === true);
    return !someAreInvalid;
  }

  get showResults() {
    return this.hasResultsAvailable;
  }

  get showDebug() {
    return environment.activeProfile !== 'production';
  }

  constructor(
    private dataService: DataService,
    private formService: FormService,
    private pdfService: PdfService,
    private persistenceService: PersistenceService,
    private router: Router,
    public coreService: DavinciCoreService,
    public modalService: ModalService,
    public octaveService: OctaveService,
    public sosService: SosService,
    public userService: UserService
  ) {}

  async ngOnInit() {
    this.userService.fetchUserRecord().subscribe((res) => {});
    this.initForm();
    await this.initializeParameters();
    await this.initBackendVersion();
    this.renderChart();
  }

  goToProjects() {
    this.router.navigate(['/projects']);
  }

  async initBackendVersion() {
    try {
      const backendVersion = await this.octaveService.version().toPromise();
      this.data.backendVersion = backendVersion;
      const nextData = clone(this.data);
      nextData.backendVersion = backendVersion;
      this.patchFormData(nextData);
      formInputFixture.backendVersion = backendVersion;
    } catch (e) {
      console.error('Error initBackendVersion:', e);
    }
  }

  // Fetch the latest parameters and initialize the default data and the form
  async initializeParameters() {
    try {
      const nextData = await this.octaveService.defaultParameterMatrix(this.data).toPromise();
      formInputFixture.parameters = nextData.parameters;
      this.patchFormData(nextData);
    } catch (e) {
      console.log('Error initializeParameters:', e);
      this.octaveErrorHandler(e.error);
    }
  }

  evaluateResultsAvailable() {
    if (!this.data.results.calculatedParams) {
      return false;
    }
    // if we have data in error message stack - hide all results too - see GBC04ADSW-168 for details
    if (this.data.alarmMarker.genericMessages === undefined || this.data.alarmMarker.genericMessages.length > 0) {
      if (!this.userService.isGbc04expert) {
        return false;
      }
    }
    const normalizedData = convertViewModel2DataModel(this.data);
    const tag = tagResults(normalizedData);
    return tag === this.data.results.md5;
  }

  initForm() {
    this.enableOrDisableExpertOnlyControls();
    const formChanged$ = this.form.valueChanges;

    this.userService.userRecord$
      .pipe(
        skip(1), // ignore the initial, default message
        filter((userServiceMsg) => userServiceMsg.state === 'ready')
      )
      .subscribe(() => this.enableOrDisableProjectTypeDropDown());

    formChanged$
      .pipe(
        debounceTime(shortDebounceTime),
        distinctUntilChanged((oldData, newData) => JSON.stringify(oldData) === JSON.stringify(newData))
      )
      .subscribe((newData: UncertaintyInputData) => {
        // See https://deagxjira.sickcn.net/jira/browse/GBC04ADSW-131
        // FlareV3: Frontend - Device Type = Ex 42 kHz for Type = Installed base only
        if (newData.projectType === 'projectTypeQuoting' && newData.deviceType === '3') {
          newData.deviceType = null;
          this.form.patchValue({ deviceType: null });
        }
        if (newData.deviceType === '9') {
          newData.m0deviation = false;
          // newData.activeSoundCorrelation = false;
          newData.projectType = 'projectTypeQuoting';
          newData.pathConfiguration = '1';
          newData.nozzleLength = newData.nozzleLength === undefined ? '1' : newData.nozzleLength;
          // visually switch form data without event loop, since it would launch infinitely
          this.form.patchValue({ projectType: 'projectTypeQuoting', pathConfiguration: '1' }, { emitEvent: false });
        }
        this.data = newData;
        this.hasResultsAvailable = this.evaluateResultsAvailable();
        this.errorMessage = '';
        this.setNominalSizes();
      });

    // Handle unit changes
    formChanged$
      .pipe(
        debounceTime(defaultDebounceTime),
        map((formData: UncertaintyInputData) => formData.unitSystem),
        filter((formUnitSystem) => formUnitSystem !== this.unitSystem)
      )
      .subscribe(() => this.onUnitChange());
  }

  loadFixture() {
    const testData = clone(formInputFixture);
    testData.id = uuid();
    this.unitSystem = 'imperial';
    const imperialTestData = convertViewModelToViewModel({ input: testData, from: 'metric', to: 'imperial' });
    this.updateDataAndChart(imperialTestData);
  }

  saveResult() {
    if (!this.data.projectName || this.data.projectName.length < 1) {
      this.errorMessage = 'Please enter a project name before saving!';
      return;
    }
    if (this.persistenceAvailable) {
      const normalizedDataModel = convertViewModel2DataModel(this.data);
      this.persistenceService.saveUserData(normalizedDataModel, this.data.unitSystem).then(
        () => confirm('Saved project as ' + normalizedDataModel.projectName),
        (err) => console.log('save data: err', err)
      );
    }
  }

  formatNumber(path) {
    const formControl: AbstractControl = this.form.get(path);
    if (formControl && formControl.value) {
      const numberParsed = num(formControl.value);
      const formater = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
      const numberDisplay = formater.format(numberParsed);
      formControl.setValue(numberDisplay);
    }
  }

  hasError(path: string): boolean {
    const formControl: AbstractControl = this.form.get(path);
    return formControl.invalid && (formControl.touched || this.showValidation);
  }

  get resultData(): ResultRow[] {
    if (this.data.envelope) {
      return this.data.results.envelopeResultRows;
    }
    return this.data.results.resultRows;
  }

  get showSosOutOfRangeErrorMessage() {
    const min = this.form.get('min').get('speed');
    const norm = this.form.get('norm').get('speed');
    const max = this.form.get('max').get('speed');
    const molWeightAndKappa = this.form.get('molWeightAndKappa');

    if (molWeightAndKappa.touched || min.touched || norm.touched || max.touched) {
      return min.invalid || norm.invalid || max.invalid;
    }

    return false;
  }

  get data(): UncertaintyInputData {
    return this.dataService.data;
  }

  set data(newValue: UncertaintyInputData) {
    const nextData = { ...this.data, ...newValue }; // can be partial input from the from for example

    console.groupCollapsed('%c Model change', `color:black; background:lime; padding:2px`);
    console.log('Current model:', this.data);
    console.log('Changes:' + jsonDiff.diffString(this.data, nextData));
    this.dataService.data = nextData;
    console.log('Next model:', this.data);
    console.groupEnd();
  }

  private onUnitChange() {
    console.log(`onUnitChange: ${this.unitSystem} -> ${this.data.unitSystem}`);
    const newUnitSystem: UnitSystemKey = this.data.unitSystem as UnitSystemKey;
    const oldUnitSystem: UnitSystemKey = this.unitSystem;
    this.unitSystem = newUnitSystem;
    const nextFormData = convertViewModelToViewModel({ input: this.data, from: oldUnitSystem, to: newUnitSystem });
    this.form.patchValue(nextFormData);
    this.data = nextFormData;
    this.renderChart();
  }

  private patchFormData(data: Partial<UncertaintyInputData>) {
    this.data = data as any;
    console.groupCollapsed('%c patchFormData', `color:black; background:lime; padding:2px`);
    console.log(data);
    console.groupEnd();

    // Ok, this is an odd bug in angular. Since we have disabled some values in
    // solutions, we can not patch them. Otherwise angular will mark them as `invalid`,
    // even if we dont have any validators applied to those controls.
    // It seems that we have to enable them first - and disable then again
    const solutionsControl = this.form.get('solutions');
    solutionsControl.enable();

    // Another nice angular form "bug": we have to patch the unit system first,
    // otherwise we get validation errors - or we have to 'touch' all
    // falsely validate controls.
    this.form.patchValue({ unitSystem: data.unitSystem || this.unitSystem }, { emitEvent: false });
    this.form.patchValue(data, { emitEvent: false });

    this.enableOrDisableExpertOnlyControls();
    this.enableOrDisableM0Controls();
    this.enableOrDisableProjectTypeDropDown();
  }

  private updateDataAndChart(data: Partial<UncertaintyInputData>) {
    this.hasResultsAvailable = this.evaluateResultsAvailable();
    this.patchFormData(data);
    this.showBusyIndicator = false;
    this.renderChart();
  }

  onComputeSosGasComposition() {
    this.showBusyIndicator = true;
    this.octaveService.sosGasComposition(this.data).subscribe(
      (nextFormData) => this.updateDataAndChart(nextFormData),
      (error) => this.octaveErrorHandler(error)
    );
  }

  enableOrDisableExpertOnlyControls() {
    const solutionsControl = this.form.get('solutions');
    if ((this.showExpertForm && solutionsControl.enabled) || (!this.showExpertForm && solutionsControl.disabled)) {
      return;
    }
    if (this.showExpertForm) {
      solutionsControl.enable();
    } else {
      solutionsControl.disable();
    }
  }

  enableOrDisableProjectTypeDropDown() {
    const projectTypeDropDown = this.form.get('projectType');
    const enabled = this.userService.isGbc04 || this.userService.isGbc04expert || this.userService.isAdmin;
    if (enabled) {
      projectTypeDropDown.enable();
    } else {
      projectTypeDropDown.disable();
    }
  }

  enableOrDisableM0Controls() {
    const m0deviationControl = this.form.get('m0deviation');
    const enabled = this.userService.isM0deviation || this.data.deviceType !== '9';
    if (enabled) {
      m0deviationControl.enable();
    } else {
      // set to false as well
      this.data.m0deviation = false;
      m0deviationControl.disable();
    }

    // deselect m0Deviation in case of Projectype Quoting (GBC04ADSW-153)
    if (this.data.projectType === 'projectTypeQuoting' && this.data.m0deviation === true) {
      m0deviationControl.patchValue(false);
    }
  }

  private validateForm(): boolean {
    function getFormValidationErrors(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach((key) => {
        const fromControl = formGroup.get(key);
        const controlErrors: ValidationErrors = fromControl.errors;
        console.log(
          `%c ${key} invalid=${fromControl.invalid}, errors=${JSON.stringify(controlErrors)}`,
          `background-color: ${fromControl.invalid ? 'red' : 'transparent'}; color:  ${
            fromControl.invalid ? 'white' : 'black'
          }`
        );

        if (fromControl instanceof FormGroup) {
          getFormValidationErrors(fromControl);
        }
      });
    }

    this.form.get('solutions').setErrors(null);
    // formData.form.controls['email'].setErrors({'incorrect': true});

    this.form.markAllAsTouched();

    if (!this.formIsValid) {
      this.showValidation = true;
      this.errorMessage = 'Please fill all required fields and verify that all values are in range';

      console.groupCollapsed(`%c ⚠️ Form validation`, `background-color:yellow; color:black; padding:2px;`);
      getFormValidationErrors(this.form);
      console.groupEnd();

      return false;
    }

    if (
      (!this.data.min || !this.data.min.active) &&
      (!this.data.norm || !this.data.norm.active) &&
      (!this.data.max || !this.data.max.active)
    ) {
      this.errorMessage = 'Please set at least one of min, norm, max';
      return false;
    }

    if (this.userService.isSsu) {
      // Diameter checks only for SSU users - GBC04ADSW-167
      const diameter = Number(this.data.diameter);
      if (
        !isDeviceSelectionValid(
          Number(this.data.deviceType),
          Number(this.data.pathConfiguration),
          diameter,
          this.userService
        )
      ) {
        this.errorMessage = 'Invalid nominal pipe width';
        return false;
      }
    }

    if (this.data.speedOfSoundMethod === 'sos-gas-composition' && !SosService.isValidGasComposition(this.data)) {
      this.errorMessage = 'Invalid gas composition. The sum must be 100%';
      return false;
    }

    return true;
  }

  private renderChart() {
    const { resultRows, calculatedParams } = this.data.results;
    this.hasResultsAvailable = this.evaluateResultsAvailable();
    if (!calculatedParams) {
      return;
    }

    this.chartData_deviation.layout.xaxis.title = `VoG [${this.units.speed}]`;

    const colors = {
      min: 'blue',
      norm: 'red',
      max: 'pink',
    };
    this.chartData_deviation.data = [];
    ['min', 'norm', 'max'].forEach((t) => {
      if (calculatedParams[t]) {
        const x_temp = new Array();
        const y_temp = new Array();
        const data = {
          x: ([] = new Array()),
          y: ([] = new Array()),
          type: 'scatter',
          mode: 'lines+markers',
          marker: { color: colors[t] },
          name: t,
        };

        this.resultData.forEach((row: any, index: number) => {
          if (row.uncertainty[t]) {
            // number recognized - place as entry
            if (typeof row.vog === 'number') {
              x_temp.push(row.vog);
            } else {
              // any other string recognized - identify via index
              switch (index) {
                case 6:
                  x_temp.push(calculatedParams[t].maxVelocity);
                  break;
                case 7:
                  // render virtual null point to generate gap between data lines
                  x_temp.push((calculatedParams[t].maxVelocity + calculatedParams[t].maxVelocityAsc) / 2);
                  y_temp.push(null);
                  x_temp.push(calculatedParams[t].maxVelocity);
                  break;
                case 8:
                  x_temp.push(calculatedParams[t].maxVelocityAsc);
                  break;
                default:
                  break;
              }
            }
            y_temp.push(row.uncertainty[t]);
          }
        });
        if (this.data.envelope) {
          // if envelope, patch data set GBC04ADSW-148
          // IF GE replacement, ignore envelope results GBC04ADSW-202
          const patched = patchEnvelopeSupportPoints(
            x_temp,
            y_temp,
            this.data.speedUncertaintyMax,
            this.data.speedUncertaintyPercent,
            this.data.speedUncertaintyAbove
          );
          data.x = patched.x_out;
          data.y = patched.y_out;
        } else {
          data.x = x_temp;
          data.y = y_temp;
        }
        this.chartData_deviation.data.push(data as Partial<ScatterData>);
      }
    });

    this.chartData_deviation.data.push({
      x: [0, Number(this.data.speedUncertaintyMax), Number(this.data.speedUncertaintyMax), 100],
      y: [
        Number(this.data.speedUncertaintyPercent),
        Number(this.data.speedUncertaintyPercent),
        Number(this.data.speedUncertaintyAbove),
        Number(this.data.speedUncertaintyAbove),
      ],
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#666' },
      line: { dash: 'dot', width: 1 },
      name: 'Specified uncertainty by customer',
    });

    if (this.data.projectName) {
      this.chartData_deviation.layout.title.text = 'Uncertainty Chart for ' + this.data.projectName;
    }
    Plotly.newPlot(this.chartElId_deviation, this.chartData_deviation.data, this.chartData_deviation.layout, {
      responsive: true,
    });

    // extend rendering for M0, if requested
    if (this.data.m0deviation) {
      this.renderChart_m0();
    }
  }

  private renderChart_m0() {
    const { resultRows, calculatedParams } = this.data.results;
    if (!calculatedParams) {
      return;
    }

    const chartIds = {
      min: this.chartElId_m0min,
      norm: this.chartElId_m0norm,
      max: this.chartElId_m0max,
    };

    // iterate for each process condition
    ['min', 'norm', 'max'].forEach((t) => {
      if (!this.data[t].active) {
        Plotly.purge(chartIds[t]);
        return;
      }
      const chartData = {
        layout: {
          width: 800,
          height: 600,
          title: {
            title: `Estim. Deviation w/o correction M1 (${t})`,
          },
          xaxis: {
            title: `VoG [${this.units.speed}]`,
            type: 'log',
            // range: [0, maxOfMaxVelocity],
            rangemode: 'tozero',
            fixedrange: true,
          },
          yaxis: {
            title: 'Deviation [%]',
            range: [-25, 25],
          },
          legend: {
            x: 1,
            xanchor: 'right',
            y: 1.1,
            yanchor: 'top ',
          },
        },
        data: [],
      };
      // data plot
      if (calculatedParams[t]) {
        // raw sytem deviation (m0) value plot
        const data = {
          x: ([] = new Array()),
          y: ([] = new Array()),
          type: 'scatter',
          mode: 'lines+markers',
          marker: { color: 'orange' },
          name: `Systematic deviation ${t}`,
        };

        this.resultData.forEach((row: any) => {
          if (row.m0deviation[t]) {
            data.x.push(typeof row.vog === 'number' ? row.vog : calculatedParams[t].maxVelocity);
            data.y.push(row.m0deviation[t]);
          }
        });
        chartData.data.push(data as Partial<ScatterData>);

        // positive m0+m1
        const dataPosM1 = {
          x: ([] = new Array()),
          y: ([] = new Array()),
          type: 'scatter',
          mode: 'lines+markers',
          marker: { color: 'blue' },
          name: `Syst. dev. + uncertainty ${t}`,
          legendgroup: 'dataplot',
        };

        this.resultData.forEach((row: any) => {
          if (row.m0deviation[t]) {
            dataPosM1.x.push(typeof row.vog === 'number' ? row.vog : calculatedParams[t].maxVelocity);
            dataPosM1.y.push(row.m0deviation[t] + row.uncertainty[t]);
          }
        });
        chartData.data.push(dataPosM1 as Partial<ScatterData>);

        // negative m0+m1
        const dataNegM1 = {
          x: ([] = new Array()),
          y: ([] = new Array()),
          type: 'scatter',
          mode: 'lines+markers',
          marker: { color: 'blue' },
          name: `M0-M1 ${t}`,
          showlegend: false,
          legendgroup: 'dataplot',
        };

        this.resultData.forEach((row: any) => {
          if (row.m0deviation[t]) {
            dataNegM1.x.push(typeof row.vog === 'number' ? row.vog : calculatedParams[t].maxVelocity);
            dataNegM1.y.push(row.m0deviation[t] - row.uncertainty[t]);
          }
        });
        chartData.data.push(dataNegM1 as Partial<ScatterData>);
      }
      // positive limit line
      chartData.data.push({
        x: [
          0,
          Number(this.data.speedUncertaintyMax),
          Number(this.data.speedUncertaintyMax),
          this.data.results.calculatedParams[t].maxVelocity,
        ],
        y: [
          Number(this.data.speedUncertaintyPercent),
          Number(this.data.speedUncertaintyPercent),
          Number(this.data.speedUncertaintyAbove),
          Number(this.data.speedUncertaintyAbove),
        ],
        type: 'scatter',
        mode: 'lines',
        marker: { color: '#666' },
        line: { dash: 'dot', width: 1 },
        name: 'Specified uncertainty by customer',
        hoverinfo: 'skip',
        legendgroup: 'limitplot',
      });
      // negative limit line
      chartData.data.push({
        x: [
          0,
          Number(this.data.speedUncertaintyMax),
          Number(this.data.speedUncertaintyMax),
          this.data.results.calculatedParams[t].maxVelocity,
        ],
        y: [
          Number(this.data.speedUncertaintyPercent) * -1.0,
          Number(this.data.speedUncertaintyPercent) * -1.0,
          Number(this.data.speedUncertaintyAbove) * -1.0,
          Number(this.data.speedUncertaintyAbove) * -1.0,
        ],
        type: 'scatter',
        mode: 'lines',
        marker: { color: '#666' },
        line: { dash: 'dot', width: 1 },
        showlegend: false,
        hoverinfo: 'skip',
        legendgroup: 'limitplot',
      });

      Plotly.newPlot(chartIds[t], chartData.data, chartData.layout);
    });
  }

  generatePdf(withChart = false) {
    let results;

    const resultValues: Array<Array<number | string>> = this.resultData.map((resultRow: ResultRow) => [
      resultRow.vog,
      resultRow.flowRate,
      this.data.min.active ? (resultRow.uncertainty.min ? resultRow.uncertainty.min.toFixed(2) : '') : '',
      this.data.norm.active ? (resultRow.uncertainty.norm ? resultRow.uncertainty.norm.toFixed(2) : '') : '',
      this.data.max.active ? (resultRow.uncertainty.max ? resultRow.uncertainty.max.toFixed(2) : '') : '',
    ]);
    results = [['', '', 'min', 'norm', 'max'], ...resultValues];
    if (withChart) {
      const pDeviation = Plotly.toImage(this.chartElId_deviation, { format: 'png', height: 400, width: 1000 });
      const pM0Min = this.data.min.active
        ? Plotly.toImage(this.chartElId_m0min, { format: 'png', height: 400, width: 1000 })
        : undefined;
      const pM0Norm = this.data.norm.active
        ? Plotly.toImage(this.chartElId_m0norm, { format: 'png', height: 400, width: 1000 })
        : undefined;
      const pM0Max = this.data.max.active
        ? Plotly.toImage(this.chartElId_m0max, { format: 'png', height: 400, width: 1000 })
        : undefined;

      Promise.all([pDeviation, pM0Min, pM0Norm, pM0Max]).then((values) => {
        this.pdfService.downloadPdf(
          this.data,
          this.data.results.calculatedParams,
          results,
          this.units,
          this.userService.userRecord.userfull,
          values
        );
      });
    } else {
      this.pdfService.downloadPdf(
        this.data,
        this.data.results.calculatedParams,
        results,
        this.units,
        this.userService.userRecord.userfull
      );
    }
  }

  gasCompositionDialog = {
    open: false,
    onCancel: (previousFormData: UncertaintyInputData) => {
      this.patchFormData(previousFormData);
      this.gasCompositionDialog.open = false;
    },
    onOk: () => {
      this.gasCompositionDialog.open = false;
      this.showBusyIndicator = true;
      this.octaveService
        .sosGasComposition(this.data)
        .pipe(take(1))
        .subscribe(
          (nextFormData) => this.updateDataAndChart(nextFormData),
          (error) => this.octaveErrorHandler(error)
        );
    },
  };

  openGasCompositionModal() {
    if (this.disableDefineGasCompositionButton) {
      this.form.markAllAsTouched();
      this.errorMessage = 'Check process data';
      return;
    }

    this.gasCompositionDialog.open = true;
  }

  openVogVectorModal() {
    this.modalService.openModal('vog-vector');
  }

  openParametersModal() {
    this.modalService.openModal('parameters');
  }

  computeSoSUsingMolMass() {
    const data = convertViewModel2DataModel(this.data);
    const nextFormData = SosService.sosUsingMolMass(data) as UncertaintyData;
    const viewData = convertDataModel2ViewModel(nextFormData, this.data.unitSystem);
    this.patchFormData(viewData);
  }

  get showSolutions() {
    return this.data.projectType === 'projectTypeInstallBased' && this.showResults;
  }

  // ---–––––------------------
  //
  //  Octave functions calls
  //
  // -------------------------

  submitForm() {
    if (!this.validateForm()) {
      return;
    }
    this.data.alarmMarker.genericMessages = [];
    this.showBusyIndicator = true;
    this.octaveService.masterFunction(this.data, true).subscribe(
      (nextData) => {
        this.updateDataAndChart(nextData);
        this.persistenceService.logData(nextData);
      },

      (error) => this.octaveErrorHandler(error)
    );
  }

  async solutionFinder() {
    this.showBusyIndicator = true;
    try {
      const nextDataWithSolution = await this.octaveService.solutionFinder(this.data).toPromise();
      const nextData = await this.octaveService.masterFunction(nextDataWithSolution, false).toPromise();
      this.updateDataAndChart(nextData);
      this.persistenceService.logData(nextData);
    } catch (error) {
      this.octaveErrorHandler(error);
    }
  }

  async solutionFinderCustomSearch() {
    this.showBusyIndicator = true;
    try {
      this.data.alarmMarker.customSolution = false;
      this.data.alarmMarker.solution = false;
      const nextData = await this.octaveService.masterFunction(this.data, true).toPromise();

      this.updateDataAndChart(nextData);
      this.persistenceService.logData(nextData);
    } catch (error) {
      this.octaveErrorHandler(error);
    }
  }

  async solutionFinderReject() {
    try {
      this.showBusyIndicator = true;
      this.patchFormData({ ...this.data, solutions: defaultUncertaintyInputData().solutions });
      const nextData = await this.octaveService.masterFunction(this.data, true).toPromise();
      this.updateDataAndChart(nextData);
      this.persistenceService.logData(nextData);
    } catch (error) {
      this.octaveErrorHandler(error);
    }
  }

  searchVminLimit() {
    this.showBusyIndicator = true;
    this.octaveService.searchVminLimit(this.data).subscribe(
      (nextData) => {
        this.updateDataAndChart(nextData);
        this.persistenceService.logData(nextData);
      },
      (error) => this.octaveErrorHandler(error)
    );
  }

  defaultParameterMatrix() {
    this.showBusyIndicator = true;
    this.octaveService.defaultParameterMatrix(this.data).subscribe(
      (nextData) => {
        this.patchFormData(nextData);
        this.showBusyIndicator = false;
      },
      (error) => this.octaveErrorHandler(error)
    );
  }

  octaveErrorHandler(error: any) {
    const errorMessage = 'Backend failure: ' + (error.error !== undefined ? error.error.message : error.message);
    this.data.alarmMarker.genericMessages.push(errorMessage);
    this.showBusyIndicator = false;
  }

  // -------------------------------
  //
  // For debug
  //
  // -------------------------------
  debugShowAllAlarmMarkers() {
    this.data.alarmMarker = {
      agc: true,
      spec: true,
      solution: true,
      customSolution: true,
      genericMessages: ['Here is an error message for debug purpose', 'second message'],
    };
  }

  get shareData() {
    return JSON.stringify(this.data, null, 2);
  }

  set shareData(val: string) {
    if (val.length === 0) {
      return;
    }

    try {
      const nextData = JSON.parse(val);
      this.patchFormData(nextData);
    } catch (e) {
      console.error('Upp.. Could not apply data!');
    }
  }

  clearShareDataTextArea() {
    const el = document.getElementById('shareDataTextArea') as any;
    el.value = '';
  }

  copySharedDataToClipboard() {
    const el = document.createElement('textarea') as any;
    el.value = JSON.stringify(this.data);
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('Copy to clipboard');
  }
}
