/* tslint:disable */
export const warningSvg = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->
<svg
    width="12" 
    height="12"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns:ns1="http://sozi.baierouge.fr"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="svg2390"
    sodipodi:docname="warning.svg"
    viewBox="0 0 80 80"
    sodipodi:version="0.32"
    version="1.0"
    inkscape:output_extension="org.inkscape.output.svg.inkscape"
    inkscape:version="0.46"
  >
  <sodipodi:namedview
      id="base"
      bordercolor="#666666"
      inkscape:pageshadow="2"
      guidetolerance="10"
      pagecolor="#ffffff"
      gridtolerance="10000"
      inkscape:window-height="715"
      inkscape:zoom="1.979899"
      objecttolerance="10"
      showgrid="false"
      borderopacity="1.0"
      inkscape:current-layer="layer1"
      inkscape:cx="109.81659"
      inkscape:cy="62.58939"
      inkscape:window-y="392"
      inkscape:window-x="950"
      inkscape:window-width="640"
      inkscape:pageopacity="0.0"
      inkscape:document-units="px"
  />
  <g
      id="layer1"
      inkscape:label="Layer 1"
      inkscape:groupmode="layer"
      transform="translate(-21.913 -16.984)"
    >
    <g
        id="g2387"
        style="fill:#ffd42a"
        inkscape:export-ydpi="90"
        inkscape:export-filename="/home/mgsmith/Desktop/warning.png"
        transform="matrix(.45541 0 0 .45541 26.271 23.868)"
        inkscape:export-xdpi="90"
      >
      <path
          id="path2389"
          d="m12.473 121.08c-0.914 1.76-1.991 3.41-1.991 5.34l0.009 0.81c0 4.17 3.266 6.69 7.02 6.69h121.32c3.76 0 6.99-3.12 6.99-7.29l-0.04-0.81c0-1.94-0.95-3.62-1.99-5.35l-60.084-107.78c-2.655-2.9458-6.959-2.9458-9.614 0.004l-61.619 108.39z"
          style="stroke:#000000;stroke-width:6.8314;fill:#ffd42a"
          sodipodi:nodetypes="ccccccccccc"
      />
    </g
    >
    <path
        id="path2393"
        sodipodi:rx="8.0416269"
        sodipodi:ry="8.0416269"
        style="fill:#000000"
        sodipodi:type="arc"
        d="m31.963 74.475a8.0416 8.0416 0 1 1 -16.083 0 8.0416 8.0416 0 1 1 16.083 0z"
        inkscape:export-ydpi="90"
        inkscape:export-filename="/home/mgsmith/Desktop/warning.png"
        transform="matrix(.54212 0 0 .54212 49.546 33.731)"
        sodipodi:cy="74.47538"
        sodipodi:cx="23.921297"
        inkscape:export-xdpi="90"
    />
    <path
        id="path2395"
        style="fill:#000000"
        d="m62.486 43.817c1.713 0 4.371 1.365 4.371 3.06l-1.279 17.615c0 1.695-1.379 3.06-3.092 3.06s-3.091-1.365-3.091-3.06l-1.493-17.615c0-1.695 2.871-3.06 4.584-3.06z"
        inkscape:export-ydpi="90"
        sodipodi:nodetypes="cccsccc"
        inkscape:export-filename="/home/mgsmith/Desktop/warning.png"
        inkscape:export-xdpi="90"
    />
  </g
  >
  <metadata
    >
    <rdf:RDF
      >
      <cc:Work
        >
        <dc:format
          >image/svg+xml</dc:format
        >
        <dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage"
        />
        <cc:license
            rdf:resource="http://creativecommons.org/licenses/publicdomain/"
        />
        <dc:publisher
          >
          <cc:Agent
              rdf:about="http://openclipart.org/"
            >
            <dc:title
              >Openclipart</dc:title
            >
          </cc:Agent
          >
        </dc:publisher
        >
        <dc:title
          >warning icon</dc:title
        >
        <dc:date
          >2010-03-03T13:31:08</dc:date
        >
        <dc:description
          >A very simple warning icon that can be scaled down fairly small and still be recognizable.</dc:description
        >
        <dc:source
          >https://openclipart.org/detail/29833/warning-icon-by-matthewgarysmith</dc:source
        >
        <dc:creator
          >
          <cc:Agent
            >
            <dc:title
              >matthewgarysmith</dc:title
            >
          </cc:Agent
          >
        </dc:creator
        >
        <dc:subject
          >
          <rdf:Bag
            >
            <rdf:li
              >icon</rdf:li
            >
            <rdf:li
              >sign</rdf:li
            >
            <rdf:li
              >warning</rdf:li
            >
            <rdf:li
              >yellow</rdf:li
            >
          </rdf:Bag
          >
        </dc:subject
        >
      </cc:Work
      >
      <cc:License
          rdf:about="http://creativecommons.org/licenses/publicdomain/"
        >
        <cc:permits
            rdf:resource="http://creativecommons.org/ns#Reproduction"
        />
        <cc:permits
            rdf:resource="http://creativecommons.org/ns#Distribution"
        />
        <cc:permits
            rdf:resource="http://creativecommons.org/ns#DerivativeWorks"
        />
      </cc:License
      >
    </rdf:RDF
    >
  </metadata
  >
</svg
>`;
