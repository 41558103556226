import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { UncertaintyData, UncertaintyInputData, UnitSystemKey } from '../model/model';
import { tap, map } from 'rxjs/operators';
import { groupLogger } from '../utils/groupLogger';

export interface FlowsicLogEntry extends UncertaintyData {
  userid: string;
  created: string;
  displayUnitSystem: UnitSystemKey;
}

// interface GetUserRecordRes {
//   Count: number;
//   Items: FlowsicLogEntry[];
//   ScannedCount: number;
// }

type GetUserRecordRes = FlowsicLogEntry[];

const { logError, log } = groupLogger({
  name: 'PersistenceService',
  backgroundColor: 'DarkMagenta',
  color: 'GhostWhite',
  symbol: '💾',
});

@Injectable({
  providedIn: 'root',
})
export class PersistenceService {
  static VERSION = '0.3';

  apiAvailable = environment.persistenceApiUrl && environment.persistenceApiUrl.length > 0;

  constructor(private http: HttpClient, private authService: OAuthService) {}

  public isApiAvailable(): boolean {
    return this.apiAvailable;
  }

  /**
   *
   * @param input - the `normalized` data model
   * @param displayUnitSystem - the displayUnitSystem unit system
   */
  public saveUserData(data: UncertaintyData, displayUnitSystem: UnitSystemKey) {
    const version = PersistenceService.VERSION;
    return this.postData({ version, displayUnitSystem, ...data }, 'user-record').then(() => log('User data saved'));
  }

  public queryUserData(starttime: number = null, endtime: number = null): Promise<any> {
    return this.getData(starttime, endtime);
  }

  public logData(input: UncertaintyInputData) {
    if (this.isApiAvailable) {
      return this.postData({ ...input }, 'log').catch((e) => logError(e));
    } else {
      console.error('Persitence not available');
    }
  }

  private postData(data, target) {
    return this.http
      .post(environment.persistenceApiUrl + '/' + target, data, {
        headers: {
          Authorization: this.authService.getIdToken(),
        },
      })
      .pipe(tap(() => log('POST ' + target, data)))
      .toPromise();
  }

  public getData(starttime: number, endtime: number) {
    const params = new HttpParams();
    if (starttime) {
      params.set('starttime', new Date(starttime).getTime().toString());
    }
    if (endtime) {
      params.set('endtime', new Date(endtime).getTime().toString());
    }
    return this.http
      .get(environment.persistenceApiUrl + '/user-record', {
        headers: {
          Authorization: this.authService.getIdToken(),
        },
        params,
      })
      .toPromise();
  }

  public getUserRecord() {
    const headers = {
      Authorization: this.authService.getIdToken(),
    };

    const ulr = environment.persistenceApiUrl + '/user-record';
    return this.http
      .get<GetUserRecordRes>(ulr, { headers })
      .pipe(tap((res) => log('GET /user-record', 'Res:', res)));
  }

  public deleteUserRecord(data) {
    if (!data.userid || !data.created) {
      data.userid = 'invalid, not set';
      data.created = 'invalid, not set';
    }
    const params = new HttpParams().set('userid', data.userid).set('created', data.created);
    return this.http
      .delete(environment.persistenceApiUrl + '/user-record', {
        headers: {
          Authorization: this.authService.getIdToken(),
        },
        params,
      })
      .pipe(tap(() => log('DELETE ' + '/user-record', params)))
      .toPromise();
  }
}
