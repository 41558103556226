//
// See https://observablehq.com/@mbostock/localized-number-parsing
//
export function numberParser(locale: string) {
  const format = new Intl.NumberFormat(locale);
  const parts = format.formatToParts(12345.6);
  const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
  const index = new Map(numerals.map((d, i) => [d, i]));
  const _group = new RegExp(`[${parts.find((d) => d.type === 'group').value}]`, 'g');
  const _decimal = new RegExp(`[${parts.find((d) => d.type === 'decimal').value}]`);
  const _numeral = new RegExp(`[${numerals.join('')}]`, 'g');
  const _index = (d) => index.get(d) as any;

  return function parse(input: any) {
    const str = '' + input;
    const numStr = str.trim().replace(_group, '').replace(_decimal, '.').replace(_numeral, _index);
    return numStr ? +numStr : NaN;
  };
}
