import { UncertaintyData } from '../model/model';

export const urlEncodeGasComposition = (input: UncertaintyData) => {
  const { gasComposition: gas } = input;
  return (
    `${gas.methan},` +
    `${gas.nitrogen},` +
    `${gas.carbondioxid},` +
    `${gas.ethan},` +
    `${gas.propan},` +
    `${gas.nButhan},` +
    `${gas.iButhan},` +
    `${gas.nPenthan},` +
    `${gas.iPenthan},` +
    `${gas.nHexan},` +
    `${gas.nHepthan},` +
    `${gas.nOcthan},` + // TODO: typo nOctan
    `${gas.nNonane},` +
    `${gas.nDecane},` +
    `${gas.hydrogen},` +
    `${gas.oxygen},` +
    `${gas.carbonMonoxid},` +
    `${gas.water},` +
    `${gas.hydrogenSulfid},` +
    `${gas.helium},` +
    `${gas.argon}`
  );
};

/**
 * Takes an object, returns an query string
 */
export const urlEncodeQuery = (query: object) => {
  const removeUndefinedProperties = (obj: object) => JSON.parse(JSON.stringify(obj));
  const q = removeUndefinedProperties(query);
  return Object.keys(q)
    .map((key) => key + '=' + query[key])
    .join('&');
};
