const frontend_url = window.location.origin;

export const oidcConfig = {
  frontend_url: `${frontend_url}`,
  oidc: {
    // Url of the Identity Provider
    issuer: 'https://id.sick.com/auth/realms/sickservices',
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'FlowsicDigitalDesign',
  },
};

export function getOidcConfig(identityProviderUrl: string): typeof oidcConfig {
  oidcConfig.oidc.issuer = 'https://' + identityProviderUrl;
  return oidcConfig;
}
