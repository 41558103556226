export namespace DavinciCoreModel {
  // Defaults

  // WARNING:
  // Always create a new instance when using the default objects!!
  // Otherwise you may get very strange side-effects!! (JS passes objects by reference only)
  // const myNavPane: NavigationPane = {...defaultNavigationPane};

  export const defaultNavigation: Navigation = {
    currentPath: [],
    tree: [],
    type: 'basic'
  };

  export const defaultNavigationPane: NavigationPane = {
    hasSearch: false,
    isSticky: false,
    isVisible: false
  };

  export const defaultToolbar: Toolbar = {
    buttons: [],
    menu: [],
    type: 'header'
  };

  // Types

  export type NavigationType = 'basic' | 'tree' | 'workflow' | null;

  // Interfaces

  export interface ModalConfig {
    showCancelButton?: boolean;
    showOkButton?: boolean;
    title?: string;
    titleBar?: string;
  }

  export interface Navigation {
    currentPath: string[];
    tree: NavigationItem[];
    type: NavigationType;
  }

  export interface NavigationItem {
    label: string;
    route: string;
    sub?: NavigationItem[];
    disabled?: boolean;
  }

  export interface NavigationPane {
    hasSearch: boolean;
    isSticky: boolean;
    isVisible: boolean;
  }

  export interface Task {
    blocking: boolean;
    canceled?: boolean;
    callback: {
      action: () => Promise<any>;
      progress?: () => Promise<number> | number;
    };
    name: string;
    progress?: number; // -1 to 100 (-1 => indeterminate || 0-100 => progress in percent || others => invalid)
    resolve?: PromiseResolver<any>;
    reject?: PromiseRejector;
    startTime?: number; // Date().getTime -> milliseconds
  }

  export interface Toolbar {
    buttons: ToolbarButton[] | null;
    menu: ToolbarMenuItem[] | null;
    type: 'header' | 'workspace';
  }

  export interface ToolbarButton {
    iconClass?: string;
    label?: string;
    title?: string;
    callback: (event?: Event) => void;
  }

  export interface ToolbarMenuItem {
    label: string;
    sub?: ToolbarMenuItem[];
    callback?: () => void;
    disabled?: boolean;
  }

  export interface CustomHeaderBranding {
    imageUrl?: string;
    text?: string;
    user?: string;
  }

  // ======================

  export type PromiseResolver<T> = (value?: T | PromiseLike<T>) => void;
  export type PromiseRejector = (reason?: any) => void;
}
