import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { DavinciCorePersistenceService } from './davinci-core-persistence.service';
import { DavinciCoreTasksService } from './davinci-core-tasks.service';
import { DavinciCoreModel } from './davinci-core.model';
import { DavinciCoreService } from './davinci-core.service';

@Component({
  selector: 'sick-davinci-core',
  templateUrl: './davinci-core.component.html',
  styleUrls: ['./davinci-core.component.scss']
})
export class DavinciCoreComponent implements OnInit {
  @Input()
  toolbarConfig: DavinciCoreModel.Toolbar = { ...DavinciCoreModel.defaultToolbar };

  @Input()
  customBranding: DavinciCoreModel.CustomHeaderBranding | null = null;

  hasNavigationPane = false;

  constructor(
    public translate: TranslateService,
    public tasks: DavinciCoreTasksService,
    public core: DavinciCoreService,
    private persistence: DavinciCorePersistenceService
  ) {}

  ngOnInit() {
    const persistedLanguage = this.persistence.getData('core_language');
    if (persistedLanguage) {
      this.core.language$.next(persistedLanguage);
    }

    this.core.language$.subscribe((lang: string) => {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.persistence.setData('core_language', lang);
    });

    combineLatest([this.core.navigationPane$, this.core.navigation$]).subscribe(
      ([pane, navigation]: [DavinciCoreModel.NavigationPane, DavinciCoreModel.Navigation]) => {
        this.hasNavigationPane = !!(
          navigation.tree &&
          navigation.tree.length > 0 &&
          navigation.type === 'tree' &&
          pane
        );
      }
    );

    this.core.uiDisabled$.subscribe(async disabled => {
      if (disabled) {
        this.core.modalActive$.next(true);
        this.tasks.startAllStatusPolling();
      } else {
        this.core.modalActive$.next(false);
        this.tasks.stopAllStatusPolling();
      }
    });
  }
}
