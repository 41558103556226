import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { log } from './log';
import { tap } from 'rxjs/operators';

export const versionHandler = (http: HttpClient) => () => {
  const url = environment.apiUrl + '/version';

  return http
    .get(url, {
      responseType: 'text',
    })
    .pipe(tap((version) => log(url, null, { result: version })));
};
