import { Inch } from '../model/model';
import { UserService } from '../services/user.service';
/**
 * Verifies the correct combination between device type, number of paths and
 * measurement diameter.
 * @param deviceType Denotes the numeric representation of the selected device type.
 * Device types are indexed by dropdown in device type selection:
 *  [1] EX-S 135 kHz
 *  [2] EX-S 80 kHz
 *  [3] EX 42 kHz
 *  [4] EX-PR
 *  [5] F1F-S
 *  [6] F1F-M
 *  [7] F1F-H
 *  [8] F1F-P
 *
 *  Diameter Sizes have been adjusted with GBC04ADSW-167
 * @param numberOfPaths Number of paths selected - can be either 1 or 2.
 * @param diameter diameter of meter in inches.
 *
 * @return True, if type, diameter and path number are correctly selected
 */
export function isDeviceSelectionValid(
  deviceType: number,
  numberOfPaths: number,
  diameter: Inch,
  userService: UserService = null
): boolean {
  if (deviceType === 1 && numberOfPaths === 1 && (diameter <= 3 || diameter >= 25)) {
    return false;
  } else if (deviceType === 1 && numberOfPaths === 2 && (diameter <= 11 || diameter >= 25)) {
    return false;
  } else if (deviceType === 2 && numberOfPaths === 1 && (diameter <= 3 || diameter >= 25)) {
    return false;
  } else if (deviceType === 2 && numberOfPaths === 2 && (diameter <= 11 || diameter >= 25)) {
    return false;
  } else if (deviceType === 3 && numberOfPaths === 1 && (diameter <= 7 || diameter >= 73)) {
    return false;
  } else if (deviceType === 3 && numberOfPaths === 2 && (diameter <= 11 || diameter >= 73)) {
    return false;
  } else if (deviceType === 4 && numberOfPaths === 1 && (diameter <= 11 || diameter >= 73)) {
    return false;
  } else if (deviceType === 4 && numberOfPaths === 2 && (diameter <= 17 || diameter >= 73)) {
    return false;
  } else if (deviceType === 5 && numberOfPaths === 1 && (diameter <= 3 || diameter >= 21)) {
    return false;
  } else if (deviceType === 5 && numberOfPaths === 2 && (diameter <= 11 || diameter >= 21)) {
    return false;
  } else if (deviceType === 6 && (diameter <= 19 || diameter >= 41)) {
    return false;
  } else if (deviceType === 7 && userService != null && !userService.isGbc04 && (diameter <= 21 || diameter >= 73)) {
    return false;
  } else if (deviceType === 7 && (diameter <= 3 || diameter >= 73)) {
    return false;
  } else if (deviceType === 8 && numberOfPaths === 1 && (diameter <= 11 || diameter >= 73)) {
    return false;
  } else if (deviceType === 8 && numberOfPaths === 2 && (diameter <= 17 || diameter >= 73)) {
    return false;
  } else if (deviceType === 9 && (diameter <= 11 || diameter >= 73)) {
    return false;
  }
  return true;
}
