import { Component, EventEmitter, Input, Output, SkipSelf } from '@angular/core';

import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-unit-field',
  templateUrl: './unit-field.component.html',
  styleUrls: ['./unit-field.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class UnitFieldComponent {
  @Input() controlName: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() unit: string;
  @Input() error = false;
  @Output() blur: EventEmitter<Object> = new EventEmitter<Object>();

  blurEvent(path) {
    this.blur.emit(path);
  }
}
