import { Component, OnInit, Input } from '@angular/core';
import { ModalService, ModalDialogId } from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {
  @Input() dialog: ModalDialogId = 'load';
  ModalDialogId: ModalDialogId;

  constructor(public modalService: ModalService) {}

  ngOnInit() {}
}
