<sick-davinci-core [toolbarConfig]="toolbarConfig" [customBranding]="customBranding">
  <div *ngIf="!error && user.name !== 'anonymous'" class="router-content">
    <router-outlet (activate)="onComponentActivated($event)"></router-outlet>
  </div>
  <div class="error">{{ error }}</div>
  <sick-davinci-footer>
    <div class="applicationInfo">
      {{ frontendVersion }} | Current Backend API version: {{ backendAPIVersion }} | Supported API version:
      {{ supportedAPIversion }}
    </div>
  </sick-davinci-footer>
</sick-davinci-core>
