import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { ValueControlValueAccessor } from './value-control-value-accessor.directive';
import { SelectedControlValueAccessor } from './selected-control-value-accessor.directive';
import { CheckedControlValueAccessor } from './checked-control-value-accessor.directive';
import { ArrayControlValueAccessor } from './array-control-value-accessor.directive';
import { StatusControlValueAccessor } from './status-control-value-accessor.directive';

@NgModule({
  declarations: [ValueControlValueAccessor,
    SelectedControlValueAccessor,
    CheckedControlValueAccessor,
    ArrayControlValueAccessor,
    StatusControlValueAccessor],
  imports: [
  ],
  exports: [ValueControlValueAccessor,
    SelectedControlValueAccessor,
    CheckedControlValueAccessor,
    ArrayControlValueAccessor,
    StatusControlValueAccessor],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BasicElementsFormLibModule { }
