import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'sick-davinci-auth-callback',
  template: ''
})
export class AuthCallbackComponent {
  constructor(private router: Router, private authService: AuthService) {
    let redirectUrl = localStorage.getItem('redirectUrl');

    if (redirectUrl == null || redirectUrl.includes('auth-callback')) {
      redirectUrl = this.authService.redirectUrl;
    }

    this.authService.completeAuthentication()
      .then(x => {
        this.authService.getUser()
          .then(user => {
            if (user != null) {
              this.router.navigate([redirectUrl]);
            }
          });
      });
  }
}



