<form [formGroup]="form">
  <div class="container" formGroupName="vogVector">
    <div class="grid">
      <div class="col1">
        <div class="gas">1</div>
      </div>

      <div class="col2">
        <davinci-text-field formControlName="velo1" [error]="hasError('velo1')"
          ><span class="unit">{{ units.speed }}</span></davinci-text-field
        >
      </div>

      <div class="col1">
        <div class="gas">2</div>
      </div>

      <div class="col2">
        <davinci-text-field formControlName="velo2" [error]="hasError('velo2')"
          ><span class="unit">{{ units.speed }}</span></davinci-text-field
        >
      </div>

      <div class="col1">
        <div class="gas">3</div>
      </div>

      <div class="col2">
        <davinci-text-field formControlName="velo3" [error]="hasError('velo3')"
          ><span class="unit">{{ units.speed }}</span></davinci-text-field
        >
      </div>

      <div class="col1">
        <div class="gas">4</div>
      </div>

      <div class="col2">
        <davinci-text-field formControlName="velo4" [error]="hasError('velo4')"
          ><span class="unit">{{ units.speed }}</span></davinci-text-field
        >
      </div>

      <div class="col1">
        <div class="gas">5</div>
      </div>
      <div class="col2">
        <davinci-text-field formControlName="velo5" [error]="hasError('velo5')"
          ><span class="unit">{{ units.speed }}</span></davinci-text-field
        >
      </div>
    </div>

    <div class="grid">
      <div class="col1">
        <div class="gas">6</div>
      </div>

      <div class="col2">
        <davinci-text-field formControlName="velo6" [error]="hasError('velo6')"
          ><span class="unit">{{ units.speed }}</span></davinci-text-field
        >
      </div>

      <div class="col1">
        <div class="gas">7</div>
      </div>

      <div class="col2">
        v_max
      </div>

      <div class="col1">
        <div class="gas">8</div>
      </div>
      <div class="col2">
        v_max_ASC
      </div>

      <div class="col1"></div>
      <div class="col2">
        <davinci-button type="outline" (click)="setDefaults()" style="min-width: 232px;"
          >Set to defaults</davinci-button
        >
      </div>
    </div>
  </div>
  <hr />

  <!-- <div class="sum" *ngIf="!errorLabel">Sum: {{ sum }}</div>
  <div class="errorLabel" *ngIf="errorLabel">{{ errorLabel }}</div> -->
</form>
