import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent, SilentRefreshComponent } from '@sick-davinci/core-ng';
import { HomeComponent } from './components/home/home.component';
import { ListDataModalComponent } from './components/list-data-modal/list-data-modal.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { AdminGuard } from './guards/admin.guard';
import { ProjectsPageComponent } from './components/projects-page/projects-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'projects', component: ProjectsPageComponent },
  { path: 'auth-callback', component: AuthCallbackComponent }, // needed to return from SICK ID
  { path: 'silent-refresh', component: SilentRefreshComponent }, // url to trigger silent token refresh
  // canActivate / RouteGuard checks users rights for this path and redirects to SICK ID login, if not.
  { path: 'modal', component: ListDataModalComponent },
  { path: 'admin/users', component: AdminUsersComponent, canActivate: [AdminGuard] },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
