import {
  Component,
  OnInit
} from '@angular/core';
import { DavinciCoreTasksService } from '../../davinci-core-tasks.service';
import { DavinciCoreModel } from '../../davinci-core.model';
import { DavinciCoreService } from '../../davinci-core.service';

@Component({
  selector: 'sick-davinci-tasks-list',
  templateUrl: './davinci-tasks-list.component.html',
  styleUrls: ['./davinci-tasks-list.component.scss']
})
export class DavinciTasksListComponent implements OnInit {
  tasksVisible = false;
  tasksButton: DavinciCoreModel.ToolbarButton = {
    iconClass: 'assignment',
    callback: (event: MouseEvent) => {
      event.stopPropagation();
      this.core.menuCloser$.next(this);
      this.core.paneCloser$.next();

      this.tasksVisible = !this.tasksVisible;
      if (this.tasksVisible) {
        this.tasks.startAllStatusPolling();
      } else {
        this.tasks.stopAllStatusPolling();
      }
    },
  };

  constructor(
    public tasks: DavinciCoreTasksService,
    private core: DavinciCoreService,
  ) {
  }

  ngOnInit() {
    window.addEventListener('click', () => {
      this.tasksVisible = false;
    });

    this.core.menuCloser$.subscribe((issuer) => {
      if (issuer !== this) {
        this.tasksVisible = false;
        this.tasks.stopAllStatusPolling();
      }
    });
  }

  tasksListClickCatcher(event: Event) {
    event.stopPropagation();
    this.core.menuCloser$.next(this);
    this.core.paneCloser$.next();
  }
}
